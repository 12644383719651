import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tGasto, tTipoGasto } from "../../interfaces";
import { v4 } from "uuid";
import InputValores from "../inputs/InputValores";

//TYPES
export type tNovoGasto = PartialEntity<tGasto, "nota" | "tipoGasto" | "valor">;

export interface FormVisitaGastoProps {
    formId?: string;
    onSubmit: (gasto: tNovoGasto) => void | Promise<void>;
    valoresIniciais: tNovoGasto;
}

//FORM
export default function FormVisitaGasto({ formId, onSubmit, valoresIniciais, ...rest }: FormVisitaGastoProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (gasto: tNovoGasto, helpers: FormikHelpers<tNovoGasto>) => {
            try {
                await onSubmit(gasto);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaVisitaGasto}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                <Form.Label className="mb-1">Tipo Gasto</Form.Label>
                                <Form.Select
                                    isInvalid={!!errors.tipoGasto}
                                    required
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            tipoGasto: e.target.value as tTipoGasto,
                                        })
                                    }
                                    value={values.tipoGasto}
                                >
                                    {utils.retornaArrayTipoGasto().map((tipoGasto) => (
                                        <option key={tipoGasto} value={tipoGasto}>
                                            {utils.retornaTextoTipoGasto(tipoGasto)}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.tipoGasto}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                <Form.Label className="mb-1">Nota fiscal</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={values.nota}
                                    onChange={(e) => setValues({ ...values, nota: e.target.value })}
                                    isInvalid={!!errors.nota}
                                />
                                <Form.Control.Feedback type="invalid">{errors.nota}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm="12" className="mb-2" controlId={v4()}>
                                <Form.Label className="mb-1">Valor do gasto</Form.Label>
                                <InputValores
                                    valor={values.valor}
                                    moeda={"BRL"}
                                    onAccept={(valor) => setValues({ ...values, valor })}
                                    isInvalid={!!errors.valor}
                                />
                                <Form.Control.Feedback type="invalid">{errors.valor}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaVisitaGasto: yup.ObjectSchema<tNovoGasto> = yup.object({
    tipoGasto: yup.mixed<tTipoGasto>().oneOf(["ALIMENTACAO", "HOSPEDAGEM"]).required("Este campo é necessário."),
    nota: yup.string().required("Este campo é necessário."),
    valor: yup.number().required("Esta campo é necessário.").notOneOf([0], "O valor não pode ser zero."),
});
