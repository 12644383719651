import {
    FiltroAnos,
    FiltroComissaoPaga,
    FiltroEstadosVenda,
    FiltroVendedores,
    OffcanvasDistribuicoesComissao,
    TabelaComissoes,
} from "../components";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutTabela } from "../layouts";
import { tComissao, tEstadoVenda } from "../interfaces";
import useComissao, { LIMITE_POR_BUSCA_COMISSAO } from "../hooks/useComissao";
import { ContextUser } from "../contexts/ContextUser";
import FiltroMes from "../components/filtros/FiltroMes";
import utils from "../utils";
import { ContextAlerta } from "../contexts/ContextAlert";
import { Button } from "react-bootstrap";
import useFiltros from "../hooks/useFiltros";

export default function Comissoes() {
    //HOOKS
    const { buscar, buscaComErro, estaBuscando, cancelar, editar, editarVarias } = useComissao();
    const { getFilter } = useFiltros("comissoes");

    //CONTEXTOS
    const { setProcessando, setConfirmar, setAviso } = useContext(ContextAlerta);
    const { podeVisualizarComissao, podeVisualizarDistribuicaoComissao, usuario } = useContext(ContextUser);

    //ESTADOS
    const [comissoes, setComissoes] = useState<tComissao[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);

    const [showOffcanvasDistribuicoes, setShowOffcanvasDistribuicoes] = useState(false);

    //VARIAVEIS
    const mesInicial = getFilter("mesInicial") ?? undefined;
    const mesFinal = getFilter("mesFinal") ?? undefined;
    const comissoesPagas = utils.deStringPara<boolean>(getFilter("comissaoPaga"), "boolean");
    const anoSelecionado = getFilter("ano") ?? undefined;
    const estadoVendaSelecionado = (getFilter("estadoVenda") as tEstadoVenda | null) ?? undefined;
    const vendedorIdSelecionado = getFilter("vendedor") ?? undefined;

    //EVENTOS
    const buscarComissoes = useCallback(
        async (pagina?: number, pesquisa?: string) => {
            const { dataInicial, dataFinal } = utils.datasPorMesesAno(mesInicial, mesFinal, anoSelecionado) ?? {};

            const where = {
                comissaoPaga: comissoesPagas,
                vendedorId: vendedorIdSelecionado,
                dataInicio: dataInicial,
                dataFim: dataFinal,
                estadoVenda: estadoVendaSelecionado,
                campoPesquisa: pesquisa,
            };
            if (!podeVisualizarComissao(where)) return 0;

            try {
                const comissoes = await buscar({ limit: LIMITE_POR_BUSCA_COMISSAO, page: pagina, where });
                if (pagina === undefined) {
                    setComissoes(comissoes);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setComissoes((atuais) => [...atuais, ...comissoes]);
                return comissoes.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [
            buscar,
            vendedorIdSelecionado,
            mesInicial,
            mesFinal,
            anoSelecionado,
            podeVisualizarComissao,
            comissoesPagas,
            estadoVendaSelecionado,
        ]
    );

    useEffect(() => {
        buscarComissoes().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarComissoes]);

    const handleOnPesquisar = useCallback((pesquisa?: string) => buscarComissoes(undefined, pesquisa), [buscarComissoes]);

    const handleOnEditComissao = useCallback(
        async (comissao: tComissao) => {
            const confirm = await setConfirmar(
                "Após marcar como paga esta comissão, não se poderá reverter este estado."
            );
            if (!confirm) return setAviso("Nada foi realizado!");
            try {
                setProcessando(true);
                await editar({ id: comissao.id, comissaoPaga: !comissao.comissaoPaga });
                buscarComissoes().catch((err) => console.error(err));
            } catch (err) {
                console.error(err);
            } finally {
                setProcessando(false);
            }
        },
        [editar, buscarComissoes, setProcessando, setAviso, setConfirmar]
    );

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroComissaoPaga />
                        <FiltroEstadosVenda />
                        <FiltroAnos />
                        <FiltroMes />
                        <FiltroVendedores />
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        {podeVisualizarDistribuicaoComissao({ vendedorId: usuario?.vendedorUser?.id })}
                        <Button className="rounded-pill" onClick={() => setShowOffcanvasDistribuicoes(true)}>
                            Distribuições
                        </Button>
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarComissoes}
                >
                    <TabelaComissoes
                        comissoes={comissoes}
                        onEdit={handleOnEditComissao}
                        buscar={handleOnPesquisar}
                        propsDropdownInfoComissao={{
                            onEditarVarias: editarVarias,
                            onEditou: () => buscarComissoes().catch((err) => console.error(err)),
                        }}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasDistribuicoesComissao
                placement="end"
                show={showOffcanvasDistribuicoes}
                onHide={() => setShowOffcanvasDistribuicoes(false)}
            />
        </>
    );
}
