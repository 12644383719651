import CardDashboardResumoLeft from "./CardDashboardResumoLeft";
import CardDashboardResumoRight from "./CardDashboardResumoRight";
import CardDashboardResumoRoot from "./CardDashboardResumoRoot";

const CardDashboardResumo = {
    Root: CardDashboardResumoRoot,
    Left: CardDashboardResumoLeft,
    Right: CardDashboardResumoRight,
};

export default CardDashboardResumo;
