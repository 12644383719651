import { ReactNode } from "react";
import "./styles.css";
import { Container, Row } from "react-bootstrap";

interface ILayoutDashboardBodyRootProps {
    children?: ReactNode;
}

export default function LayoutDashboardBodyRoot(props: ILayoutDashboardBodyRootProps) {
    return (
        <div className="my-div-layout-dashboard-body-root bg-white overflow-auto">
            <Container fluid>
                <Row className="pt-3 pb-3">{props.children}</Row>
            </Container>
        </div>
    );
}
