import { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import OffcanvasUsuario from "../offcanvas/OffcanvasUsuario";

export default function BotaoUser() {
    //ESTADOS
    const [mostrarOffcanvasUsuarios, setMostrarOffcanvasUsuarios] = useState(false);

    //EVENTOS
    const handleMostrarOffcanvas = useCallback(() => setMostrarOffcanvasUsuarios(true), []);
    const handleHideOffcanvas = useCallback(() => setMostrarOffcanvasUsuarios(false), []);

    return (
        <>
            <Button className="rounded-pill" onClick={handleMostrarOffcanvas}>
                Usuários
            </Button>
            <OffcanvasUsuario placement="end" show={mostrarOffcanvasUsuarios} onHide={handleHideOffcanvas} />
        </>
    );
}
