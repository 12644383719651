import { ReactNode } from "react";
import "./styles.css";

interface ITabelaHeaderProps {
    children?: ReactNode;
}

export default function TabelaHeader(props: ITabelaHeaderProps) {
    return (
        <thead className="my-sticky-header rounded-3 rounded-top sticky-top bg-white shadow-sm">{props.children}</thead>
    );
}
