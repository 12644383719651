import { ReactNode } from "react";
import "./styles.css";

interface LayoutTabelaHeaderProps {
    children?: ReactNode;
}

export default function LayoutTabelaHeader(props: LayoutTabelaHeaderProps) {
    return <div className="d-flex my-col-session-filter border-bottom z-1 w-100 ps-3 pe-3">{props.children}</div>;
}
