import { useCallback, useContext } from "react";
import { PartialEntity, tVisita } from "../../interfaces";
import { OffcanvasNovoDado } from "./OffcanvasNovoDado";
import { OffcanvasEditarDado } from "./OffcanvasEditarDado.tsx";
import { OffcanvasDeletarDado } from "./OffcanvasDeletarDado.tsx";
import utils from "../../utils";
import { ContextUser } from "../../contexts/ContextUser";
import FormVisita, { tNovaVisita } from "../forms/FormVisita";
import useVisita from "../../hooks/useVisita";

export type IOffcanvasVisitaProps = {
    mostrarForm: boolean;
    onTerminou?: () => void;
    onCancel?: () => void;
} & (
    | { tipo: "criar"; dados?: null }
    | { tipo: "editar"; dados: null | (tNovaVisita & PartialEntity<tVisita, "id">) }
    | { tipo: "deletar"; dados: null | tVisita }
);

const FORM = "form-vista";
//export const TEXT_CONFIRM_VISITA_VERIFICADA = `A visita será marcada como verificada e não poderá ser editada. Confirme apenas se os dados estiverem corretos.`;

export default function OffcanvasVisita(props: IOffcanvasVisitaProps) {
    //CONTEXTOS
    const { usuario } = useContext(ContextUser);

    //HOOKS
    const { criar, editar, deletar, estaCriando, estaEditando, estaDeletando, cancelar } = useVisita();

    //VARAIVEIS
    const { onTerminou, onCancel, dados, tipo } = props;

    //EVENTS
    const handleOnCancel = useCallback(() => {
        cancelar();
        if (onCancel) onCancel();
    }, [onCancel, cancelar]);

    const handleOnCriar = useCallback(
        async (visita: tNovaVisita) => {
            try {
                await criar([visita]);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [criar, onTerminou]
    );

    const handleOnEditar = useCallback(
        async (visita: tNovaVisita & PartialEntity<tVisita, "id">) => {
            try {
                await editar(visita);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [editar, onTerminou]
    );

    const handleOnDeletar = useCallback(
        async (id: string) => {
            try {
                await deletar(id, { vendedorId: usuario?.vendedorUser?.id });
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [deletar, onTerminou, usuario]
    );

    return props.tipo === "criar" ? (
        <OffcanvasNovoDado.Root show={props.mostrarForm}>
            <OffcanvasNovoDado.Header>
                <h4>Nova Visita</h4>
            </OffcanvasNovoDado.Header>
            <OffcanvasNovoDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ carregando: estaCriando, type: "submit", form: FORM }}
            >
                <FormVisita
                    formId={FORM}
                    valoresIniciais={{
                        vendedorId: "",
                        clienteId: "",
                        dataVisita: utils.retornaDataAtual({ segundos: 0, minutos: 0, hora: 0 }),
                        gastos: [],
                        carroEmpresa: null,
                        kmInicial: null,
                        kmFinal: null,
                        comentarios: null,
                        remoto: false,
                    }}
                    onSubmit={handleOnCriar}
                />
            </OffcanvasNovoDado.Body>
        </OffcanvasNovoDado.Root>
    ) : tipo === "editar" ? (
        <OffcanvasEditarDado.Root show={props.mostrarForm}>
            <OffcanvasEditarDado.Header>
                <h4>Editar Visita</h4>
            </OffcanvasEditarDado.Header>
            <OffcanvasEditarDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ form: FORM, carregando: estaEditando, type: "submit" }}
            >
                {dados && (
                    <FormVisita
                        formId={FORM}
                        valoresIniciais={{
                            vendedorId: dados.vendedorId,
                            clienteId: dados.clienteId,
                            dataVisita: dados.dataVisita,
                            gastos: dados.gastos.map((gasto) => ({
                                nota: gasto.nota,
                                valor: gasto.valor,
                                tipoGasto: gasto.tipoGasto,
                            })),
                            carroEmpresa: dados.carroEmpresa,
                            kmInicial: dados.kmInicial,
                            kmFinal: dados.kmFinal,
                            comentarios: dados.comentarios,
                            remoto: dados.remoto,
                        }}
                        onSubmit={(visita) => handleOnEditar({ id: dados.id, ...visita })}
                    />
                )}
            </OffcanvasEditarDado.Body>
        </OffcanvasEditarDado.Root>
    ) : tipo === "deletar" ? (
        <OffcanvasDeletarDado.Root show={props.mostrarForm}>
            <OffcanvasDeletarDado.Header>
                <h4>Deletar Visita</h4>
            </OffcanvasDeletarDado.Header>
            {dados && (
                <OffcanvasDeletarDado.Body
                    acceptButtonProps={{ onClick: () => handleOnDeletar(dados.id), carregando: estaDeletando }}
                    cancelButtonProps={{ onClick: handleOnCancel }}
                >
                    <p>
                        Você está prestes a deletar permanentemente do sistema a visita com o cliente{" "}
                        <strong>{dados.cliente.name}</strong> do vendedor <strong>{dados.vendedor.user.name}</strong>.
                        Clique no botão <strong className="text-danger">Deletar</strong> logo abaixo para prosseguir.
                    </p>
                </OffcanvasDeletarDado.Body>
            )}
        </OffcanvasDeletarDado.Root>
    ) : undefined;
}
