import TabelaRoot from "./TabelaRoot";
import TabelaBody from "./TabelaBody";
import TabelaHeader from "./TabelaHeader";
import TabelaComponentTop from "./TabelaComponentTop";
import TabelaTable from "./TabelaTable";

export const Tabela = {
    Root: TabelaRoot,
    Header: TabelaHeader,
    Body: TabelaBody,
    ComponentTop: TabelaComponentTop,
    Table: TabelaTable,
};
