import React, { useCallback, useRef, useState } from "react";
import { Col, Container, Overlay, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { tVenda } from "../../interfaces";
import utils from "../../utils";
import "./styles.css";

interface ISpanVendaImpostoProps {
    venda: tVenda;
}
export default function SpanVendaImposto(props: ISpanVendaImpostoProps) {
    //ESTADOS
    const [mostrarInfo, setMostrarInfo] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);

    //VARIAVEIS
    const { venda } = props;

    //EVENTOS
    const handleOnMostrarInfo = useCallback(() => setMostrarInfo(true), []);
    const handleOnEsconderInfo = useCallback(() => setMostrarInfo(false), []);

    return (
        <>
            <div
                className="d-flex w-100 overflow-hidden text-info justify-content-end"
                role="button"
                onClick={handleOnMostrarInfo}
                ref={divRef}
            >
                <OverlayTrigger placement="top" flip overlay={<Tooltip>Clique para saber mais</Tooltip>}>
                    <span className="text-nowrap">
                        {utils.retornaValorMonetario(venda.valorImpostos, "BRL")}
                        <i className="bi bi-caret-down-fill ms-1" />
                    </span>
                </OverlayTrigger>
            </div>

            <Overlay
                rootClose
                onHide={handleOnEsconderInfo}
                target={divRef.current}
                show={mostrarInfo}
                flip
                placement="bottom"
            >
                <Popover className="shadow">
                    <Popover.Body className="my-container-controle-borda p-2">
                        <Container fluid className="p-1">
                            <Row className="my-child-controle-borda border-bottom">
                                <Col sm="12" className="mb-1">
                                    <span className="fw-bold">Produtos</span>
                                </Col>
                                {venda.vendasProduto?.length < 1 && (
                                    <Col sm="12" className="text-center">
                                        --- Nenhum produto ---
                                    </Col>
                                )}
                                {venda.vendasProduto?.map((vendaProduto) => {
                                    return (
                                        <React.Fragment key={vendaProduto.produtoId}>
                                            <Col sm="12" className="text-start lh-1 text-primary">
                                                {vendaProduto.produto.name}
                                            </Col>
                                            <Col
                                                sm="12"
                                                className="d-flex justify-content-between align-items-end lh-1"
                                            >
                                                <span>Dedutíveis distribuidor:</span>
                                                <span>{utils.retornaValorMonetario(vendaProduto.dedutiveisDistribuidor ?? 0, "BRL")}</span>
                                            </Col>
                                            <Col
                                                sm="12"
                                                className="d-flex justify-content-between align-items-end lh-1 mb-2"
                                            >
                                                <span>Nota:</span>
                                                <span>
                                                    {utils.retornaValorMonetario(
                                                        vendaProduto.valorImpostoNota ?? 0,
                                                        "BRL"
                                                    )}
                                                </span>
                                            </Col>
                                        </React.Fragment>
                                    );
                                })}
                                <Col sm="12" className="mt-1 mb-1">
                                    <span className="fw-bold">Serviços</span>
                                </Col>
                                {venda.vendasServico?.length < 1 && (
                                    <Col sm="12" className="text-center">
                                        --- Nenhum serviço ---
                                    </Col>
                                )}
                                {venda.vendasServico?.map((vendaServico) => {
                                    return (
                                        <React.Fragment key={vendaServico.servicoId}>
                                            <Col sm="12" className="text-start lh-1 text-primary">
                                                {vendaServico.servico.name}
                                            </Col>
                                            <Col
                                                sm="12"
                                                className="d-flex justify-content-between align-items-end lh-1"
                                            >
                                                <span>Dedutíveis distribuidor:</span>
                                                <span>{utils.retornaValorMonetario(vendaServico.dedutiveisDistribuidor ?? 0, "BRL")}</span>
                                            </Col>

                                            <Col
                                                sm="12"
                                                className="d-flex justify-content-between align-items-end lh-1 mb-2"
                                            >
                                                <span>Nota:</span>
                                                <span>
                                                    {utils.retornaValorMonetario(
                                                        vendaServico.valorImpostoNota ?? 0,
                                                        "BRL"
                                                    )}
                                                </span>
                                            </Col>
                                        </React.Fragment>
                                    );
                                })}
                                <Col
                                    sm="12"
                                    className="d-flex justify-content-between fw-bold align-items-end mt-2 lh-1 border-top pt-2"
                                >
                                    <span>Impostos totais:</span>
                                    <span>{utils.retornaValorMonetario(venda.valorImpostos, "BRL")}</span>
                                </Col>
                            </Row>
                        </Container>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}
