import { ReactNode } from "react";
import { Table } from "react-bootstrap";
import "./styles.css";

interface ITabelaProps {
    children?: ReactNode;
}

export default function TabelaTable(props: ITabelaProps) {
    return (
        <Table striped bordered hover className={"my-table position-relative z-1 mt-3"}>
            {props.children}
        </Table>
    );
}
