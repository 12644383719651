import { Stack } from "react-bootstrap";
import CardDashboardResumo from "./CardDashboardResumo";
import { tDashMetaAtingida, tMetricaDashboard } from "../../interfaces";
import utils from "../../utils";
import { useCallback, useEffect, useState } from "react";
import { tTipoMeta } from "../metas/MetasVendedor";
import GroupRadio from "../botoes/GroupRadio";
import { v4 } from "uuid";
import "./styles.css";

export default function CardMetaAtingida({
    metrica,
    ...props
}: {
    dados: tDashMetaAtingida;
    metrica: tMetricaDashboard;
}) {
    //ESTADOS
    const [tipoMetaSelected, setTipoMetaSelected] = useState<tTipoMeta>("FATURAMENTO");

    //VARIAVEIS
    const valorMetaVendaAtingidaAtual = props.dados.valorVendaAtingidoAtual;
    const valorMetaVendaAtual = props.dados.valorVendaAtual;
    const porcentagemVendaAtingida = (valorMetaVendaAtingidaAtual / valorMetaVendaAtual) * 100;

    const valorMetaFaturamentoAtingidaAtual = props.dados.valorFaturamentoAtingidoAtual;
    const valorMetaFaturamentoAtual = props.dados.valorFaturamentoAtual;
    const porcentagemFaturamentoAntingido = (valorMetaFaturamentoAtingidaAtual / valorMetaFaturamentoAtual) * 100;

    const valorMetaVisitaAtingidaAtual = props.dados.valorMetaAltAtingidoAtual;
    const valorMetaVisitaAtual = props.dados.valorMetaAltAtual;
    const porcentagemVisitaAntingida = (valorMetaVisitaAtingidaAtual / valorMetaVisitaAtual) * 100;

    const retornaTextoValorAtingido = useCallback(() => {
        switch (tipoMetaSelected) {
            case "FATURAMENTO":
                return porcentagemFaturamentoAntingido;
            case "VENDA":
                return porcentagemVendaAtingida;
            case "VISITA":
                return porcentagemVisitaAntingida;
        }
    }, [tipoMetaSelected, porcentagemFaturamentoAntingido, porcentagemVendaAtingida, porcentagemVisitaAntingida]);

    const retornaTextoValores = useCallback(() => {
        switch (tipoMetaSelected) {
            case "FATURAMENTO":
                return (
                    utils.retornaValorMonetario(valorMetaFaturamentoAtingidaAtual, "BRL") +
                    "/" +
                    utils.retornaValorMonetario(valorMetaFaturamentoAtual, "BRL")
                );
            case "VENDA":
                return (
                    utils.retornaValorMonetario(valorMetaVendaAtingidaAtual, "BRL") +
                    "/" +
                    utils.retornaValorMonetario(valorMetaVendaAtual, "BRL")
                );
            case "VISITA":
                return `${valorMetaVisitaAtingidaAtual}/${valorMetaVisitaAtual}`;
        }
    }, [
        tipoMetaSelected,
        valorMetaFaturamentoAtingidaAtual,
        valorMetaFaturamentoAtual,
        valorMetaVendaAtingidaAtual,
        valorMetaVendaAtual,
        valorMetaVisitaAtingidaAtual,
        valorMetaVisitaAtual,
    ]);

    //EVENTOS
    useEffect(() => {
        if (metrica === "FATURAMENTO") setTipoMetaSelected("FATURAMENTO");
        else if (metrica === "VALOR_VENDA") setTipoMetaSelected("VENDA");
        else setTipoMetaSelected("VISITA");
    }, [metrica]);

    return (
        <CardDashboardResumo.Root className="my-card-meta-atingida position-relative">
            <i className={"position-absolute end-0 top-0 mt-1 me-2 bi bi-pin-fill fs-5"} />
            <GroupRadio.Root className="position-absolute bottom-0 end-0 mb-1 me-1 gap-1">
                {utils.retornaArrayTipoMeta().map((tipoMeta) => (
                    <GroupRadio.Item
                        onActive={() => setTipoMetaSelected(tipoMeta)}
                        className="me-2 p-0 ps-2 pe-2 lh-1 pb-1"
                        checked={tipoMetaSelected === tipoMeta}
                        key={v4()}
                        name="dashTipoMeta"
                    >
                        <small> {utils.retornaTextoTipoMeta(tipoMeta)}</small>
                    </GroupRadio.Item>
                ))}
            </GroupRadio.Root>
            <CardDashboardResumo.Left>
                <i className="bi bi-crosshair2 text-dark display-3" />
            </CardDashboardResumo.Left>
            <CardDashboardResumo.Right>
                <Stack className="text-dark text-nowrap">
                    <h6 className="mb-0">Meta Atingida</h6>
                    <Stack className="d-flex w-100">
                        <span className="fs-4">
                            {tipoMetaSelected === "FATURAMENTO"}
                            {isNaN(retornaTextoValorAtingido()) ? "--,--" : retornaTextoValorAtingido().toFixed(2)}%
                        </span>
                        <div className="d-flex">
                            <small>{retornaTextoValores()}</small>
                        </div>
                    </Stack>
                </Stack>
            </CardDashboardResumo.Right>
        </CardDashboardResumo.Root>
    );
}
