import { Col, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import { PartialEntity, tFornecedor } from "../../interfaces";
import * as yup from "yup";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import { v4 } from "uuid";

//TYPES
export type tNovoFornecedor = PartialEntity<tFornecedor, "name" | "cnpj" | "email">;

export interface IFormFornecedorProps {
    formId?: string;
    onSubmit: (fornecedor: tNovoFornecedor) => void | Promise<void>;
    valoresIniciais: tNovoFornecedor;
}

//FORM
export default function FormFornecedor(props: IFormFornecedorProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    const onSubmitFormik = useCallback(
        async (fornecedor: tNovoFornecedor, helpers: FormikHelpers<tNovoFornecedor>) => {
            try {
                await onSubmit(fornecedor);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaFornecedor}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Nome do fornecedor</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.name}
                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">CNPJ do fornecedor</Form.Label>
                                <Form.Control
                                    as={IMaskInput}
                                    required
                                    type="text"
                                    unmask
                                    mask="00.000.000/0000-00"
                                    value={values.cnpj}
                                    onAccept={(cnpj: string) => setValues({ ...values, cnpj })}
                                    isInvalid={!!errors.cnpj}
                                />
                                <Form.Control.Feedback type="invalid">{errors.cnpj}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">E-mail do responsável</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.email}
                                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

//Adicionei o export que estava faltando => Vinicius
export const yupEsquemaFornecedor: yup.ObjectSchema<tNovoFornecedor> = yup.object({
    name: yup.string().required("Campo necessário.").max(256, "Deve ter no máximo 256 carecteres."),
    cnpj: yup
        .string()
        .required("Campo necessário.")
        .min(14, "Um CNPJ válido deve ter 14 dígitos.")
        .max(14, "Um CNPJ válido deve ter 14 dígitos."),
    email: yup
        .string()
        .email("Insira um e-mail válido.")
        .required("Campo necessário.")
        .max(256, "Deve ter no máximo 256 caracteres."),
});
