import { ReactNode, useCallback, useState } from "react";
import BotaoEditar from "../../botoes/BotaoEditar";
import BotaoDeletar from "../../botoes/BotaoDeletar";
import BotaoCustom, { BotaoCustomProps } from "../../botoes/BotaoCustom";
import { PartialEntity } from "../../../interfaces";
import "./styles.css";

interface ITabelaBodyProps {
    dados: any[];
    campoUnico: string;
    campoReferencia?: string;
    children: (dado: any) => ReactNode;
    onEdit?: (dado: any) => void;
    onDelete?: (dado: any) => void;
    onClickCustomButton?: (dado: any) => void;
    onClickAuditoria?: (dado: any) => void;
    customButton?: (dado: any) => PartialEntity<BotaoCustomProps, "tooltip" | "children">;
    disabledEdit?: (dado: any) => boolean;
    disabledDelete?: (dado: any) => boolean;
    disabledCustom?: (dado: any) => boolean;
    disabledAuditoria?: (dado: any) => boolean;
}

export default function TabelaBody(props: ITabelaBodyProps) {
    //ESTADOS
    const [idDadoOverOptions, setIdDadoOverOptions] = useState<string | null>(null);

    //VARIAVEIS
    const { onEdit, onDelete, customButton, onClickCustomButton, onClickAuditoria } = props;

    //EVENTOS
    const handleOnOverOptions = useCallback((id: string) => {
        setIdDadoOverOptions(id);
    }, []);

    const handleOnOutOptions = useCallback(() => {
        setIdDadoOverOptions(null);
    }, []);

    return (
        <tbody className="my-table-body">
            {props.dados.map((dado) => (
                <tr key={dado[props.campoUnico]} className="position-relative">
                    {props.children(dado)}
                    {(onDelete || onEdit || (onClickCustomButton && customButton) || onClickAuditoria) && (
                        <td
                            role="button"
                            className={`my-table-options d-flex position-absolute top-0 end-0 border z-1 border-0 me-1 shadow-sm rounded-start-pill rounded-end`}
                            onMouseOver={() => handleOnOverOptions(dado[props.campoUnico])}
                            onMouseOut={handleOnOutOptions}
                        >
                            <div className={dado[props.campoUnico] === idDadoOverOptions ? "d-flex" : "d-none"}>
                                {onClickAuditoria &&
                                    (props.disabledAuditoria ? !props.disabledAuditoria(dado) : true) && (
                                        <label>
                                            <BotaoCustom onClick={() => onClickAuditoria(dado)} tooltip="Auditoria">
                                                <i className="bi bi-archive text-secondary-dark" />
                                            </BotaoCustom>
                                        </label>
                                    )}
                                {onClickCustomButton &&
                                    customButton &&
                                    (props.disabledCustom ? !props.disabledCustom(dado) : true) && (
                                        <label>
                                            <BotaoCustom
                                                {...customButton(dado)}
                                                onClick={() => onClickCustomButton(dado)}
                                            />
                                        </label>
                                    )}
                                {onEdit !== undefined && (props.disabledEdit ? !props.disabledEdit(dado) : true) && (
                                    <label>
                                        <BotaoEditar
                                            tooltip={
                                                props.campoReferencia
                                                    ? `Editar ${dado[props.campoReferencia]?.split(" ")[0]}`
                                                    : "Editar"
                                            }
                                            onClick={() => onEdit(dado)}
                                        />
                                    </label>
                                )}
                                {onDelete !== undefined &&
                                    (props.disabledDelete ? !props.disabledDelete(dado) : true) && (
                                        <label>
                                            <BotaoDeletar
                                                tooltip={
                                                    props.campoReferencia
                                                        ? `Deletar ${dado[props.campoReferencia]?.split(" ")[0]}`
                                                        : "Deletar"
                                                }
                                                onClick={() => onDelete(dado)}
                                            />
                                        </label>
                                    )}
                            </div>

                            <span
                                className={`my-table-span-icon-arrow ${
                                    dado[props.campoUnico] === idDadoOverOptions ? "open" : ""
                                }`}
                            >
                                <i className={"bi bi-chevron-left text-dark"} />
                            </span>
                        </td>
                    )}
                </tr>
            ))}
        </tbody>
    );
}
