import { useCallback, useRef, useState } from "react";
import { Col, Container, Overlay, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { tVisita } from "../../interfaces";
import "./styles.css";
import utils from "../../utils";

interface SpanVisitaKilometragemProps {
    visita: tVisita;
}
export default function SpanVisitaKilometragem(props: SpanVisitaKilometragemProps) {
    //ESTADOS
    const [mostrarInfo, setMostrarInfo] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);

    //VARIAVEIS
    const { visita } = props;
    const { kmInicial, kmFinal } = visita;

    const retornaTextoCarroUsado = useCallback(() => {
        if (utils.isNil(kmInicial) || utils.isNil(kmFinal)) return `----`;
        return `${(kmFinal as number) - (kmInicial as number)} km`;
    }, [kmInicial, kmFinal]);

    //EVENTOS
    const handleOnMostrarInfo = useCallback(() => setMostrarInfo(true), []);
    const handleOnEsconderInfo = useCallback(() => setMostrarInfo(false), []);

    return (
        <>
            <div
                className="d-flex w-100 overflow-hidden text-info justify-content-end"
                role="button"
                onClick={handleOnMostrarInfo}
                ref={divRef}
            >
                <OverlayTrigger placement="top" flip overlay={<Tooltip>Clique para saber mais</Tooltip>}>
                    <span className="text-nowrap">
                        {retornaTextoCarroUsado()}
                        <i className="bi bi-caret-down-fill ms-1" />
                    </span>
                </OverlayTrigger>
            </div>

            <Overlay
                rootClose
                onHide={handleOnEsconderInfo}
                target={divRef.current}
                show={mostrarInfo}
                flip
                placement="bottom"
            >
                <Popover className="shadow">
                    <Popover.Body className="my-container-controle-borda p-2">
                        <Container fluid className="p-1">
                            {utils.isNil(kmFinal) || utils.isNil(kmInicial) ? (
                                <Row className="my-child-controle-borda border-bottom border-bottom">
                                    <Col sm="12" className="d-flex justify-content-center align-items-end lh-1 mt-1">
                                        <span> ---- </span>
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="my-child-controle-borda border-bottom border-bottom">
                                    <Col sm="12" className="text-start fw-bold">
                                        Kilometragem
                                    </Col>
                                    <Col sm="12" className="d-flex justify-content-between align-items-end lh-1 mt-1">
                                        <span>Km inicial:</span>
                                        <span>{kmInicial}</span>
                                    </Col>
                                    <Col
                                        sm="12"
                                        className="d-flex justify-content-between align-items-end lh-1 mt-1 border-bottom pb-2"
                                    >
                                        <span>Km final:</span>
                                        <span>{kmFinal}</span>
                                    </Col>
                                    <Col sm="12" className="d-flex justify-content-between align-items-end lh-1 mt-1">
                                        <span>Rodados:</span>
                                        <span>{(kmFinal as number) - (kmInicial as number)} km</span>
                                    </Col>
                                </Row>
                            )}
                        </Container>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}
