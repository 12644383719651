import { Button, Card, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { routes } from "../../router";
import api from "../../services/api";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import MyLink from "../links/MyLink";
import "./styles.css";
import { v4 } from "uuid";

let abortController: AbortController | undefined;

export default function CardRecuperarSenha() {
    //CONTEXTOS
    const { setPerigo, setSucesso } = useContext(ContextAlerta);

    //ESTADOS
    const [enviandoEmail, setEnviandoEmail] = useState(false);

    //EVENTOS
    useEffect(() => {
        return () => abortController?.abort();
    }, []);

    const handleSubmit = useCallback(
        async (dados: { email: string }, helpersFormik: FormikHelpers<{ email: string }>) => {
            abortController = new AbortController();
            const signal = abortController.signal;

            setEnviandoEmail(true);
            try {
                const urlToRedirect = window.location.origin + routes.criarNovaSenha;
                await api.recuperarSenha({ ...dados, redirect: urlToRedirect }, signal);
                helpersFormik.setSubmitting(false);
                setSucesso("As instruções para redefinir sua senha foram enviadas.");
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                console.error(err);
                setPerigo(utils.retornaMensagemErro(err));
            } finally {
                setEnviandoEmail(false);
            }
        },
        [setPerigo, setSucesso]
    );

    return (
        <Card className="my-card-recuperar-senha shadow border-primary">
            <Card.Body>
                <Col sm="12" className="text-center mb-3">
                    <Stack className="d-flex align-items-center gap-1">
                        <img className="my-img-logo" alt="logo" src="/logo192-col.png" />
                        <h4 className="mb-0 text-primary">NextGen ComissionPro</h4>
                    </Stack>
                </Col>
                <Col sm="12" className="lh-1 mb-2">
                    <div className="d-flex rounded bg-info bg-opacity-25 p-2">
                        <p className="my-card-recuperar-senha-text-info m-0 text-info small">
                            Caso o e-mail informado esteja cadastrado, serão enviadas instruções para recuperar sua
                            senha.
                        </p>
                    </div>
                </Col>
                <Col sm="12">
                    <Formik
                        validateOnChange={false}
                        validationSchema={esquemaRecuperarSenha}
                        onSubmit={handleSubmit}
                        initialValues={{ email: "" }}
                    >
                        {({ handleSubmit, handleChange, setValues, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit} className="text-end">
                                <Row className="mb-3 text-start">
                                    <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                        <Form.Label className="mb-1">E-mail</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Col sm="12">
                                        <MyLink to={routes.login}>Realizar login?</MyLink>
                                    </Col>
                                </Row>
                                <Button type="submit">
                                    Receber instruções
                                    {enviandoEmail && <Spinner size="sm" animation="grow" className="ms-1" />}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Card.Body>
        </Card>
    );
}

const esquemaRecuperarSenha: yup.ObjectSchema<{ email: string }> = yup.object({
    email: yup.string().required("Este campo é necessário.").email("Insira um e-mail válido."),
});
