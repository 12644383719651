import { Col, Row } from "react-bootstrap";
import LayoutDashboard from "../layouts/LayoutDashboard";
import {
    CardRankCategoriasPorVenda,
    CardMetaAtingida,
    CardTiposProdServ,
    CardTopClientes,
    CardTopVendedores,
    FiltroAnos,
    FiltroVendedores,
    FiltroEstadosVenda,
    DropdownMetricaDashboard,
    CardDashboard,
    EvolucaoDashboard,
} from "../components";
import { useEffect, useState } from "react";
import { tEstadoVenda, tMetricaDashboard } from "../interfaces";
import FiltroMes, { tPossivelMes } from "../components/filtros/FiltroMes";
import useDashboard, { tDadosDashboard } from "../hooks/useDashboard";
import utils from "../utils";
import useFiltros from "../hooks/useFiltros";

export default function Dashboard() {
    //HOOKS
    const { getFilter } = useFiltros("dashboard");
    const { buscaComErro, buscar, estaBuscando, cancelar } = useDashboard();

    //ESTADOS
    const [dados, setDados] = useState<null | tDadosDashboard>(null);
    const [metricaAtual, setMetricaAtual] = useState<tMetricaDashboard>("FATURAMENTO");

    //VARIABLES
    const mesInicial = utils.deStringPara<tPossivelMes>(getFilter("mesInicial"), "number");
    const mesFinal = utils.deStringPara<tPossivelMes>(getFilter("mesFinal"), "number");
    const estadoVendaSelecionado = (getFilter("estadoVenda") as tEstadoVenda | null) ?? undefined;
    const anoSelecionado = utils.deStringPara<number>(getFilter("ano"), "number");
    const metricaAtualTexto = utils.retornaTextoMetricaDasboard(metricaAtual);
    const vendedorIdSelecionado = getFilter("vendedor") ?? undefined;

    const valorAtual = dados?.valoresTotais.valorTotalAtual ?? 0;
    const valorAnterior = dados?.valoresTotais.valorTotalAnterior ?? 0;
    const ticketAtual = dados?.valoresTotais.avgValorTotalAtual ?? 0;
    const ticketAnterior = dados?.valoresTotais.avgValorTotalAnterior ?? 0;
    const margemContribuicaoAtual = dados?.margemContribuicao.margemContribuicaoAtual ?? 0;
    const margemContribuicaoAnterior = dados?.margemContribuicao.margemContribuicaoAnterior ?? 0;

    const porcentValor = valorAnterior > 0 ? ((valorAtual - valorAnterior) * 100) / valorAnterior : 0;
    const porcentTicket = ticketAnterior > 0 ? ((ticketAtual - ticketAnterior) * 100) / ticketAnterior : 0;
    const porcentMargemContribuicao =
        margemContribuicaoAnterior > 0
            ? ((margemContribuicaoAtual - margemContribuicaoAnterior) * 100) / margemContribuicaoAnterior
            : 0;

    //EVENTOS
    useEffect(() => {
        //Encontrando intervalo de datas
        const { dataFinalSelecionada, dataInicialSelecionada } = utils.retornaDatasFiltroMesAno(
            { inicio: mesInicial, fim: mesFinal },
            anoSelecionado
        );
        if (!dataFinalSelecionada || !dataInicialSelecionada) return;

        //Realizando busca dos dados
        buscar({
            dataInicio: dataInicialSelecionada,
            dataFim: dataFinalSelecionada,
            estadoVenda: estadoVendaSelecionado,
            vendedorId: vendedorIdSelecionado,
            metrica: metricaAtual,
        })
            .then((dados) => setDados(dados))
            .catch((err) => console.error(err));

        return () => cancelar();
    }, [
        buscar,
        cancelar,
        mesInicial,
        mesFinal,
        anoSelecionado,
        vendedorIdSelecionado,
        estadoVendaSelecionado,
        metricaAtual,
    ]);

    return (
        <>
            <LayoutDashboard.Root>
                <LayoutDashboard.Header>
                    <>
                        <FiltroAnos />
                        <FiltroMes />
                        <FiltroEstadosVenda />
                        <FiltroVendedores />
                    </>
                </LayoutDashboard.Header>
                <LayoutDashboard.BodyRoot>
                    <Col sm="12" className="mb-3">
                        <DropdownMetricaDashboard metricaAtual={metricaAtual} setMetricaAtual={setMetricaAtual} />
                    </Col>
                    <LayoutDashboard.Body carregando={estaBuscando || buscaComErro}>
                        {dados && (
                            <Col md="12">
                                <Row>
                                    <Col md="6" lg="3" className="mb-3">
                                        <CardDashboard
                                            label={metricaAtualTexto}
                                            valor={utils.retornaValorMonetario(
                                                dados.valoresTotais.valorTotalAtual,
                                                "BRL"
                                            )}
                                            icon="coin"
                                            porcent={{ valor: porcentValor, miniLabel: "vs. ano anterior" }}
                                        />
                                    </Col>
                                    <Col md="6" lg="3" className="mb-3">
                                        <CardDashboard
                                            label={"Ticket Médio (" + metricaAtualTexto + ")"}
                                            valor={utils.retornaValorMonetario(
                                                dados.valoresTotais.avgValorTotalAtual,
                                                "BRL"
                                            )}
                                            icon="graph-up"
                                            color="green"
                                            porcent={{ valor: porcentTicket, miniLabel: "vs. ano anterior" }}
                                        />
                                    </Col>
                                    <Col md="6" lg="3" className="mb-3">
                                        <CardDashboard
                                            pinned
                                            label={"Margem de Contribuição"}
                                            valor={`${margemContribuicaoAtual.toFixed(2)}%`}
                                            icon="bar-chart-line"
                                            color="blue"
                                            porcent={{
                                                valor: porcentMargemContribuicao,
                                                miniLabel: "vs. ano anterior",
                                            }}
                                        />
                                    </Col>
                                    <Col md="6" lg="3" className="mb-3">
                                        <CardMetaAtingida dados={dados.metaAtingida} metrica={metricaAtual} />
                                    </Col>

                                    <Col md="12" lg="5" className="mb-3">
                                        <EvolucaoDashboard
                                            currentColor="blue"
                                            title={"Evolução de " + metricaAtualTexto}
                                            dados={{
                                                anoAtual: dados.evolucoes.evolucaoAnoAtual.map(({ valor, mesAno }) => ({
                                                    valor,
                                                    mesAno,
                                                })),
                                                anoAnterior: dados.evolucoes.evolucaoAnoAnterior.map(
                                                    ({ valor, mesAno }) => ({
                                                        valor,
                                                        mesAno,
                                                    })
                                                ),
                                            }}
                                        />
                                    </Col>

                                    <Col md="12" lg="5" className="mb-3">
                                        <EvolucaoDashboard
                                            title={"Evolução de Ticket Médio (" + metricaAtualTexto + ")"}
                                            dados={{
                                                anoAtual: dados.evolucoes.evolucaoAnoAtual.map(
                                                    ({ valorTicketMedio, mesAno }) => ({
                                                        valor: valorTicketMedio,
                                                        mesAno,
                                                    })
                                                ),
                                                anoAnterior: dados.evolucoes.evolucaoAnoAnterior.map(
                                                    ({ valorTicketMedio, mesAno }) => ({
                                                        valor: valorTicketMedio,
                                                        mesAno,
                                                    })
                                                ),
                                            }}
                                        />
                                    </Col>
                                    <Col md="12" lg="2" className="mb-3">
                                        <CardTiposProdServ metrica={metricaAtual} dados={dados.vendasPorTipoProdServ} />
                                    </Col>

                                    <Col md="12" lg="4" className="mb-3">
                                        <CardRankCategoriasPorVenda
                                            metrica={metricaAtual}
                                            dados={dados.rankCategorias}
                                        />
                                    </Col>
                                    <Col md="12" lg="4" className="mb-3">
                                        <CardTopClientes metrica={metricaAtual} dados={dados.rankClientes} />
                                    </Col>
                                    {!vendedorIdSelecionado && (
                                        <Col md="12" lg="4" className="mb-3">
                                            <CardTopVendedores metrica={metricaAtual} dados={dados.rankVendedores} />
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        )}
                    </LayoutDashboard.Body>
                </LayoutDashboard.BodyRoot>
            </LayoutDashboard.Root>
        </>
    );
}
