import { ReactNode } from "react";
import { Offcanvas } from "react-bootstrap";

interface IOffcanvasEditarDadoRoot {
    show?: boolean;
    children?: ReactNode;
}
export default function OffcanvasEditarDadoRoot(props: IOffcanvasEditarDadoRoot) {
    return (
        <Offcanvas show={props.show} placement="end">
            {props.children}
        </Offcanvas>
    );
}
