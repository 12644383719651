import LayoutInfoBody from "./LayoutInfoBody";
import LayoutInfoBodyLeft from "./LayoutInfoBodyLeft";
import LayoutInfoBodyRight from "./LayoutInfoBodyRight";
import LayoutInfoHeader from "./LayoutInfoHeader";
import LayoutInfoHeaderLeft from "./LayoutInfoHeaderLeft";
import LayoutInfoHeaderRight from "./LayoutInfoHeaderRight";
import LayoutInfoRoot from "./LayoutInfoRoot";

export const LayoutInfo = {
    Root: LayoutInfoRoot,
    Body: LayoutInfoBody,
    Header: LayoutInfoHeader,
    HeaderLeft: LayoutInfoHeaderLeft,
    HeaderRight: LayoutInfoHeaderRight,
    BodyLeft: LayoutInfoBodyLeft,
    BodyRight: LayoutInfoBodyRight,
};
