import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tPapelUsuario, tUser } from "../../interfaces";
import { v4 } from "uuid";

//TYPES
export type tNovoUser = PartialEntity<tUser, "name" | "email" | "role" | "bloqueado">;

export interface IFormGerenteProps {
    formId?: string;
    onSubmit: (gerente: tNovoUser) => void | Promise<void>;
    valoresIniciais: tNovoUser;
}

//FORM
export default function FormGerente(props: IFormGerenteProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    const onSubmitFormik = useCallback(
        async (admin: tNovoUser, helpers: FormikHelpers<tNovoUser>) => {
            try {
                await onSubmit(admin);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaGerente}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Nome do gerente de vendas</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.name}
                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">E-mail do gerente de vendas</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.email}
                                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                <small className="text-secondary-dark lh-1">
                                    A senha de acesso será enviada a este e-mail.
                                </small>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaGerente: yup.ObjectSchema<tNovoUser> = yup.object({
    email: yup.string().email("Insira um e-mail válido.").required("Este campo é necessário."),
    role: yup.mixed<tPapelUsuario>().oneOf(["GERENTE_VENDAS"]).required("Este campo é necessário"),
    name: yup.string().required("Campo necessário.").max(256, "Deve ter no máximo 256 carecteres."),
    bloqueado: yup.boolean().required("Este campo é necessário."),
});
