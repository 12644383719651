import { createContext, ReactNode, useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Alerta } from "../components";
import { Button, Modal } from "react-bootstrap";

export const ContextAlerta = createContext<{
    setSucesso: (msg: string) => void;
    setAviso: (msg: string) => void;
    setPerigo: (msg: string) => void;
    setProcessando: (processando: boolean) => void;
    setConfirmar: (msg: ReactNode) => Promise<boolean>;
    alertas: IAlerta[];
}>({
    setSucesso: (msg: string) => {},
    setAviso: (msg: string) => {},
    setPerigo: (msg: string) => {},
    alertas: [],
    setProcessando: (processando: boolean) => {},
    setConfirmar: async (msg: ReactNode) => false,
});

export interface IAlerta {
    id: string;
    msg: string;
    date: Date;
    variant: "success" | "warning" | "danger";
    onClose: () => void;
}

export default function ProviderAlerta(props: { children: JSX.Element }) {
    //ESTADOS
    const [processando, setProcessando] = useState(false);
    const [alertas, setAlerta] = useState<IAlerta[]>([]);

    const [confirmMsg, setConfirmMsg] = useState<ReactNode | null>(null);
    const [resolveCallback, setResolveCallback] = useState<((value: boolean) => void) | null>(null);

    //EVENTOS
    const fechaAlerta = useCallback((id: string) => {
        setAlerta((alertas) => alertas.filter((alerta) => alerta.id !== id));
    }, []);

    const criaAlerta = useCallback(
        (msg: string, variant: "success" | "warning" | "danger") => {
            const id = uuidv4();
            const date = new Date();

            setAlerta((alertas) => [
                ...alertas.filter((alerta) => alerta.msg !== msg),
                { id, msg, date, variant, onClose: () => fechaAlerta(id) },
            ]);
        },
        [fechaAlerta]
    );

    const setSucesso = useCallback((msg: string) => criaAlerta(msg, "success"), [criaAlerta]);
    const setAviso = useCallback((msg: string) => criaAlerta(msg, "warning"), [criaAlerta]);
    const setPerigo = useCallback((msg: string) => criaAlerta(msg, "danger"), [criaAlerta]);

    const setConfirmar = useCallback(async (msg: ReactNode) => {
        setConfirmMsg(msg);
        return new Promise<boolean>(function (resolve) {
            setResolveCallback(() => resolve);
        });
    }, []);

    const handleOnClickConfirm = useCallback(() => {
        if (resolveCallback) resolveCallback(true);
        setConfirmMsg(null);
    }, [resolveCallback]);
    const handleOnClickCancel = useCallback(() => {
        if (resolveCallback) resolveCallback(false);
        setConfirmMsg(null);
    }, [resolveCallback]);

    return (
        <ContextAlerta.Provider value={{ setSucesso, setAviso, setPerigo, alertas, setProcessando, setConfirmar }}>
            <Alerta />
            {props.children}

            <Modal backdrop="static" size="sm" show={processando} keyboard={false}>
                <Modal.Header>
                    <h6>Por favor aguarde</h6>
                </Modal.Header>
                <Modal.Body>Processando sua solicitação...</Modal.Body>
            </Modal>

            <Modal show={confirmMsg !== null} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Deseja continuar?</Modal.Title>
                </Modal.Header>
                <Modal.Body>{confirmMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleOnClickCancel}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleOnClickConfirm}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </ContextAlerta.Provider>
    );
}
