import { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import DropdownCustomizacaoTabela from "../../dropdowns/DropdownCustomizacaoTabela";

export interface TabelaComponentTopProps {
    children?: ReactNode;
}

export default function TabelaComponentTop({ children }: TabelaComponentTopProps) {
    return (
        <Col sm="12" className={"position-relative z-2"}>
            <Row className="mt-3 position-relative">
                {children}
                <Col sm="auto" className="position-absolute end-0 top-0">
                    <DropdownCustomizacaoTabela />
                </Col>
            </Row>
        </Col>
    );
}
