import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { tEstadoVenda, PartialEntity, tVenda, tProduto, tServico } from "../../interfaces";
import * as yup from "yup";
import SelectCliente from "../selects/SelectCliente";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import FormVendaProduto, { tNovaVendaProduto, yupEsquemaVendaProduto } from "./FormVendaProduto";
import { OffcanvasNovoDado } from "../offcanvas/OffcanvasNovoDado";
import { v4 } from "uuid";
import { OffcanvasEditarDado } from "../offcanvas/OffcanvasEditarDado.tsx";
import BotaoFechar from "../botoes/BotaoFechar";
import BotaoLabel from "../botoes/BotaoLabel";
import FormVendaServico, { tNovaVendaServico, yupEsquemaVendaServico } from "./FormVendaServico";
import InputDate from "../inputs/InputDate";
import useServico from "../../hooks/useServico";
import useProduto from "../../hooks/useProduto";
import { ContextUser } from "../../contexts/ContextUser";
import SelectFormaPagamento from "../selects/SelectFormaPagamento";
import SelectVendedoresPorDistComissao from "../selects/SelectVendedoresPorDistComissao";
import SelectContrato from "../selects/SelectContrato";

//TODO: Ao clicar em adicionar em todos os forms, buscar uma forma de scrolar até o erro, caso contrário o erro aparece mas não está na tela.
//TYPES
export type tNovaVenda = PartialEntity<
    tVenda,
    | "clienteId"
    | "codigoNota"
    | "dataFaturamento"
    | "dataVenda"
    | "estadoVenda"
    | "formaParcelamento"
    | "formaPagamentoId"
    | "numOportunidadeCRM"
    | "numPedidoDistribuidor"
    | "comissaoPrimeiraParcela"
    | "vendaRenovacao"
    | "contratoId"
> & {
    datasBoleto: Date[];
    vendasProduto: tNovaVendaProduto[];
    vendasServico: tNovaVendaServico[];
    vendedoresId: string[];
};
export interface IFormVendaProps {
    formId?: string;
    onSubmit: (venda: tNovaVenda, params: { multiplasVendas: boolean }) => void | Promise<void>;
    valoresIniciais: tNovaVenda;
    options?: { aceitarVendasMultiplas?: boolean };
}

//FORM
export default function FormVenda(props: IFormVendaProps) {
    //HOOKS
    const { buscar: buscarServicos, cancelar: cancelarBuscaServico } = useServico();
    const { buscar: buscarProdutos, cancelar: cancelarBuscaProduto } = useProduto();

    //CONTEXTOS
    const { setPerigo, setAviso } = useContext(ContextAlerta);
    const { podeAdicionarVenda, podeEditarVenda } = useContext(ContextUser);

    //ESTADOS
    const [multiplasVendas, setMultiplasVendas] = useState(false);

    const [produtos, setProdutos] = useState<tProduto[]>([]);
    const [servicos, setServicos] = useState<tServico[]>([]);

    const [vendaProdutosServicos, setVendaProdutosServicos] = useState<
        PartialEntity<tNovaVenda, "vendasProduto" | "vendasServico">
    >({ vendasProduto: props.valoresIniciais.vendasProduto, vendasServico: props.valoresIniciais.vendasServico });

    const [mostrarAdicionarVendaProduto, setMostrarAdicionarVendaProduto] = useState(false);
    const [vendaProdutoEditando, setVendaProdutoEditando] = useState<tNovaVendaProduto | null>(null);

    const [mostrarAdicionarVendaServico, setMostrarAdicionarVendaServico] = useState(false);
    const [vendaServicoEditando, setVendaServicoEditando] = useState<tNovaVendaServico | null>(null);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;
    const FORM_PRODUTO = "form-add-edit-produto";
    const FORM_SERVICO = "form-add-edit-servico";

    //EVENTOS
    useEffect(() => {
        buscarProdutos()
            .then((produtos) => setProdutos(produtos))
            .catch((err) => {
                console.error(err);
                setAviso("Não foi possível encontrar os produtos.");
            });
        buscarServicos()
            .then((servicos) => setServicos(servicos))
            .catch((err) => {
                console.error(err);
                setAviso("Não foi possível encontrar os serviços.");
            });
        return () => {
            cancelarBuscaProduto();
            cancelarBuscaServico();
        };
    }, [cancelarBuscaProduto, buscarProdutos, cancelarBuscaServico, buscarServicos, setAviso]);

    const handleOnClickAddVendaProduto = useCallback(() => setMostrarAdicionarVendaProduto(true), []);
    const handleOnClickCancelAddProduto = useCallback(() => setMostrarAdicionarVendaProduto(false), []);

    const handleOnClickCancelEditProduto = useCallback(() => setVendaProdutoEditando(null), []);
    const handleOnClickEditVendaProduto = useCallback(
        (vendaProduto: tNovaVendaProduto) => setVendaProdutoEditando(vendaProduto),
        []
    );

    const handleOnRemoveVendaProduto = useCallback(
        (vendaProduto: tNovaVendaProduto) => {
            setVendaProdutosServicos({
                ...vendaProdutosServicos,
                vendasProduto: vendaProdutosServicos.vendasProduto.filter(
                    ({ produtoId }) => produtoId !== vendaProduto.produtoId
                ),
            });
        },
        [vendaProdutosServicos]
    );

    const handleOnSubmitVendaProduto = useCallback(
        (vendaProduto: tNovaVendaProduto) => {
            const produtoExisteNaVenda = vendaProdutosServicos.vendasProduto.some(
                ({ produtoId }) => produtoId === vendaProduto.produtoId
            );

            if (
                produtoExisteNaVenda &&
                (!vendaProdutoEditando || vendaProdutoEditando.produtoId !== vendaProduto.produtoId)
            )
                return setPerigo("Este produto já existe na venda.");

            //Adiciona vendaProduto na venda
            if (!vendaProdutoEditando) {
                setVendaProdutosServicos({
                    ...vendaProdutosServicos,
                    vendasProduto: [...vendaProdutosServicos.vendasProduto, vendaProduto],
                });
                return setMostrarAdicionarVendaProduto(false);
            }

            //Edita vendaProduto na venda
            setVendaProdutosServicos({
                ...vendaProdutosServicos,
                vendasProduto: vendaProdutosServicos.vendasProduto.map((vp) =>
                    vp.produtoId === vendaProdutoEditando.produtoId ? vendaProduto : vp
                ),
            });
            setVendaProdutoEditando(null);
        },
        [setPerigo, vendaProdutosServicos, vendaProdutoEditando]
    );

    const handleOnClickAddVendaServico = useCallback(() => setMostrarAdicionarVendaServico(true), []);
    const handleOnClickCancelAddServico = useCallback(() => setMostrarAdicionarVendaServico(false), []);

    const handleOnClickCancelEditVendaServico = useCallback(() => setVendaServicoEditando(null), []);
    const handleOnClickEditVendaServico = useCallback(
        (vendaServico: tNovaVendaServico) => setVendaServicoEditando(vendaServico),
        []
    );
    const handleOnRemoveVendaServico = useCallback(
        (vendaServico: tNovaVendaServico) => {
            setVendaProdutosServicos({
                ...vendaProdutosServicos,
                vendasServico: vendaProdutosServicos.vendasServico.filter(
                    ({ servicoId }) => servicoId !== vendaServico.servicoId
                ),
            });
        },
        [vendaProdutosServicos]
    );

    const handleOnSubmitVendaServico = useCallback(
        (vendaServico: tNovaVendaServico) => {
            const servicoExisteNaVenda = vendaProdutosServicos.vendasServico.some(
                ({ servicoId }) => servicoId === vendaServico.servicoId
            );

            if (
                servicoExisteNaVenda &&
                (!vendaServicoEditando || vendaServicoEditando.servicoId !== vendaServico.servicoId)
            )
                return setPerigo("Este serviço já existe na venda.");

            //Adicionando vendaServico na venda
            if (!vendaServicoEditando) {
                setVendaProdutosServicos({
                    ...vendaProdutosServicos,
                    vendasServico: [...vendaProdutosServicos.vendasServico, vendaServico],
                });
                return setMostrarAdicionarVendaServico(false);
            }

            //Editando vendaServico na venda
            setVendaProdutosServicos({
                ...vendaProdutosServicos,
                vendasServico: vendaProdutosServicos.vendasServico.map((vs) =>
                    vs.servicoId === vendaServicoEditando.servicoId ? vendaServico : vs
                ),
            });
            setVendaServicoEditando(null);
        },
        [setPerigo, vendaProdutosServicos, vendaServicoEditando]
    );

    const onSubmitFormik = useCallback(
        async (venda: tNovaVenda, helpers: FormikHelpers<tNovaVenda>) => {
            if (venda.datasBoleto.length !== venda.formaParcelamento)
                helpers.setErrors({
                    formaParcelamento: "O número de datas de boleto deve corresponder com este valor.",
                });
            if (vendaProdutosServicos.vendasProduto.length < 1 && vendaProdutosServicos.vendasServico.length < 1)
                return helpers.setErrors({
                    vendasProduto: "Adicione ao menos um serviço ou produto.",
                    vendasServico: "Adicione ao menos um produto ou serviço.",
                });

            try {
                await onSubmit({ ...venda, ...vendaProdutosServicos }, { multiplasVendas });
                helpers.setSubmitting(false);
                helpers.resetForm();
                setVendaProdutosServicos({ vendasProduto: [], vendasServico: [] });
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo, vendaProdutosServicos, multiplasVendas]
    );

    return (
        <>
            <Formik
                validateOnChange={false}
                validationSchema={yupEsquemaVenda}
                onSubmit={onSubmitFormik}
                initialValues={valoresIniciais}
            >
                {({ handleSubmit, values, errors, setValues }) => {
                    const arrNumDatasBoleto = new Array(values.formaParcelamento).fill(undefined);
                    return (
                        <Form id={formId} onSubmit={handleSubmit}>
                            <Row className="gap-3">
                                <Form.Group as={Col} sm="12" className="text-end" controlId={v4()}>
                                    <Form.Label className="mb-0">
                                        <Stack className="w-100 d-flex align-items-end">
                                            <span className="mb-1">Vendedores</span>
                                            <SelectVendedoresPorDistComissao
                                                idsVendedores={values.vendedoresId}
                                                setIdsVendedores={(ids) =>
                                                    setValues({ ...values, vendedoresId: ids ?? [] })
                                                }
                                            />
                                        </Stack>
                                    </Form.Label>
                                    <Form.Control className="d-none" isInvalid={!!errors.vendedoresId} />
                                    <Form.Control.Feedback type="invalid">{errors.vendedoresId}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm="12" className="text-end" controlId={v4()}>
                                    <Form.Label className="mb-0">
                                        <Stack className="w-100 d-flex align-items-end">
                                            <span className="mb-1">Cliente</span>
                                            <SelectCliente
                                                setIdSelecionado={(id) =>
                                                    setValues({ ...values, clienteId: id as string })
                                                }
                                                idSelecionado={values.clienteId}
                                            />
                                        </Stack>
                                    </Form.Label>
                                    <Form.Control className="d-none" isInvalid={!!errors.clienteId} />
                                    <Form.Control.Feedback type="invalid">{errors.clienteId}</Form.Control.Feedback>
                                </Form.Group>

                                <Col sm="12">
                                    <Row>
                                        <Form.Group as={Col} sm="12" controlId={v4()} className="border-top pt-2">
                                            <h6>Produtos</h6>
                                            <Form.Control isInvalid={!!errors.vendasProduto} className="d-none" />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vendasProduto as string}
                                            </Form.Control.Feedback>
                                            <ListaVendaProdutos
                                                vendasProduto={vendaProdutosServicos.vendasProduto}
                                                produtos={produtos}
                                                onClickAdd={handleOnClickAddVendaProduto}
                                                onClickEdit={handleOnClickEditVendaProduto}
                                                onClickDelete={handleOnRemoveVendaProduto}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} sm="12" controlId={v4()} className="border-top pt-2 mt-3">
                                            <h6>Serviços</h6>
                                            <Form.Control isInvalid={!!errors.vendasServico} className="d-none" />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vendasServico as string}
                                            </Form.Control.Feedback>
                                            <ListaVendaServico
                                                vendasServico={vendaProdutosServicos.vendasServico}
                                                servicos={servicos}
                                                onClickAdd={handleOnClickAddVendaServico}
                                                onClickEdit={handleOnClickEditVendaServico}
                                                onClickDelete={handleOnRemoveVendaServico}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Col>

                                <Col sm="12">
                                    <Row>
                                        <Col sm="12" className="border-top pt-2">
                                            <h6>Sobre a venda</h6>
                                        </Col>

                                        <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2 text-end">
                                            <Form.Label className="mb-0">
                                                <Stack className="w-100 d-flex align-items-end">
                                                    <span className="mb-1">Contrato</span>
                                                    <SelectContrato
                                                        idSelecionado={values.contratoId}
                                                        setIdSelecionado={(contratoId) =>
                                                            setValues({ ...values, contratoId })
                                                        }
                                                    />
                                                </Stack>
                                            </Form.Label>
                                            <Form.Control className="d-none" isInvalid={!!errors.vendedoresId} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contratoId}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                            <Form.Label className="mb-1">Venda de renovação?</Form.Label>
                                            <Form.Check
                                                role="button"
                                                type="switch"
                                                id={v4()}
                                                onChange={(e) =>
                                                    setValues({ ...values, vendaRenovacao: e.target.checked })
                                                }
                                                label={values.vendaRenovacao ? "Sim" : "Não"}
                                                checked={values.vendaRenovacao}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.vendaRenovacao}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                            <Form.Label className="mb-1">Estado da venda</Form.Label>
                                            <Form.Select
                                                value={values.estadoVenda}
                                                onChange={(e) =>
                                                    setValues({
                                                        ...values,
                                                        estadoVenda: e.target.value as tEstadoVenda,
                                                    })
                                                }
                                                isInvalid={!!errors.estadoVenda}
                                            >
                                                {utils.retornaArrayEstadoVenda().map((estado) => (
                                                    <option
                                                        value={estado}
                                                        key={estado}
                                                        disabled={
                                                            estado === "VERIFICADA" &&
                                                            (!podeEditarVenda({ estadoVenda: "VERIFICADA" }) ||
                                                                !podeAdicionarVenda({ estadoVenda: "VERIFICADA" }))
                                                        }
                                                    >
                                                        {utils.retornaTextoEstadoVenda(estado)}
                                                    </option>
                                                ))}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                {errors.estadoVenda}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                            <Form.Label className="mb-1">Código da nota</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.codigoNota ?? ""}
                                                onChange={(e) =>
                                                    setValues({
                                                        ...values,
                                                        codigoNota: e.target.value === "" ? null : e.target.value,
                                                    })
                                                }
                                                isInvalid={!!errors.codigoNota}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.codigoNota}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                            <Form.Label className="mb-1">Num. Oportunidade CRM</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.numOportunidadeCRM}
                                                onChange={(e) =>
                                                    setValues({ ...values, numOportunidadeCRM: e.target.value })
                                                }
                                                isInvalid={!!errors.numOportunidadeCRM}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.numOportunidadeCRM}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                            <Form.Label className="mb-1">Num. Pedido Distribuidor</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.numPedidoDistribuidor}
                                                onChange={(e) =>
                                                    setValues({ ...values, numPedidoDistribuidor: e.target.value })
                                                }
                                                isInvalid={!!errors.numPedidoDistribuidor}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.numPedidoDistribuidor}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="6" controlId={v4()} className="mb-2">
                                            <Form.Label className="mb-1">Data da venda (CRM)</Form.Label>
                                            <InputDate
                                                valor={values.dataVenda}
                                                onAccept={(dataVenda) => setValues({ ...values, dataVenda })}
                                                isInvalid={!!errors.dataVenda}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dataVenda as string}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="6" controlId={v4()} className="mb-2">
                                            <Form.Label className="mb-1">Data de faturamento</Form.Label>
                                            <InputDate
                                                valor={values.dataFaturamento}
                                                onAccept={(dataFaturamento) =>
                                                    setValues({ ...values, dataFaturamento })
                                                }
                                                isInvalid={!!errors.dataFaturamento}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dataFaturamento as string}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>

                                <Col sm="12">
                                    <Row>
                                        <Col sm="12" className="border-top pt-2">
                                            <h6>Pagamento</h6>
                                        </Col>
                                        <Form.Group as={Col} sm="12" className="text-end mb-2" controlId={v4()}>
                                            <Form.Label className="mb-0">
                                                <Stack className="w-100 d-flex align-items-end">
                                                    <span className="mb-1">Forma de pagamento</span>
                                                    <SelectFormaPagamento
                                                        setIdSelecionado={(id) =>
                                                            setValues({ ...values, formaPagamentoId: id as string })
                                                        }
                                                        idSelecionado={values.formaPagamentoId}
                                                    />
                                                </Stack>
                                            </Form.Label>
                                            <Form.Control className="d-none" isInvalid={!!errors.formaPagamentoId} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.formaPagamentoId}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} sm="12" controlId={v4()} className="mb-3">
                                            <Form.Label className="mb-1">Número de parcelas</Form.Label>
                                            <Form.Control
                                                required
                                                min={1}
                                                type="number"
                                                value={values.formaParcelamento}
                                                onChange={(e) =>
                                                    setValues({
                                                        ...values,
                                                        formaParcelamento: Number(e.target.value),
                                                        datasBoleto: new Array(Number(e.target.value))
                                                            .fill(0)
                                                            .map((_, index) =>
                                                                utils.retornaDataAtual({ mesesAdicionais: index })
                                                            ),
                                                    })
                                                }
                                                isInvalid={!!errors.formaParcelamento}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.formaParcelamento}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {arrNumDatasBoleto.map((_, index) => (
                                            <Form.Group key={index} as={Col} sm="6" controlId={v4()} className="mb-2">
                                                <Form.Label className="mb-1">Boleto #{index + 1}</Form.Label>
                                                <InputDate
                                                    valor={values.datasBoleto[index]}
                                                    onAccept={(dataBoleto) =>
                                                        setValues({
                                                            ...values,
                                                            datasBoleto: values.datasBoleto.map((existente, idx) =>
                                                                idx === index ? dataBoleto : existente
                                                            ),
                                                        })
                                                    }
                                                    isInvalid={!!errors.datasBoleto}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.datasBoleto as string}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ))}

                                        {props.options?.aceitarVendasMultiplas && values.formaParcelamento > 1 && (
                                            <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                                <Form.Label className="mb-1">
                                                    Criar múltiplas vendas para cada data boleto?
                                                </Form.Label>
                                                <Form.Check
                                                    role="button"
                                                    type="switch"
                                                    id={v4()}
                                                    onChange={(e) => setMultiplasVendas(e.target.checked)}
                                                    label={multiplasVendas ? "Sim" : "Não"}
                                                    checked={multiplasVendas}
                                                />
                                            </Form.Group>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>

            <OffcanvasNovoDado.Root show={mostrarAdicionarVendaProduto}>
                <OffcanvasNovoDado.Header>
                    <h4>Adicionar Produto à Venda</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: handleOnClickCancelAddProduto }}
                    acceptButtonProps={{ type: "submit", form: FORM_PRODUTO }}
                >
                    <FormVendaProduto
                        formId={FORM_PRODUTO}
                        onSubmit={handleOnSubmitVendaProduto}
                        valoresIniciais={{
                            produtoId: "",
                            cotacao: 1,
                            tipoMoeda: "BRL",
                            impostoNota: 0,
                            tipoFaturamento: "DIRETO",
                            dedutiveisDistribuidor: 0,
                            valorCustoUnit: 0,
                            quantidade: 1,
                            markup: 0,
                            fornecedoresId: [],
                        }}
                    />
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>

            <OffcanvasEditarDado.Root show={!!vendaProdutoEditando}>
                <OffcanvasEditarDado.Header>
                    <h4>Editar Produto da Venda</h4>
                </OffcanvasEditarDado.Header>
                <OffcanvasEditarDado.Body
                    cancelButtonProps={{ onClick: handleOnClickCancelEditProduto }}
                    acceptButtonProps={{ form: FORM_PRODUTO, type: "submit" }}
                >
                    {vendaProdutoEditando ? (
                        <FormVendaProduto
                            formId={FORM_PRODUTO}
                            valoresIniciais={vendaProdutoEditando}
                            onSubmit={handleOnSubmitVendaProduto}
                        />
                    ) : undefined}
                </OffcanvasEditarDado.Body>
            </OffcanvasEditarDado.Root>

            <OffcanvasNovoDado.Root show={mostrarAdicionarVendaServico}>
                <OffcanvasNovoDado.Header>
                    <h4>Adicionar Serviço à Venda</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: handleOnClickCancelAddServico }}
                    acceptButtonProps={{ type: "submit", form: FORM_SERVICO }}
                >
                    <FormVendaServico
                        servicos={servicos}
                        formId={FORM_SERVICO}
                        onSubmit={handleOnSubmitVendaServico}
                        valoresIniciais={{
                            servicoId: "",
                            impostoNota: 0,
                            dedutiveisDistribuidor: 0,
                            cotacao: 1,
                            markup: 0,
                            tipoFaturamento: "REVENDA",
                            qntdHorasComercial: 0,
                            qntdHorasEspecial: 0,
                            qntdHorasNaoComercial: 0,
                        }}
                    />
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>

            <OffcanvasEditarDado.Root show={!!vendaServicoEditando}>
                <OffcanvasEditarDado.Header>
                    <h4>Editar Serviço da Venda</h4>
                </OffcanvasEditarDado.Header>
                <OffcanvasEditarDado.Body
                    cancelButtonProps={{ onClick: handleOnClickCancelEditVendaServico }}
                    acceptButtonProps={{ form: FORM_SERVICO, type: "submit" }}
                >
                    {vendaServicoEditando ? (
                        <FormVendaServico
                            servicos={servicos}
                            formId={FORM_SERVICO}
                            onSubmit={handleOnSubmitVendaServico}
                            valoresIniciais={vendaServicoEditando}
                        />
                    ) : undefined}
                </OffcanvasEditarDado.Body>
            </OffcanvasEditarDado.Root>
        </>
    );
}

//FUNCOES AUXILIARES
function ListaVendaProdutos(props: {
    vendasProduto: tNovaVendaProduto[];
    produtos: tProduto[];
    onClickAdd: () => void;
    onClickEdit: (vendaProduto: tNovaVendaProduto) => void;
    onClickDelete: (vendaProduto: tNovaVendaProduto) => void;
}) {
    const valorVenda = props.vendasProduto.reduce((acc, vendaProduto) => {
        const custoTotalReal = vendaProduto.valorCustoUnit * vendaProduto.quantidade * vendaProduto.cotacao;
        const valorMarkup = (vendaProduto.markup * custoTotalReal) / 100;
        return (acc += custoTotalReal + valorMarkup);
    }, 0);

    return (
        <Row className="gap-2">
            {props.vendasProduto.map((vendaProduto) => {
                const produto = props.produtos.find(({ id }) => id === vendaProduto.produtoId);
                return (
                    <Col sm="12" key={v4()}>
                        <Stack className="d-flex w-100 position-relative border rounded shadow-sm p-2">
                            <div className="position-absolute end-0 top-0 mt-1 me-1 z-1">
                                <BotaoFechar onClick={() => props.onClickDelete(vendaProduto)} />
                            </div>
                            <BotaoLabel
                                variant="info"
                                texto={produto?.name ?? "--Nenhum--"}
                                onClick={() => props.onClickEdit(vendaProduto)}
                            />
                            <div className="d-flex w-100 justify-content-between">
                                <small>Qntd.</small>
                                <small>{vendaProduto.quantidade}</small>
                            </div>
                        </Stack>
                    </Col>
                );
            })}
            <Col sm="12" className="text-secondary-dark">
                <small>Valor venda: {utils.retornaValorMonetario(valorVenda, "BRL")}</small>
            </Col>
            <Col sm="12" className="text-end">
                <Button className="rounded-pill" size="sm" onClick={props.onClickAdd}>
                    Adicionar
                </Button>
            </Col>
        </Row>
    );
}

function ListaVendaServico(props: {
    vendasServico: tNovaVendaServico[];
    servicos: tServico[];
    onClickAdd: () => void;
    onClickEdit: (vendaServico: tNovaVendaServico) => void;
    onClickDelete: (vendaServico: tNovaVendaServico) => void;
}) {
    const valorVenda = props.vendasServico.reduce((acc, vendaServico) => {
        const servico = props.servicos.find(({ id }) => id === vendaServico.servicoId);
        const custoHora = servico?.valorCustoHora ?? 0;
        const custoHoraReal = vendaServico.cotacao * custoHora;
        const custoTotal =
            vendaServico.qntdHorasComercial * custoHoraReal +
            vendaServico.qntdHorasNaoComercial * custoHoraReal * 1.5 +
            vendaServico.qntdHorasEspecial * custoHoraReal * 2;
        const valorMarkup = (vendaServico.markup * custoTotal) / 100;
        return (acc += custoTotal + valorMarkup);
    }, 0);

    return (
        <Row className="gap-2">
            {props.vendasServico.map((vendaServico) => {
                const servico = props.servicos.find(({ id }) => id === vendaServico.servicoId);
                const horasTotais =
                    vendaServico.qntdHorasComercial +
                    vendaServico.qntdHorasNaoComercial +
                    vendaServico.qntdHorasEspecial;

                return (
                    <Col sm="12" key={v4()}>
                        <Stack className="d-flex w-100 position-relative border rounded shadow-sm p-2 ">
                            <div className="position-absolute end-0 top-0 mt-1 me-1 z-1">
                                <BotaoFechar onClick={() => props.onClickDelete(vendaServico)} />
                            </div>
                            <BotaoLabel
                                variant="info"
                                texto={servico?.name ?? "--Nenhum--"}
                                onClick={() => props.onClickEdit(vendaServico)}
                            />
                            <div className="d-flex w-100 justify-content-between">
                                <small>Horas totais</small>
                                <small>{horasTotais}</small>
                            </div>
                        </Stack>
                    </Col>
                );
            })}
            <Col sm="12" className="text-secondary-dark">
                <small>Valor venda: {utils.retornaValorMonetario(valorVenda, "BRL")}</small>
            </Col>
            <Col sm="12" className="text-end">
                <Button className="rounded-pill" size="sm" onClick={props.onClickAdd}>
                    Adicionar
                </Button>
            </Col>
        </Row>
    );
}

//ESQUEMA YUP
export const yupEsquemaVenda: yup.ObjectSchema<tNovaVenda> = yup.object({
    vendedoresId: yup
        .array(yup.string().required("Selecione um vendedor."))
        .required("Selecione ao menos uma opção.")
        .min(1, "Selecione uma opção."),
    clienteId: yup.string().required("Selecione um cliente."),
    codigoNota: yup.string().required("Este campo é obrigatório.").nullable(),
    contratoId: yup.string().required("Este campo é necessário.").nullable(),
    dataFaturamento: yup.date().required("Este campo é necessário."),
    dataVenda: yup.date().required("Este campo é orbigatório."),
    estadoVenda: yup.mixed<tEstadoVenda>().oneOf(utils.retornaArrayEstadoVenda()).required("Este campo é necessário."),
    formaParcelamento: yup.number().required("Este campo é necessário."),
    formaPagamentoId: yup.string().required("Selecione uma forma de pagamento."),
    numOportunidadeCRM: yup.string().required("Este campo é necessário."),
    numPedidoDistribuidor: yup.string().required("Este campo é necessário."),
    comissaoPrimeiraParcela: yup.boolean().required("Este campo é necesário."),
    vendaRenovacao: yup.boolean().required("Este campo é necessário."),
    datasBoleto: yup.array(yup.date().required("Este campo é necessário.")).required("Esta campo é necessário."),
    vendasProduto: yup.array(yupEsquemaVendaProduto).required("Este campo é necessário."),
    vendasServico: yup.array(yupEsquemaVendaServico).required("Este campo é necessário."),
});
