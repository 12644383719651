import { useCallback, useContext, useEffect, useState } from "react";
import { PartialEntity, tUser, tVendedor } from "../../interfaces";
import SelectMenu from "./SelectMenu";
import { ContextUser } from "../../contexts/ContextUser";
import OffcanvasVendedor from "../offcanvas/OffcanvasVendedor";
import useVendedor from "../../hooks/useVendedor";

export interface ISelectVendedorProps {
    idSelecionado?: string | null;
    setIdSelecionado?: (id: string | null) => void;
    size?: "sm";
}

export default function SelectVendedor(props: ISelectVendedorProps) {
    //HOOKS
    const { buscar, estaBuscando, buscaComErro, cancelar } = useVendedor();

    //CONTEXTOS
    const { podeEditarVendedor, podeDeletarVendedor, podeAdicionarVendedor, podeVisualizarVendedor, usuario } =
        useContext(ContextUser);

    //ESTADOS
    const [vendedores, setVendedores] = useState<tVendedor[]>([]);
    const [pesquisa, setPesquisa] = useState("");
    const [mostrarAdicionar, setMostrarAdicionar] = useState(false);
    const [vendedorDeletando, setVendedorDeletando] = useState<tVendedor | null>(null);
    const [vendedorEditando, setVendedorEditando] = useState<
        (tVendedor & PartialEntity<tUser, "email" | "name">) | null
    >(null);

    //VARIAVEIS
    const { setIdSelecionado, idSelecionado } = props;

    const vendedoresFiltrados = vendedores.filter((vendedor) =>
        vendedor.user.name.toLowerCase().includes(pesquisa.toLowerCase())
    );

    const valoresFormatados = vendedoresFiltrados.map((vendedor) => ({
        ...vendedor,
        texto: vendedor.user.name,
        selecionado: idSelecionado === vendedor.id,
    }));

    //EVENTOS
    const buscarVendedores = useCallback(() => {
        if (podeVisualizarVendedor({}))
            buscar()
                .then((vendedores) => setVendedores(vendedores))
                .catch((err) => console.error(err));
        else if (podeVisualizarVendedor({ id: usuario?.vendedorUser?.id }))
            buscar({ where: { id: usuario?.vendedorUser?.id } })
                .then((vendedores) => setVendedores(vendedores))
                .catch((err) => console.error(err));
    }, [buscar, podeVisualizarVendedor, usuario]);

    useEffect(() => {
        buscarVendedores();
        return () => cancelar();
    }, [buscarVendedores, cancelar]);

    const handleOnClickAdicionar = useCallback(() => setMostrarAdicionar(true), []);

    const handleOnClickEditar = useCallback(
        (id: string) => {
            const vendedorEditando = vendedores.find((vendedor) => vendedor.id === id);
            if (vendedorEditando)
                setVendedorEditando({
                    ...vendedorEditando,
                    email: vendedorEditando.user.email,
                    name: vendedorEditando.user.name,
                });
        },
        [vendedores]
    );
    const handleOnClickDeletar = useCallback(
        (id: string) => {
            const vendedorDeletando = vendedores.find((vendedor) => vendedor.id === id);
            if (vendedorDeletando) setVendedorDeletando(vendedorDeletando);
        },
        [vendedores]
    );
    const handleOnClickVendedor = useCallback(
        (id: string) => {
            if (!setIdSelecionado) return;

            //Encontrando o novo vendedor a selecionar
            const vendedorNovo = vendedores.find((vendedor) => vendedor.id === id);
            if (!vendedorNovo) return;

            if (vendedorNovo.id === idSelecionado) return setIdSelecionado(null);
            setIdSelecionado(vendedorNovo.id);
        },
        [setIdSelecionado, vendedores, idSelecionado]
    );

    const returnLabelClienteSelecionado = useCallback((): string => {
        if (!idSelecionado) return "--- Nenhum ---";

        const vendedorSelecionado = vendedores.find(({ id }) => id === idSelecionado);
        if (!vendedorSelecionado) return "--- Nenhum ---";
        return vendedorSelecionado.user.name;
    }, [idSelecionado, vendedores]);

    return (
        <>
            <SelectMenu
                setPesquisa={setPesquisa}
                valores={valoresFormatados}
                texto={returnLabelClienteSelecionado()}
                onOpenMenu={buscaComErro ? buscarVendedores : undefined}
                onClickAdicionar={podeAdicionarVendedor({}) ? handleOnClickAdicionar : undefined}
                onClickItem={handleOnClickVendedor}
                onClickDeletar={podeDeletarVendedor({}) ? handleOnClickDeletar : undefined}
                onClickEditar={podeEditarVendedor({}) ? handleOnClickEditar : undefined}
                closeOnSelect
                carregando={estaBuscando || buscaComErro}
            />

            <OffcanvasVendedor
                tipo="criar"
                mostrarForm={mostrarAdicionar}
                onCancel={() => setMostrarAdicionar(false)}
                onTerminou={buscarVendedores}
            />

            <OffcanvasVendedor
                tipo="editar"
                mostrarForm={vendedorEditando !== null}
                onCancel={() => setVendedorEditando(null)}
                onTerminou={() => {
                    setVendedorEditando(null);
                    buscarVendedores();
                }}
                dados={vendedorEditando}
            />

            <OffcanvasVendedor
                tipo="deletar"
                mostrarForm={vendedorDeletando !== null}
                onCancel={() => setVendedorDeletando(null)}
                onTerminou={() => {
                    setVendedorDeletando(null);
                    buscarVendedores();
                }}
                dados={vendedorDeletando}
            />
        </>
    );
}
