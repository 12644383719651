import { tCliente } from "../../interfaces";
import { Tabela } from "./Tabela";
import utils from "../../utils";
import { useCallback, useContext, useState } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import ModalAuditoriaCliente from "../modals/ModalAuditoriaCliente";
import "./styles.css";

interface ITabelaClienteProps {
    clientes: tCliente[];
    onEdit: (cliente: tCliente) => void;
    onDelete: (cliente: tCliente) => void;
}

export default function TabelaCliente(props: ITabelaClienteProps) {
    //CONTEXTOS
    const { podeEditarCliente, podeDeletarCliente, podeVisualizarAuditoria } = useContext(ContextUser);

    //ESTADOS
    const [clienteAuditando, setClienteAuditando] = useState<tCliente | null>(null);

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((cliente: tCliente) => setClienteAuditando(cliente), []);
    const handleOnHideAuditoria = useCallback(() => setClienteAuditando(null), []);

    return (
        <>
            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        <th>Nome do cliente</th>
                        <th>CNPJ</th>
                        <th>E-mail</th>
                        <th>Telefone</th>
                        <th>Segmento</th>
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.clientes}
                    campoUnico="id"
                    campoReferencia="name"
                    onEdit={podeEditarCliente({}) ? props.onEdit : undefined}
                    onDelete={podeDeletarCliente({}) ? props.onDelete : undefined}
                    onClickAuditoria={
                        podeVisualizarAuditoria({ tabela: "CLIENTE" }) ? handleOnClickOpenAuditoria : undefined
                    }
                >
                    {(cliente: tCliente) => (
                        <>
                            <td>{cliente.name}</td>
                            <td>{utils.retornaCNPJFormatado(cliente.cnpj)}</td>
                            <td>{cliente.email}</td>
                            <td width={"10%"}>{utils.retornaTelefoneFormatado(cliente.telefone)}</td>
                            <td>{cliente.segmento.name}</td>
                        </>
                    )}
                </Tabela.Body>
            </Tabela.Table>
            <ModalAuditoriaCliente
                show={clienteAuditando !== null}
                cliente={clienteAuditando}
                onHide={handleOnHideAuditoria}
            />
        </>
    );
}
