import { useCallback, useContext, useEffect, useState } from "react";
import { tDistribuicaoComissao } from "../../interfaces";
import SelectMenu from "./SelectMenu";
import { ContextUser } from "../../contexts/ContextUser";
import useDistribuicaoComissao from "../../hooks/useDistribuicaoComissao";
import OffcanvasDistribuicaoComissao from "../offcanvas/OffcanvasDistribuicaoComissao";

export interface ISelectVendedoresPorDistComissaoProps {
    idsVendedores?: string[];
    setIdsVendedores?: (ids: string[] | null) => void;
}

export default function SelectVendedoresPorDistComissao(props: ISelectVendedoresPorDistComissaoProps) {
    //HOOKS
    const { buscar, cancelar, estaBuscando, buscaComErro } = useDistribuicaoComissao();

    //CONTEXTOS
    const {
        usuario,
        podeVisualizarDistribuicaoComissao,
        podeAdicionarDistribuicaoComissao,
        podeDeletarDistribuicaoComissao,
        podeEditarDistribuicaoComissao,
    } = useContext(ContextUser);

    //ESTADOS
    const [distribuicoes, setDistribuicoes] = useState<tDistribuicaoComissao[]>([]);
    const [pesquisa, setPesquisa] = useState("");
    const [mostrarAdicionar, setMostrarAdicionar] = useState(false);

    const [distribuicaoEditando, setDistribuicaoEditando] = useState<tDistribuicaoComissao | null>(null);
    const [distribuicaoDeletando, setDistribuicaoDeletando] = useState<tDistribuicaoComissao | null>(null);

    //VARIAVEIS
    const { setIdsVendedores, idsVendedores } = props;

    const idDistSelecionada = distribuicoes.find((dist) => {
        const contemVendedores = dist.vendedoresComissao.every((vc) => idsVendedores?.includes(vc.vendedorId));
        const contemExactNumVendedores = dist.vendedoresComissao.length === idsVendedores?.length;
        return contemVendedores && contemExactNumVendedores;
    })?.id;

    const distribuicoesFiltradas = distribuicoes.filter((distribuicao) =>
        distribuicao.vendedoresComissao.some(({ vendedor }) =>
            vendedor.user.name.toLowerCase().includes(pesquisa.toLowerCase())
        )
    );

    const valoresFormatados = distribuicoesFiltradas.map((distribuicao) => ({
        ...distribuicao,
        texto: distribuicao.vendedoresComissao.map(({ vendedor }) => vendedor.user.name).join(", "),
        selecionado: idDistSelecionada === distribuicao.id,
    }));

    //EVENTOS
    const buscarDistribuicoes = useCallback(() => {
        if (podeVisualizarDistribuicaoComissao({}))
            buscar()
                .then((distribuicoes) => setDistribuicoes(distribuicoes))
                .catch((err) => console.error(err));
        else
            buscar({ where: { vendedorId: usuario?.vendedorUser?.id } })
                .then((distribuicoes) => setDistribuicoes(distribuicoes))
                .catch((err) => console.error(err));
    }, [buscar, usuario, podeVisualizarDistribuicaoComissao]);

    useEffect(() => {
        buscarDistribuicoes();
        return () => cancelar();
    }, [cancelar, buscarDistribuicoes]);

    const handleOnClickAdicionar = useCallback(() => setMostrarAdicionar(true), []);
    const handleOnClickEditar = useCallback(
        (id: string) => {
            const distribuicaoEditando = distribuicoes.find((distribuicao) => distribuicao.id === id);
            if (distribuicaoEditando) setDistribuicaoEditando(distribuicaoEditando);
        },
        [distribuicoes]
    );
    const handleOnClickDeletar = useCallback(
        (id: string) => {
            const distribuicaoDeletando = distribuicoes.find((distribuicao) => distribuicao.id === id);
            if (distribuicaoDeletando) setDistribuicaoDeletando(distribuicaoDeletando);
        },
        [distribuicoes]
    );
    const handleOnClickDistribuicao = useCallback(
        (id: string) => {
            if (!setIdsVendedores) return;

            //Encontrando a nova distribuicao a selecionar
            const distribuicaoNova = distribuicoes.find((distribuicao) => distribuicao.id === id);
            if (!distribuicaoNova) return;

            if (distribuicaoNova.id === idDistSelecionada) return setIdsVendedores([]);
            setIdsVendedores(distribuicaoNova.vendedoresComissao.map((vc) => vc.vendedorId));
        },
        [distribuicoes, idDistSelecionada, setIdsVendedores]
    );

    const returnLabelDistribuicaoSelecionada = useCallback((): string => {
        if (!idDistSelecionada) return "--- Nenhum ---";

        const distribuicaoSelecionada = distribuicoes.find(({ id }) => id === idDistSelecionada);
        if (!distribuicaoSelecionada) return "--- Nenhum ---";
        return distribuicaoSelecionada.vendedoresComissao.map(({ vendedor }) => vendedor.user.name).join(", ");
    }, [idDistSelecionada, distribuicoes]);

    return (
        <>
            <SelectMenu
                setPesquisa={setPesquisa}
                valores={valoresFormatados}
                texto={returnLabelDistribuicaoSelecionada()}
                onOpenMenu={buscaComErro ? buscarDistribuicoes : undefined}
                onClickAdicionar={podeAdicionarDistribuicaoComissao({}) ? handleOnClickAdicionar : undefined}
                onClickItem={handleOnClickDistribuicao}
                onClickDeletar={podeDeletarDistribuicaoComissao({}) ? handleOnClickDeletar : undefined}
                onClickEditar={podeEditarDistribuicaoComissao({}) ? handleOnClickEditar : undefined}
                closeOnSelect
                carregando={estaBuscando}
            />

            <OffcanvasDistribuicaoComissao
                tipo="criar"
                mostrarForm={mostrarAdicionar}
                onCancel={() => setMostrarAdicionar(false)}
                onTerminou={buscarDistribuicoes}
            />

            <OffcanvasDistribuicaoComissao
                tipo="editar"
                mostrarForm={distribuicaoEditando !== null}
                dados={distribuicaoEditando}
                onCancel={() => setDistribuicaoEditando(null)}
                onTerminou={() => {
                    buscarDistribuicoes();
                    setDistribuicaoEditando(null);
                }}
            />

            <OffcanvasDistribuicaoComissao
                tipo="deletar"
                mostrarForm={distribuicaoDeletando !== null}
                dados={distribuicaoDeletando}
                onCancel={() => setDistribuicaoDeletando(null)}
                onTerminou={() => {
                    buscarDistribuicoes();
                    setDistribuicaoDeletando(null);
                }}
            />
        </>
    );
}
