import { useCallback, useContext, useRef, useState } from "react";
import useComissao, { LIMITE_POR_BUSCA_COMISSAO } from "../../hooks/useComissao";
import { tComissao, tEstadoVenda, tVendedor } from "../../interfaces";
import { Col, Row, Spinner, Stack } from "react-bootstrap";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import BotaoAssincrono from "../botoes/BotaoAssincrono";
import Dropdown from "./dropdown";
import { ContextUser } from "../../contexts/ContextUser";
import useFiltros from "../../hooks/useFiltros";

//TYPES
export type tVendedorComissaoTotal = {
    vendedor: tVendedor;
    comissaoTotal: number;
    comissoes: tComissao[];
    paga: boolean;
};

export interface DropdownComissoesTotaisProps {
    onEditarVarias?: (comissoesIds: string[], comissaoPaga: boolean) => Promise<void>;
    onEditou?: () => any;
}

export default function DropdownComissoesTotais({ onEditarVarias, onEditou }: DropdownComissoesTotaisProps) {
    //CONTEXTOS
    const { setPerigo, setConfirmar, setAviso, setProcessando } = useContext(ContextAlerta);
    const { podeEditarComissao } = useContext(ContextUser);

    //HOOKS
    const { buscaComErro, buscar, estaBuscando } = useComissao();
    const { getFilter } = useFiltros();

    //ESTADOS
    const [vendedoresComissaoTotal, setVendedoresComissaoTotal] = useState<tVendedorComissaoTotal[]>([]);
    const [fecharMenuAoClicarFora, setFecharMenuAoClicarFora] = useState(true);

    //REFS
    const divRef = useRef<HTMLDivElement>(null);

    //VARIABLES
    const ano = getFilter("ano") ?? undefined;
    const mesInicial = getFilter("mesInicial") ?? undefined;
    const mesFinal = getFilter("mesFinal") ?? undefined;
    const vendedorId = getFilter("vendedor") ?? undefined;
    const estadoVenda = (getFilter("estadoVenda") as tEstadoVenda) ?? undefined;

    const modificaArrVendedorComissao = useCallback(
        (arrVendedorComissao: tVendedorComissaoTotal[], comissoes: tComissao[]) => {
            for (const comissao of comissoes) {
                const valorComissao = (comissao.comissao * comissao.valorMargemBrutaBase) / 100;
                const indexVendedorComissao = arrVendedorComissao.findIndex(
                    (vc) => vc.vendedor.id === comissao.vendedorId
                );

                if (indexVendedorComissao !== -1) {
                    arrVendedorComissao[indexVendedorComissao].comissaoTotal += valorComissao;
                    arrVendedorComissao[indexVendedorComissao].comissoes.push(comissao);
                } else
                    arrVendedorComissao.push({
                        vendedor: comissao.vendedor,
                        comissaoTotal: valorComissao,
                        comissoes: [comissao],
                        paga: false,
                    });
            }
        },
        []
    );

    const retornaDatasFiltradasFormatadas = useCallback(() => {
        const datas = utils.datasPorMesesAno(mesInicial, mesFinal, ano);

        const inicio = datas?.dataInicial?.toLocaleDateString("pt-Br", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        const fim = datas?.dataFinal?.toLocaleDateString("pt-Br", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });

        return `${inicio} - ${fim}`;
    }, [mesInicial, mesFinal, ano]);

    //EVENTOS
    const buscarComissoes = useCallback(async () => {
        try {
            const datas = utils.datasPorMesesAno(mesInicial, mesFinal, ano);

            const vendedoresComissao = await new Promise<tVendedorComissaoTotal[]>(async (resolve, reject) => {
                try {
                    //Auxiliares na busca
                    let pagina = 0;
                    const vendedoresComissao: tVendedorComissaoTotal[] = [];

                    while (true) {
                        const novasComissoes = await buscar({
                            page: pagina,
                            limit: LIMITE_POR_BUSCA_COMISSAO,
                            where: {
                                dataInicio: datas?.dataInicial,
                                dataFim: datas?.dataFinal,
                                vendedorId,
                                comissaoPaga: false,
                                estadoVenda,
                            },
                        });

                        //Linhas importantes para sair do loop
                        pagina++;
                        if (novasComissoes.length === 0) break;

                        modificaArrVendedorComissao(vendedoresComissao, novasComissoes);
                    }

                    resolve(vendedoresComissao);
                } catch (err) {
                    reject(err);
                }
            });

            setVendedoresComissaoTotal(vendedoresComissao);
        } catch (err) {
            if (utils.blErroCancelamento(err)) return;
            setPerigo(utils.retornaMensagemErro(err));
            console.error(err);
        }
    }, [estadoVenda, vendedorId, mesInicial, mesFinal, ano, setPerigo, buscar, modificaArrVendedorComissao]);

    const handleOnPagar = useCallback(
        async (vendedoresComissao: tVendedorComissaoTotal[]) => {
            //Evita que o menu seja fechado quando o usário confirmar a ação
            setFecharMenuAoClicarFora(false);

            const confirmou = await setConfirmar(
                "Após marcar como pagas estas comissões, não se poderá reverter este estado."
            );

            setFecharMenuAoClicarFora(true);
            if (!confirmou) return setAviso("Nada foi realizado!");

            const comissoesId = vendedoresComissao.flatMap((vc) => vc.comissoes.map((c) => c.id));
            try {
                setProcessando(true);
                if (onEditarVarias) await onEditarVarias(comissoesId, true);
                if (onEditou) onEditou();

                //Atualizando os vendedoresComissoes que foram pagos
                setVendedoresComissaoTotal((current) =>
                    current.map((vc) =>
                        vendedoresComissao.some(({ vendedor }) => vendedor.id === vc.vendedor.id)
                            ? { ...vc, paga: true }
                            : vc
                    )
                );
            } catch (err) {
                console.error(err);
            } finally {
                setProcessando(false);
            }
        },
        [onEditarVarias, onEditou, setConfirmar, setAviso, setProcessando]
    );

    return (
        <>
            <Dropdown.Root onOpenMenu={() => buscarComissoes()}>
                <Dropdown.Item ref={divRef} className="text-primary">
                    <span>
                        Comissões totais <strong> a pagar </strong>
                    </span>
                </Dropdown.Item>
                <Dropdown.Menu rootClose={fecharMenuAoClicarFora} target={divRef.current} placement="bottom-start">
                    <Row>
                        {buscaComErro && !estaBuscando && (
                            <Col sm="12" className="text-secondary-dark text-center">
                                <span>
                                    Houve um erro ao buscar os dados.{" "}
                                    <span onClick={buscarComissoes} role="button" className="text-primary">
                                        Tentar novamente.
                                    </span>
                                </span>
                            </Col>
                        )}
                        {estaBuscando && (
                            <Col sm="12">
                                <Stack className="d-flex w-100 align-items-center text-primary">
                                    <Spinner size="sm" />
                                    <span> Aguarde... </span>
                                </Stack>
                            </Col>
                        )}
                        {vendedoresComissaoTotal.length < 1 && !estaBuscando && !buscaComErro && (
                            <Col sm="12" className="text-center text-secondary-dark">
                                Nenhuma comissão a pagar
                            </Col>
                        )}
                        {vendedoresComissaoTotal.length > 0 && !estaBuscando && !buscaComErro && (
                            <>
                                <Col sm="12" className="lh-1">
                                    <Stack className="bg-info bg-opacity-25 rounded p-2 border border-info text-info">
                                        <small className="mb-2">Comissões filtradas por:</small>
                                        <small className="fw-bold mb-1">
                                            Data: {retornaDatasFiltradasFormatadas()}
                                        </small>
                                        <small className="fw-bold">
                                            Vendas:{" "}
                                            {estadoVenda
                                                ? utils.retornaTextoEstadoVenda(estadoVenda)
                                                : "Todos os estados."}
                                        </small>
                                    </Stack>
                                </Col>

                                {vendedoresComissaoTotal.map((vc) => (
                                    <Col sm="12" key={vc.vendedor.id}>
                                        <Stack className="bg-secondary bg-opacity-25 mt-2 p-2 rounded">
                                            <h6 className="text-truncate">{vc.vendedor.user.name}</h6>
                                            <div className="d-flex w-100 justify-content-between align-items-center">
                                                <span>{utils.retornaValorMonetario(vc.comissaoTotal, "BRL")}</span>
                                                {onEditarVarias && podeEditarComissao({ comissaoPaga: true }) && (
                                                    <BotaoAssincrono
                                                        className="rounded-pill"
                                                        variante="outline-primary"
                                                        size="sm"
                                                        disabled={vc.paga}
                                                        onClick={() => handleOnPagar([vc])}
                                                    >
                                                        {vc.paga ? "Pago" : "Pagar"}
                                                    </BotaoAssincrono>
                                                )}
                                            </div>
                                        </Stack>
                                    </Col>
                                ))}

                                {podeEditarComissao({ comissaoPaga: true }) && (
                                    <Col sm="12" className="text-end mt-2">
                                        <BotaoAssincrono
                                            className="rounded-pill"
                                            size="sm"
                                            onClick={() => handleOnPagar(vendedoresComissaoTotal)}
                                        >
                                            Pagar todas
                                        </BotaoAssincrono>
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                </Dropdown.Menu>
            </Dropdown.Root>
        </>
    );
}
