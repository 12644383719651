import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import {
    PartialEntity,
    tActions,
    tCliente,
    tFabricante,
    tFornecedor,
    tInfoUser,
    tMeta,
    tProduto,
    tServico,
    tVenda,
    tEntityOptional,
    tSubjects,
    tVendedor,
    tCategoria,
    tSegmento,
    tTipoProdutoServico,
    tUser,
    tComissao,
    tAuditoria,
    tFormaPagamento,
    tDistribuicaoComissao,
    tVisita,
    tContrato,
} from "../interfaces";
import api from "../services/api";
import utils from "../utils";
import { useNavigate } from "react-router-dom";
import { routes } from "../router";
import { createMongoAbility, subject } from "@casl/ability";
import { tNovoFornecedor } from "../components/forms/FormFornecedor";
import { tNovoCliente } from "../components/forms/FormCliente";
import { tNovoVendedor } from "../components/forms/FormVendedor";
import { tNovoFabricante } from "../components/forms/FormFabricante";
import { tNovoProduto } from "../components/forms/FormProduto";
import { tNovoServico } from "../components/forms/FormServico";
import { tNovaVenda } from "../components/forms/FormVenda";
import { tNovaMeta } from "../components/forms/FormMeta";
import { tNovaCategoria } from "../components/forms/FormCategoria";
import { tNovoSegmento } from "../components/forms/FormSegmento";
import { tNovoTipoProdutoServico } from "../components/forms/FormTipoProdutoServico";
import { tNovoUser } from "../components/forms/FormAdmin";
import { tNovaFormaPagamento } from "../components/forms/FormFormaPagamento";
import { tNovaDistribuicaoComissao } from "../components/forms/FormDistribuicaoComissao";
import { tNovaVisita } from "../components/forms/FormVisita";
import { tNovoContrato } from "../components/forms/FormContrato";

//TYPES
export interface ContextUserProps {
    children: JSX.Element;
}
export type tPartialInfoUser = PartialEntity<
    tInfoUser,
    "id" | "name" | "email" | "role" | "regrasHabilidades" | "vendedorUser"
>;

//INFORMACOES DO CONTEXTO
export const ContextUser = createContext<{
    usuario: tPartialInfoUser | null;
    carregandoUsuario: boolean;

    podeAdicionarVendedor: (props: tEntityOptional<tNovoVendedor>) => boolean;
    podeEditarVendedor: (props: tEntityOptional<tNovoVendedor>) => boolean;
    podeVisualizarVendedor: (props: tEntityOptional<tVendedor>) => boolean;
    podeDeletarVendedor: (props: tEntityOptional<tVendedor>) => boolean;

    podeAdicionarCliente: (props: tEntityOptional<tNovoCliente>) => boolean;
    podeEditarCliente: (props: tEntityOptional<tNovoCliente>) => boolean;
    podeVisualizarCliente: (props: tEntityOptional<tCliente>) => boolean;
    podeDeletarCliente: (props: tEntityOptional<tCliente>) => boolean;

    podeAdicionarFornecedor: (props: tEntityOptional<tNovoFornecedor>) => boolean;
    podeEditarFornecedor: (props: tEntityOptional<tNovoFornecedor>) => boolean;
    podeVisualizarFornecedor: (props: tEntityOptional<tFornecedor>) => boolean;
    podeDeletarFornecedor: (props: tEntityOptional<tFornecedor>) => boolean;

    podeAdicionarFabricante: (props: tEntityOptional<tNovoFabricante>) => boolean;
    podeEditarFabricante: (props: tEntityOptional<tNovoFabricante>) => boolean;
    podeVisualizarFabricante: (props: tEntityOptional<tFabricante>) => boolean;
    podeDeletarFabricante: (props: tEntityOptional<tFabricante>) => boolean;

    podeAdicionarProduto: (props: tEntityOptional<tNovoProduto>) => boolean;
    podeEditarProduto: (props: tEntityOptional<tNovoProduto>) => boolean;
    podeVisualizarProduto: (props: tEntityOptional<tProduto>) => boolean;
    podeDeletarProduto: (props: tEntityOptional<tProduto>) => boolean;

    podeAdicionarServico: (props: tEntityOptional<tNovoServico>) => boolean;
    podeEditarServico: (props: tEntityOptional<tNovoServico>) => boolean;
    podeVisualizarServico: (props: tEntityOptional<tServico>) => boolean;
    podeDeletarServico: (props: tEntityOptional<tServico>) => boolean;

    podeAdicionarVenda: (props: tEntityOptional<tNovaVenda> & { vendedoresId?: string[] }) => boolean;
    podeEditarVenda: (props: tEntityOptional<tNovaVenda> & { vendedoresId?: string[] }) => boolean;
    podeVisualizarVenda: (props: tEntityOptional<tVenda> & { vendedorId?: string }) => boolean;
    podeDeletarVenda: (props: tEntityOptional<tVenda> & { vendedorId?: string }) => boolean;

    podeAdicionarMeta: (props: tEntityOptional<tNovaMeta>) => boolean;
    podeEditarMeta: (props: tEntityOptional<tNovaMeta>) => boolean;
    podeVisualizarMeta: (props: tEntityOptional<tMeta>) => boolean;
    podeDeletarMeta: (props: tEntityOptional<tMeta>) => boolean;

    podeAdicionarCategoria: (props: tEntityOptional<tNovaCategoria>) => boolean;
    podeEditarCategoria: (props: tEntityOptional<tNovaCategoria>) => boolean;
    podeVisualizarCategoria: (props: tEntityOptional<tCategoria>) => boolean;
    podeDeletarCategoria: (props: tEntityOptional<tCategoria>) => boolean;

    podeAdicionarContrato: (props: tEntityOptional<tNovoContrato>) => boolean;
    podeEditarContrato: (props: tEntityOptional<tNovoContrato>) => boolean;
    podeVisualizarContrato: (props: tEntityOptional<tContrato>) => boolean;
    podeDeletarContrato: (props: tEntityOptional<tContrato>) => boolean;

    podeAdicionarSegmento: (props: tEntityOptional<tNovoSegmento>) => boolean;
    podeEditarSegmento: (props: tEntityOptional<tNovoSegmento>) => boolean;
    podeVisualizarSegmento: (props: tEntityOptional<tSegmento>) => boolean;
    podeDeletarSegmento: (props: tEntityOptional<tSegmento>) => boolean;

    podeAdicionarTipoProdutoServico: (props: tEntityOptional<tNovoTipoProdutoServico>) => boolean;
    podeEditarTipoProdutoServico: (props: tEntityOptional<tNovoTipoProdutoServico>) => boolean;
    podeVisualizarTipoProdutoServico: (props: tEntityOptional<tTipoProdutoServico>) => boolean;
    podeDeletarTipoProdutoServico: (props: tEntityOptional<tTipoProdutoServico>) => boolean;

    podeAdicionarUser: (props: tEntityOptional<tNovoUser>) => boolean;
    podeEditarUser: (props: tEntityOptional<tNovoUser>) => boolean;
    podeVisualizarUser: (props: tEntityOptional<tUser>) => boolean;
    podeDeletarUser: (props: tEntityOptional<tUser>) => boolean;

    podeVisualizarComissao: (props: tEntityOptional<tComissao> & { dataInicio?: Date; dataFim?: Date }) => boolean;
    podeEditarComissao: (props: tEntityOptional<PartialEntity<tComissao, "comissaoPaga" | "id">>) => boolean;

    podeVisualizarAuditoria: (props: tEntityOptional<tAuditoria>) => boolean;

    podeAdicionarFormaPagamento: (props: tEntityOptional<tNovaFormaPagamento>) => boolean;
    podeEditarFormaPagamento: (props: tEntityOptional<tNovaFormaPagamento>) => boolean;
    podeVisualizarFormaPagamento: (props: tEntityOptional<tFormaPagamento>) => boolean;
    podeDeletarFormaPagamento: (props: tEntityOptional<tFormaPagamento>) => boolean;

    podeAdicionarDistribuicaoComissao: (props: tEntityOptional<tNovaDistribuicaoComissao>) => boolean;
    podeEditarDistribuicaoComissao: (props: tEntityOptional<tNovaDistribuicaoComissao>) => boolean;
    podeVisualizarDistribuicaoComissao: (props: { vendedorId?: string }) => boolean;
    podeDeletarDistribuicaoComissao: (props: tEntityOptional<tDistribuicaoComissao>) => boolean;

    podeAdicionarVisita: (props: tEntityOptional<tNovaVisita>) => boolean;
    podeEditarVisita: (props: tEntityOptional<tNovaVisita>) => boolean;
    podeVisualizarVisita: (props: { vendedorId?: string }) => boolean;
    podeDeletarVisita: (props: tEntityOptional<tVisita>) => boolean;
}>({
    usuario: {
        name: "",
        id: "",
        email: "",
        role: "VENDEDOR",
        regrasHabilidades: [],
    },
    carregandoUsuario: false,

    podeAdicionarVendedor: () => false,
    podeEditarVendedor: () => false,
    podeVisualizarVendedor: () => false,
    podeDeletarVendedor: () => false,

    podeAdicionarCliente: () => false,
    podeEditarCliente: () => false,
    podeVisualizarCliente: () => false,
    podeDeletarCliente: () => false,

    podeAdicionarFornecedor: () => false,
    podeEditarFornecedor: () => false,
    podeVisualizarFornecedor: () => false,
    podeDeletarFornecedor: () => false,

    podeAdicionarFabricante: () => false,
    podeEditarFabricante: () => false,
    podeVisualizarFabricante: () => false,
    podeDeletarFabricante: () => false,

    podeAdicionarProduto: () => false,
    podeEditarProduto: () => false,
    podeVisualizarProduto: () => false,
    podeDeletarProduto: () => false,

    podeAdicionarServico: () => false,
    podeEditarServico: () => false,
    podeVisualizarServico: () => false,
    podeDeletarServico: () => false,

    podeAdicionarVenda: () => false,
    podeEditarVenda: () => false,
    podeVisualizarVenda: () => false,
    podeDeletarVenda: () => false,

    podeAdicionarMeta: () => false,
    podeEditarMeta: () => false,
    podeVisualizarMeta: () => false,
    podeDeletarMeta: () => false,

    podeAdicionarCategoria: () => false,
    podeEditarCategoria: () => false,
    podeVisualizarCategoria: () => false,
    podeDeletarCategoria: () => false,

    podeAdicionarContrato: () => false,
    podeEditarContrato: () => false,
    podeVisualizarContrato: () => false,
    podeDeletarContrato: () => false,

    podeAdicionarSegmento: () => false,
    podeEditarSegmento: () => false,
    podeVisualizarSegmento: () => false,
    podeDeletarSegmento: () => false,

    podeAdicionarTipoProdutoServico: () => false,
    podeEditarTipoProdutoServico: () => false,
    podeVisualizarTipoProdutoServico: () => false,
    podeDeletarTipoProdutoServico: () => false,

    podeAdicionarUser: () => false,
    podeEditarUser: () => false,
    podeVisualizarUser: () => false,
    podeDeletarUser: () => false,

    podeVisualizarComissao: () => false,
    podeEditarComissao: () => false,

    podeVisualizarAuditoria: () => false,

    podeAdicionarFormaPagamento: () => false,
    podeEditarFormaPagamento: () => false,
    podeVisualizarFormaPagamento: () => false,
    podeDeletarFormaPagamento: () => false,

    podeAdicionarDistribuicaoComissao: () => false,
    podeEditarDistribuicaoComissao: () => false,
    podeVisualizarDistribuicaoComissao: () => false,
    podeDeletarDistribuicaoComissao: () => false,

    podeAdicionarVisita: () => false,
    podeEditarVisita: () => false,
    podeVisualizarVisita: () => false,
    podeDeletarVisita: () => false,
});

export default function ProviderUser(props: ContextUserProps) {
    //CONTEXTOS
    const navigate = useNavigate();

    //ESTADOS
    const [usuario, setUsuario] = useState<tPartialInfoUser | null>(null);
    const [carregandoUsuario, setCarregandoUsuario] = useState(false);

    //VARIVEIS
    const regrasDeHabilidades = usuario?.regrasHabilidades;
    const abilidades = useMemo(() => createMongoAbility<[tActions, any]>(regrasDeHabilidades), [regrasDeHabilidades]);

    const podeAdicionarVendedor = useCallback(
        (props: tEntityOptional<tNovoVendedor>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Vendedor", props));
        },
        [abilidades]
    );
    const podeEditarVendedor = useCallback(
        (props: tEntityOptional<tNovoVendedor>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Vendedor", props));
        },
        [abilidades]
    );
    const podeVisualizarVendedor = useCallback(
        (props: tEntityOptional<tVendedor>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Vendedor", props));
        },
        [abilidades]
    );
    const podeDeletarVendedor = useCallback(
        (props: tEntityOptional<tVendedor>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Vendedor", props));
        },
        [abilidades]
    );

    const podeAdicionarCliente = useCallback(
        (props: tEntityOptional<tNovoCliente>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Cliente", props));
        },
        [abilidades]
    );
    const podeEditarCliente = useCallback(
        (props: tEntityOptional<tNovoCliente>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Cliente", props));
        },
        [abilidades]
    );
    const podeVisualizarCliente = useCallback(
        (props: tEntityOptional<tCliente>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Fornecedor", props));
        },
        [abilidades]
    );
    const podeDeletarCliente = useCallback(
        (props: tEntityOptional<tCliente>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Cliente", props));
        },
        [abilidades]
    );

    const podeAdicionarFornecedor = useCallback(
        (props: tEntityOptional<tNovoFornecedor>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Fornecedor", props));
        },
        [abilidades]
    );
    const podeEditarFornecedor = useCallback(
        (props: tEntityOptional<tNovoFornecedor>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Fornecedor", props));
        },
        [abilidades]
    );
    const podeVisualizarFornecedor = useCallback(
        (props: tEntityOptional<tFornecedor>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Fornecedor", props));
        },
        [abilidades]
    );
    const podeDeletarFornecedor = useCallback(
        (props: tEntityOptional<tFornecedor>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Fornecedor", props));
        },
        [abilidades]
    );

    const podeAdicionarFabricante = useCallback(
        (props: tEntityOptional<tNovoFabricante>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Fabricante", props));
        },
        [abilidades]
    );
    const podeEditarFabricante = useCallback(
        (props: tEntityOptional<tNovoFabricante>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Fabricante", props));
        },
        [abilidades]
    );
    const podeVisualizarFabricante = useCallback(
        (props: tEntityOptional<tFabricante>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Fabricante", props));
        },
        [abilidades]
    );
    const podeDeletarFabricante = useCallback(
        (props: tEntityOptional<tFabricante>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Fabricante", props));
        },
        [abilidades]
    );

    const podeAdicionarProduto = useCallback(
        (props: tEntityOptional<tNovoProduto>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Produto", props));
        },
        [abilidades]
    );
    const podeEditarProduto = useCallback(
        (props: tEntityOptional<tNovoProduto>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Produto", props));
        },
        [abilidades]
    );
    const podeVisualizarProduto = useCallback(
        (props: tEntityOptional<tProduto>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Produto", props));
        },
        [abilidades]
    );
    const podeDeletarProduto = useCallback(
        (props: tEntityOptional<tProduto>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Produto", props));
        },
        [abilidades]
    );

    const podeAdicionarServico = useCallback(
        (props: tEntityOptional<tNovoServico>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Servico", props));
        },
        [abilidades]
    );
    const podeEditarServico = useCallback(
        (props: tEntityOptional<tNovoServico>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Servico", props));
        },
        [abilidades]
    );
    const podeVisualizarServico = useCallback(
        (props: tEntityOptional<tServico>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Servico", props));
        },
        [abilidades]
    );
    const podeDeletarServico = useCallback(
        (props: tEntityOptional<tServico>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Servico", props));
        },
        [abilidades]
    );

    const podeAdicionarVenda = useCallback(
        (props: tEntityOptional<tNovaVenda>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Venda", props));
        },
        [abilidades]
    );
    const podeEditarVenda = useCallback(
        (props: tEntityOptional<tNovaVenda>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Venda", props));
        },
        [abilidades]
    );
    const podeVisualizarVenda = useCallback(
        (props: tEntityOptional<tVenda> & { vendedorId?: string }): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Venda", props));
        },
        [abilidades]
    );
    const podeDeletarVenda = useCallback(
        (props: tEntityOptional<tVenda>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Venda", props));
        },
        [abilidades]
    );

    const podeAdicionarMeta = useCallback(
        (props: tEntityOptional<tNovaMeta>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Meta", props));
        },
        [abilidades]
    );
    const podeEditarMeta = useCallback(
        (props: tEntityOptional<tNovaMeta>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Meta", props));
        },
        [abilidades]
    );
    const podeVisualizarMeta = useCallback(
        (props: tEntityOptional<tMeta>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Meta", props));
        },
        [abilidades]
    );
    const podeDeletarMeta = useCallback(
        (props: tEntityOptional<tMeta>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Meta", props));
        },
        [abilidades]
    );

    const podeAdicionarCategoria = useCallback(
        (props: tEntityOptional<tNovaCategoria>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Categoria", props));
        },
        [abilidades]
    );
    const podeEditarCategoria = useCallback(
        (props: tEntityOptional<tNovaCategoria>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Categoria", props));
        },
        [abilidades]
    );
    const podeVisualizarCategoria = useCallback(
        (props: tEntityOptional<tCategoria>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Categoria", props));
        },
        [abilidades]
    );
    const podeDeletarCategoria = useCallback(
        (props: tEntityOptional<tCategoria>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Categoria", props));
        },
        [abilidades]
    );

    const podeAdicionarContrato = useCallback(
        (props: tEntityOptional<tNovoContrato>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Contrato", props));
        },
        [abilidades]
    );
    const podeEditarContrato = useCallback(
        (props: tEntityOptional<tNovoContrato>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Contrato", props));
        },
        [abilidades]
    );
    const podeVisualizarContrato = useCallback(
        (props: tEntityOptional<tContrato>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Contrato", props));
        },
        [abilidades]
    );
    const podeDeletarContrato = useCallback(
        (props: tEntityOptional<tContrato>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Contrato", props));
        },
        [abilidades]
    );

    const podeAdicionarTipoProdutoServico = useCallback(
        (props: tEntityOptional<tNovoTipoProdutoServico>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("TipoProdutoServico", props));
        },
        [abilidades]
    );
    const podeEditarTipoProdutoServico = useCallback(
        (props: tEntityOptional<tNovoTipoProdutoServico>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("TipoProdutoServico", props));
        },
        [abilidades]
    );
    const podeVisualizarTipoProdutoServico = useCallback(
        (props: tEntityOptional<tTipoProdutoServico>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("TipoProdutoServico", props));
        },
        [abilidades]
    );
    const podeDeletarTipoProdutoServico = useCallback(
        (props: tEntityOptional<tTipoProdutoServico>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("TipoProdutoServico", props));
        },
        [abilidades]
    );

    const podeAdicionarSegmento = useCallback(
        (props: tEntityOptional<tNovoSegmento>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Segmento", props));
        },
        [abilidades]
    );
    const podeEditarSegmento = useCallback(
        (props: tEntityOptional<tNovoSegmento>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Segmento", props));
        },
        [abilidades]
    );
    const podeVisualizarSegmento = useCallback(
        (props: tEntityOptional<tSegmento>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Segmento", props));
        },
        [abilidades]
    );
    const podeDeletarSegmento = useCallback(
        (props: tEntityOptional<tSegmento>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Segmento", props));
        },
        [abilidades]
    );

    const podeAdicionarUser = useCallback(
        (props: tEntityOptional<tNovoUser>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("User", props));
        },
        [abilidades]
    );
    const podeEditarUser = useCallback(
        (props: tEntityOptional<tNovoUser>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("User", props));
        },
        [abilidades]
    );
    const podeVisualizarUser = useCallback(
        (props: tEntityOptional<tUser>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("User", props));
        },
        [abilidades]
    );
    const podeDeletarUser = useCallback(
        (props: tEntityOptional<tUser>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("User", props));
        },
        [abilidades]
    );

    const podeVisualizarComissao = useCallback(
        (props: tEntityOptional<tComissao> & { dataInicio?: Date; dataFim?: Date }) => {
            return abilidades.can("read", subject<tSubjects, any>("Comissao", props));
        },
        [abilidades]
    );
    const podeEditarComissao = useCallback(
        (props: tEntityOptional<PartialEntity<tComissao, "id" | "dataComissao">>) => {
            return abilidades.can("update", subject<tSubjects, any>("Comissao", props));
        },
        [abilidades]
    );

    const podeVisualizarAuditoria = useCallback(
        (props: tEntityOptional<tAuditoria>) => {
            return abilidades.can("read", subject<tSubjects, any>("Auditoria", props));
        },
        [abilidades]
    );

    const podeAdicionarFormaPagamento = useCallback(
        (props: tEntityOptional<tNovaFormaPagamento>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("FormaPagamento", props));
        },
        [abilidades]
    );
    const podeEditarFormaPagamento = useCallback(
        (props: tEntityOptional<tNovaFormaPagamento>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("FormaPagamento", props));
        },
        [abilidades]
    );
    const podeVisualizarFormaPagamento = useCallback(
        (props: tEntityOptional<tFormaPagamento>): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("FormaPagamento", props));
        },
        [abilidades]
    );
    const podeDeletarFormaPagamento = useCallback(
        (props: tEntityOptional<tFormaPagamento>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("FormaPagamento", props));
        },
        [abilidades]
    );

    const podeAdicionarDistribuicaoComissao = useCallback(
        (props: tEntityOptional<tNovaDistribuicaoComissao>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("DistribuicaoComissao", props));
        },
        [abilidades]
    );
    const podeEditarDistribuicaoComissao = useCallback(
        (props: tEntityOptional<tNovaDistribuicaoComissao>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("DistribuicaoComissao", props));
        },
        [abilidades]
    );
    const podeVisualizarDistribuicaoComissao = useCallback(
        (props: { vendedorId?: string }): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("DistribuicaoComissao", props));
        },
        [abilidades]
    );
    const podeDeletarDistribuicaoComissao = useCallback(
        (props: tEntityOptional<tDistribuicaoComissao>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("DistribuicaoComissao", props));
        },
        [abilidades]
    );

    const podeAdicionarVisita = useCallback(
        (props: tEntityOptional<tNovaVisita>): boolean => {
            return abilidades.can("create", subject<tSubjects, any>("Visita", props));
        },
        [abilidades]
    );
    const podeEditarVisita = useCallback(
        (props: tEntityOptional<tNovaVisita>): boolean => {
            return abilidades.can("update", subject<tSubjects, any>("Visita", props));
        },
        [abilidades]
    );
    const podeVisualizarVisita = useCallback(
        (props: { vendedorId?: string }): boolean => {
            return abilidades.can("read", subject<tSubjects, any>("Visita", props));
        },
        [abilidades]
    );
    const podeDeletarVisita = useCallback(
        (props: tEntityOptional<tVisita>): boolean => {
            return abilidades.can("delete", subject<tSubjects, any>("Visita", props));
        },
        [abilidades]
    );

    //EVENTOS
    const carregaUsuario = useCallback(
        async (signal?: AbortSignal) => {
            try {
                setCarregandoUsuario(true);
                const res = await api.getInfoUsuario(signal);
                setUsuario(res.data);
            } catch (err: any) {
                if (utils.blErroCancelamento(err)) return;
                //Deixa o interceptor do axios se responsabilizar por este tipo de erro
                if (err?.response?.status !== 401)
                    navigate(routes.errorInfouser.replace(":error", utils.retornaMensagemErro(err)));
                console.error(err);
            } finally {
                setCarregandoUsuario(false);
            }
        },
        [navigate]
    );

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        carregaUsuario(signal);

        return () => controller.abort();
    }, [carregaUsuario]);

    return (
        <ContextUser.Provider
            value={{
                usuario,
                carregandoUsuario,

                podeAdicionarVendedor,
                podeEditarVendedor,
                podeVisualizarVendedor,
                podeDeletarVendedor,

                podeAdicionarCliente,
                podeEditarCliente,
                podeVisualizarCliente,
                podeDeletarCliente,

                podeAdicionarFornecedor,
                podeEditarFornecedor,
                podeVisualizarFornecedor,
                podeDeletarFornecedor,

                podeAdicionarFabricante,
                podeEditarFabricante,
                podeVisualizarFabricante,
                podeDeletarFabricante,

                podeAdicionarProduto,
                podeEditarProduto,
                podeVisualizarProduto,
                podeDeletarProduto,

                podeAdicionarServico,
                podeEditarServico,
                podeVisualizarServico,
                podeDeletarServico,

                podeAdicionarVenda,
                podeEditarVenda,
                podeVisualizarVenda,
                podeDeletarVenda,

                podeAdicionarMeta,
                podeEditarMeta,
                podeVisualizarMeta,
                podeDeletarMeta,

                podeAdicionarCategoria,
                podeEditarCategoria,
                podeVisualizarCategoria,
                podeDeletarCategoria,

                podeAdicionarContrato,
                podeEditarContrato,
                podeVisualizarContrato,
                podeDeletarContrato,

                podeAdicionarSegmento,
                podeEditarSegmento,
                podeVisualizarSegmento,
                podeDeletarSegmento,

                podeAdicionarTipoProdutoServico,
                podeEditarTipoProdutoServico,
                podeVisualizarTipoProdutoServico,
                podeDeletarTipoProdutoServico,

                podeAdicionarUser,
                podeEditarUser,
                podeVisualizarUser,
                podeDeletarUser,

                podeVisualizarComissao,
                podeEditarComissao,

                podeVisualizarAuditoria,

                podeAdicionarFormaPagamento,
                podeEditarFormaPagamento,
                podeVisualizarFormaPagamento,
                podeDeletarFormaPagamento,

                podeAdicionarDistribuicaoComissao,
                podeEditarDistribuicaoComissao,
                podeVisualizarDistribuicaoComissao,
                podeDeletarDistribuicaoComissao,

                podeAdicionarVisita,
                podeEditarVisita,
                podeVisualizarVisita,
                podeDeletarVisita,
            }}
        >
            {props.children}
        </ContextUser.Provider>
    );
}
