import { useCallback } from "react";
import { PartialEntity, tFornecedor } from "../../interfaces";
import { OffcanvasNovoDado } from "./OffcanvasNovoDado";
import { OffcanvasEditarDado } from "./OffcanvasEditarDado.tsx";
import { OffcanvasDeletarDado } from "./OffcanvasDeletarDado.tsx";
import utils from "../../utils";
import FormFornecedor, { tNovoFornecedor } from "../forms/FormFornecedor";
import useFornecedor from "../../hooks/useFornecedor";

export type IOffcanvasFornecedorProps = {
    mostrarForm: boolean;
    onTerminou?: () => void;
    onCancel?: () => void;
} & (
    | { tipo: "criar"; dados?: null }
    | { tipo: "editar"; dados: null | (tNovoFornecedor & PartialEntity<tFornecedor, "id">) }
    | { tipo: "deletar"; dados: null | tFornecedor }
);

const FORM = "form-fornecedor";

export default function OffcanvasFornecedor(props: IOffcanvasFornecedorProps) {
    //HOOKS
    const { criar, editar, deletar, cancelar, estaCriando, estaEditando, estaDeletando } = useFornecedor();

    //VARAIVEIS
    const { onTerminou, onCancel, dados } = props;

    //EVENTS
    const handleOnCancel = useCallback(() => {
        cancelar();
        if (onCancel) onCancel();
    }, [onCancel, cancelar]);

    const handleOnCriar = useCallback(
        async (fornecedor: tNovoFornecedor) => {
            try {
                await criar([fornecedor]);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [criar, onTerminou]
    );

    const handleOnEditar = useCallback(
        async (fornecedor: tNovoFornecedor & PartialEntity<tFornecedor, "id">) => {
            try {
                await editar(fornecedor);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [editar, onTerminou]
    );

    const handleOnDeletar = useCallback(
        async (id: string) => {
            try {
                await deletar(id);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [deletar, onTerminou]
    );

    return props.tipo === "criar" ? (
        <OffcanvasNovoDado.Root show={props.mostrarForm}>
            <OffcanvasNovoDado.Header>
                <h4>Novo Fornecedor</h4>
            </OffcanvasNovoDado.Header>
            <OffcanvasNovoDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ carregando: estaCriando, type: "submit", form: FORM }}
            >
                <FormFornecedor
                    formId={FORM}
                    valoresIniciais={{ name: "", cnpj: "", email: "" }}
                    onSubmit={handleOnCriar}
                />
            </OffcanvasNovoDado.Body>
        </OffcanvasNovoDado.Root>
    ) : props.tipo === "editar" ? (
        <OffcanvasEditarDado.Root show={props.mostrarForm}>
            <OffcanvasEditarDado.Header>
                <h4>Editar Fornecedor</h4>
            </OffcanvasEditarDado.Header>
            <OffcanvasEditarDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ form: FORM, carregando: estaEditando, type: "submit" }}
            >
                {dados && (
                    <FormFornecedor
                        formId={FORM}
                        valoresIniciais={{
                            name: dados.name,
                            cnpj: dados.cnpj,
                            email: dados.email,
                        }}
                        onSubmit={(fornecedor) => handleOnEditar({ id: dados.id, ...fornecedor })}
                    />
                )}
            </OffcanvasEditarDado.Body>
        </OffcanvasEditarDado.Root>
    ) : props.tipo === "deletar" ? (
        <OffcanvasDeletarDado.Root show={props.mostrarForm}>
            <OffcanvasDeletarDado.Header>
                <h4>Deletar Fornecedor</h4>
            </OffcanvasDeletarDado.Header>
            {dados && (
                <OffcanvasDeletarDado.Body
                    acceptButtonProps={{ onClick: () => handleOnDeletar(dados.id), carregando: estaDeletando }}
                    cancelButtonProps={{ onClick: handleOnCancel }}
                >
                    <p>
                        Você está prestes a deletar permanentemente do sistema o fornecedor de nome{" "}
                        <strong>{dados?.name}</strong> e CNPJ{" "}
                        <strong>{utils.retornaCNPJFormatado(dados?.cnpj ?? "")}</strong>. Clique no botão{" "}
                        <strong className="text-danger">Deletar</strong> logo abaixo para prosseguir.
                    </p>
                </OffcanvasDeletarDado.Body>
            )}
        </OffcanvasDeletarDado.Root>
    ) : undefined;
}
