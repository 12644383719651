import React, { useCallback, useRef, useState } from "react";
import { Col, Container, Overlay, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { tVisita } from "../../interfaces";
import utils from "../../utils";
import "./styles.css";

interface SpanVisitaGastoProps {
    visita: tVisita;
}
export default function SpanVisitaGasto(props: SpanVisitaGastoProps) {
    //ESTADOS
    const [mostrarInfo, setMostrarInfo] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);

    //VARIAVEIS
    const { visita } = props;

    const retornaValorTotalGasto = useCallback(() => {
        return visita.gastos.reduce((acc, gasto) => (acc += gasto.valor), 0);
    }, [visita]);

    //EVENTOS
    const handleOnMostrarInfo = useCallback(() => setMostrarInfo(true), []);
    const handleOnEsconderInfo = useCallback(() => setMostrarInfo(false), []);

    return (
        <>
            <div
                className="d-flex w-100 overflow-hidden text-info justify-content-end"
                role="button"
                onClick={handleOnMostrarInfo}
                ref={divRef}
            >
                <OverlayTrigger placement="top" flip overlay={<Tooltip>Clique para saber mais</Tooltip>}>
                    <span className="text-nowrap">
                        {utils.retornaValorMonetario(retornaValorTotalGasto(), "BRL")}
                        <i className="bi bi-caret-down-fill ms-1" />
                    </span>
                </OverlayTrigger>
            </div>

            <Overlay
                rootClose
                onHide={handleOnEsconderInfo}
                target={divRef.current}
                show={mostrarInfo}
                flip
                placement="bottom"
            >
                <Popover className="shadow">
                    <Popover.Body className="my-container-controle-borda p-2">
                        {visita.gastos.length < 1 && <Col sm="12"> --- Nenhum gasto --- </Col>}
                        {visita.gastos.map((gasto: any) => {
                            return (
                                <React.Fragment key={gasto.id}>
                                    <Container fluid className="p-1">
                                        <Row className="my-child-controle-borda border-bottom border-bottom">
                                            <Col sm="12" className="text-start fs-6 fw-bold">
                                                {utils.retornaTextoTipoGasto(gasto.tipoGasto)}
                                            </Col>
                                            <Col
                                                sm="12"
                                                className="d-flex justify-content-between align-items-end lh-1 mt-1"
                                            >
                                                <span>Nota:</span>
                                                <span>{gasto.nota}</span>
                                            </Col>
                                            <Col
                                                sm="12"
                                                className="d-flex justify-content-between align-items-end lh-1 mt-1"
                                            >
                                                <span>Valor:</span>
                                                <span>{utils.retornaValorMonetario(gasto.valor, "BRL")}</span>
                                            </Col>
                                        </Row>
                                    </Container>
                                </React.Fragment>
                            );
                        })}
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}
