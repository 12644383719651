import { Col, Form, Row, Stack } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import SelectCategoria from "../selects/SelectCategoria";
import SelectFabricante from "../selects/SelectFabricante";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tProduto } from "../../interfaces";
import SelectTipoProdutoServico from "../selects/SelectTipoProdutoServico";
import { v4 } from "uuid";

//TYPES
export type tNovoProduto = PartialEntity<
    tProduto,
    "categoriaId" | "fabricanteId" | "name" | "partNumber" | "tipoProdutoServicoId"
>;

interface IFormProdutoProps {
    formId?: string;
    onSubmit: (produto: tNovoProduto) => void | Promise<void>;
    valoresIniciais: tNovoProduto;
}

//FORM
export default function FormProduto(props: IFormProdutoProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (produto: tNovoProduto, helpers: FormikHelpers<tNovoProduto>) => {
            try {
                await onSubmit(produto);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaProduto}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} sm="12" className="text-end">
                                <Form.Label className="mb-0">
                                    <Stack className="w-100 d-flex align-items-end">
                                        <span className="mb-1">Fabricante</span>
                                        <SelectFabricante
                                            setIdSelecionado={(id) => setValues({ ...values, fabricanteId: id ?? "" })}
                                            idSelecionado={values.fabricanteId}
                                        />
                                    </Stack>
                                </Form.Label>
                                <Form.Control className="d-none" isInvalid={!!errors.fabricanteId} />
                                <Form.Control.Feedback type="invalid">{errors.fabricanteId}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">PartNumber do produto</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.partNumber}
                                    onChange={(e) => setValues({ ...values, partNumber: e.target.value })}
                                    isInvalid={!!errors.partNumber}
                                />
                                <Form.Control.Feedback type="invalid">{errors.partNumber}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Nome do produto</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.name}
                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm="12" className="text-end">
                                <Form.Label className="mb-0">
                                    <Stack className="d-flex align-items-center w-100">
                                        <span className="mb-1">Categoria do produto</span>
                                        <SelectCategoria
                                            setIdSelecionado={(id) => setValues({ ...values, categoriaId: id ?? "" })}
                                            idSelecionado={values.categoriaId}
                                        />
                                    </Stack>
                                </Form.Label>
                                <Form.Control className="d-none" isInvalid={!!errors.categoriaId} />
                                <Form.Control.Feedback type="invalid">{errors.categoriaId}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm="12" className="text-end">
                                <Form.Label className="mb-0">
                                    <Stack className="d-flex align-items-center w-100">
                                        <span className="mb-1">Tipo de Produto/Serviço</span>
                                        <SelectTipoProdutoServico
                                            setIdSelecionado={(id) =>
                                                setValues({ ...values, tipoProdutoServicoId: id ?? "" })
                                            }
                                            idSelecionado={values.tipoProdutoServicoId}
                                        />
                                    </Stack>
                                </Form.Label>
                                <Form.Control className="d-none" isInvalid={!!errors.tipoProdutoServicoId} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.tipoProdutoServicoId}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaProduto: yup.ObjectSchema<tNovoProduto> = yup.object({
    name: yup
        .string()
        .required("Campo necessário.")
        .max(256, "Deve ter no máximo 256 carecteres."),
    partNumber: yup
        .string()
        .defined()
        .required("Campo necessário.")
        .min(1, "Deve ter no mínimo 1 caracteres.")
        .max(50, "Deve ter no máximo 50 carecteres."),
    categoriaId: yup.string().required("Selecione uma categoria."),
    tipoProdutoServicoId: yup.string().required("Selecione um tipo."),

    fabricanteId: yup.string().required("Selecione um fabricante."),
});

/*
    

    //COLOCAR NO ESQUEMA


    //TABELA PRODUTO
    const returnFornecedores = useCallback((produto: tProduto): string => {
        if (produto.fornecedores.length < 1) return "--- nenhum ---";
        return produto.fornecedores.map(({ name: nome }) => nome)?.join(", ");
    }, []);
*/
