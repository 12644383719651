import { ReactNode } from "react";
import { Modal } from "react-bootstrap";

interface IModalBaseBodyProps {
    children?: ReactNode;
}

export default function ModalBaseBody(props: IModalBaseBodyProps) {
    return <Modal.Body className="overflow-hidden">{props.children}</Modal.Body>;
}
