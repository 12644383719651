import { Stack } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { tDashVendaPorTiposProdServ, tMetricaDashboard } from "../../interfaces";
import utils from "../../utils";
import "chart.js/auto";
import "./styles.css";

export default function CardTiposProdServ(props: { metrica: tMetricaDashboard; dados: tDashVendaPorTiposProdServ }) {
    //VARIAVEIS
    const nomesDosTipos = props.dados.map((dado) => dado.tipoProdServ.name);
    const valoresDeCadaTipo = props.dados.map((dado) => dado.valorAcumulado);

    return (
        <div className="my-div-produto-vs-servico-root rounded-4 shadow p-3 text-white">
            <Stack className="d-flex gap-2 h-100 w-100">
                <div className="my-div-produto-vs-servico-header d-flex">
                    <h6>{utils.retornaTextoMetricaDasboard(props.metrica)} por Tipo Produto/Serviço</h6>
                </div>

                <div className="my-div-produto-vs-servico-grafico d-flex w-100 pt-3 pe-3">
                    <Doughnut
                        options={{
                            aspectRatio: 0,
                            plugins: {
                                legend: { title: { color: "white" }, labels: { color: "white" } },
                                tooltip: {
                                    callbacks: {
                                        label: (info) => `Valor: ${utils.retornaValorMonetario(info.parsed, "BRL")}`,
                                    },
                                },
                            },
                            scales: { x: { display: false }, y: { display: false } },
                        }}
                        data={{
                            labels: nomesDosTipos,
                            datasets: [
                                {
                                    label: "Produtos X Serviços",
                                    data: valoresDeCadaTipo,
                                    hoverOffset: 4,
                                },
                            ],
                        }}
                    />
                </div>
            </Stack>
        </div>
    );
}
