import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tServico, tTipoFaturamento, tVendaServico } from "../../interfaces";
import { v4 } from "uuid";
import InputValores from "../inputs/InputValores";
import InputPorcentagem from "../inputs/InputPorcentagem";
import SelectServico, { ISelectServicoProps } from "../selects/SelectServico";

export const INFO_MARKUP_LIQUIDO =
    "Valor de comissão listado no site do distribuidor referente à comissão a ser paga para a revenda.";

//TYPES
export type tNovaVendaServico = PartialEntity<
    tVendaServico,
    | "servicoId"
    | "cotacao"
    | "markup"
    | "tipoFaturamento"
    | "dedutiveisDistribuidor"
    | "impostoNota"
    | "qntdHorasComercial"
    | "qntdHorasEspecial"
    | "qntdHorasNaoComercial"
>;

export interface IFormVendaServicoProps extends ISelectServicoProps {
    servicos: tServico[];
    formId?: string;
    onSubmit: (vendaServico: tNovaVendaServico) => void | Promise<void>;
    valoresIniciais: tNovaVendaServico;
}

//FORM
export default function FormVendaServico({
    formId,
    onSubmit,
    valoresIniciais,
    servicos,
    ...rest
}: IFormVendaServicoProps) {
    //CONTEXTOS
    const { setPerigo, setAviso } = useContext(ContextAlerta);

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (vendaServico: tNovaVendaServico, helpers: FormikHelpers<tNovaVendaServico>) => {
            try {
                await onSubmit(vendaServico);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaVendaServico}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                const servico = servicos.find(({ id }) => values.servicoId === id);

                const valorCustoHora = servico?.valorCustoHora ?? 0;
                const tipoMoeda = servico?.tipoMoeda ?? "BRL";

                const cotacaoDesabilitada = tipoMoeda === "BRL";
                const custoTotal =
                    values.qntdHorasComercial * valorCustoHora +
                    values.qntdHorasNaoComercial * valorCustoHora * 1.5 +
                    values.qntdHorasEspecial * valorCustoHora * 2;

                const valorMarkup = (values.markup * custoTotal) / 100;
                const valorVenda = custoTotal + valorMarkup;

                const valorMarkupLiquido = valorMarkup - (values.dedutiveisDistribuidor ?? 0);

                const valorNota =
                    values.tipoFaturamento === "DIRETO"
                        ? (values.impostoNota * valorMarkupLiquido) / 100
                        : (values.impostoNota * valorVenda) / 100;

                const valorImpostos = 0 + valorNota;
                const valorMargem = valorMarkupLiquido - valorImpostos;

                const atualizaMarkupPorValorVenda = (valor: number) => {
                    if (custoTotal === 0) return setAviso("O custo não pode ser 0.");
                    const novoValorMarkup = valor - custoTotal;
                    const novoMarkup = (novoValorMarkup * 100) / custoTotal;
                    setValues({ ...values, markup: novoMarkup });
                };
                const findPorcentMarkup = (valor: number) => {
                    if (custoTotal === 0) setAviso("O custo não pode ser 0.");
                    return custoTotal !== 0 ? (valor * 100) / custoTotal : 0;
                };
                const findPorcentImpNota = (valor: number) => {
                    switch (values.tipoFaturamento) {
                        case "DIRETO":
                            if (valorMarkupLiquido === 0) setAviso("O markup líquido não pode ser 0.");
                            return valorMarkupLiquido !== 0 ? (valor * 100) / valorMarkupLiquido : 0;
                        default:
                            if (valorVenda === 0 && values.tipoFaturamento === "REVENDA")
                                setAviso("O valor da venda não pode ser 0.");
                            return valorVenda !== 0 ? (valor * 100) / valorVenda : 0;
                    }
                };

                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row>
                            <Col sm="12" className="p-0">
                                <Row className="p-2 pt-4 pb-4 border-bottom">
                                    <Col sm="12">
                                        <h6>Serviço e Faturamento</h6>
                                    </Col>
                                    <Col sm="12" className="pe-4 text-end mb-2">
                                        <Form.Label className="mb-1">Serviço</Form.Label>
                                        <SelectServico
                                            {...rest}
                                            idSelecionado={values.servicoId}
                                            setIdSelecionado={(id) => setValues({ ...values, servicoId: id ?? "" })}
                                        />
                                        <Form.Control isInvalid={!!errors.servicoId} className="d-none" />
                                        <Form.Control.Feedback type="invalid">{errors.servicoId}</Form.Control.Feedback>
                                    </Col>

                                    <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                        <Form.Label className="mb-1">Tipo de faturamento</Form.Label>
                                        <Form.Select
                                            isInvalid={!!errors.tipoFaturamento}
                                            required
                                            onChange={(e) =>
                                                setValues({
                                                    ...values,
                                                    tipoFaturamento: e.target.value as tTipoFaturamento,
                                                })
                                            }
                                            value={values.tipoFaturamento}
                                        >
                                            {utils.retornaArrayTipoFaturamento().map((tipoFaturamento) => (
                                                <option key={tipoFaturamento} value={tipoFaturamento}>
                                                    {utils.retornaTextoTipoFaturamento(tipoFaturamento)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.tipoFaturamento}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Col>

                            <Col sm="12" className="p-0">
                                <Row className="p-2 bg-light pt-4 pb-4 border-bottom">
                                    <Col sm="12">
                                        <h6>
                                            Valores da venda (
                                            {utils.retornaTextoTipoFaturamento(values.tipoFaturamento)})
                                        </h6>
                                    </Col>

                                    <Form.Group as={Col} className="col-7 mb-2" controlId={v4()}>
                                        <Form.Label className="mb-1">Custo hora</Form.Label>
                                        <InputValores
                                            disabled={true}
                                            valor={valorCustoHora}
                                            moeda={tipoMoeda}
                                            onAccept={() => {}}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-5 mb-2" controlId={v4()}>
                                        <Form.Label className="mb-1">Cotação</Form.Label>
                                        <InputValores
                                            disabled={cotacaoDesabilitada}
                                            isInvalid={!!errors.cotacao}
                                            valor={values.cotacao}
                                            moeda="BRL"
                                            onAccept={
                                                !cotacaoDesabilitada
                                                    ? (cotacao) => setValues({ ...values, cotacao })
                                                    : () => {}
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.cotacao}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                        <Form.Label className="mb-1">Qntd. hora comercial</Form.Label>
                                        <Form.Control
                                            type="number"
                                            min={0}
                                            isInvalid={!!errors.qntdHorasComercial}
                                            value={values.qntdHorasComercial}
                                            onChange={(e) =>
                                                setValues({ ...values, qntdHorasComercial: Number(e.target.value) })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.qntdHorasComercial}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                        <Form.Label className="mb-1">Qntd. hora não comercial (1.5x)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            min={0}
                                            isInvalid={!!errors.qntdHorasNaoComercial}
                                            value={values.qntdHorasNaoComercial}
                                            onChange={(e) =>
                                                setValues({
                                                    ...values,
                                                    qntdHorasNaoComercial: Number(e.target.value),
                                                })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.qntdHorasNaoComercial}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                        <Form.Label className="mb-1">Qntd. hora especial (2x)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            min={0}
                                            isInvalid={!!errors.qntdHorasEspecial}
                                            value={values.qntdHorasEspecial}
                                            onChange={(e) =>
                                                setValues({ ...values, qntdHorasEspecial: Number(e.target.value) })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.qntdHorasEspecial}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Col sm="12" className="text-secondary-dark">
                                        <small>Custo: {utils.retornaValorMonetario(custoTotal, tipoMoeda)}</small>
                                    </Col>

                                    <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                        <Form.Label className="mb-1">Markup Bruto %</Form.Label>
                                        <InputPorcentagem
                                            isInvalid={!!errors.markup}
                                            required
                                            valor={values.markup}
                                            onAccept={(markup) => setValues({ ...values, markup })}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.markup}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                        <Form.Label className="mb-1">Markup Bruto</Form.Label>
                                        <InputValores
                                            required
                                            moeda={tipoMoeda}
                                            valor={valorMarkup}
                                            onAccept={(valor) =>
                                                setValues({ ...values, markup: findPorcentMarkup(valor) })
                                            }
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-12 mb-2" controlId={v4()}>
                                        <Form.Label className="mb-1">Venda (unitário)</Form.Label>
                                        <InputValores
                                            valor={valorVenda}
                                            moeda={tipoMoeda}
                                            onAccept={(valorVenda) => {
                                                atualizaMarkupPorValorVenda(valorVenda);
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>

                            {values.tipoFaturamento === "DIRETO" && (
                                <Col sm="12" className="p-0 border-bottom">
                                    <Row className="p-2 pt-4 pb-4">
                                        <Col sm="12">
                                            <h6>Dedutíveis e Impostos de Fornecimento</h6>
                                        </Col>

                                        <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                            <Form.Label className="mb-1 text-nowrap" role="button">
                                                Dedutíveis distribuidor
                                            </Form.Label>
                                            <InputValores
                                                isInvalid={!!errors.dedutiveisDistribuidor}
                                                moeda={tipoMoeda}
                                                required
                                                valor={values.dedutiveisDistribuidor ?? 0}
                                                onAccept={(dedutiveisDistribuidor) =>
                                                    setValues({ ...values, dedutiveisDistribuidor })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dedutiveisDistribuidor}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                            <Form.Label className="mb-1 text-nowrap" role="button">
                                                Markup Líquido
                                                <OverlayTrigger overlay={<Tooltip>{INFO_MARKUP_LIQUIDO}</Tooltip>}>
                                                    <i className="bi bi-question-circle-fill text-primary ms-1" />
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <InputValores
                                                required
                                                moeda={tipoMoeda}
                                                valor={valorMarkupLiquido}
                                                onAccept={(markupLiquido) =>
                                                    setValues({
                                                        ...values,
                                                        dedutiveisDistribuidor: valorMarkup - markupLiquido,
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                    </Row>
                                </Col>
                            )}

                            <Col sm="12" className="p-0">
                                <Row className="p-2 pt-4 pb-4">
                                    <Col sm="12">
                                        <h6>Tributos Referentes à Nota Fiscal da Revenda</h6>
                                    </Col>

                                    <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                        <Form.Label className="mb-1 text-nowrap" role="button">
                                            Imposto Nota %
                                        </Form.Label>
                                        <InputPorcentagem
                                            isInvalid={!!errors.impostoNota}
                                            required
                                            valor={values.impostoNota}
                                            onAccept={(impostoNota) => setValues({ ...values, impostoNota })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.impostoNota}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                        <Form.Label className="mb-1 text-nowrap" role="button">
                                            Imposto Nota
                                        </Form.Label>
                                        <InputValores
                                            required
                                            moeda={tipoMoeda}
                                            valor={valorNota}
                                            onAccept={(valor) =>
                                                setValues({
                                                    ...values,
                                                    impostoNota: findPorcentImpNota(valor),
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <Col sm="12" className="text-secondary-dark">
                                        <small>Impostos: {utils.retornaValorMonetario(valorImpostos, tipoMoeda)}</small>
                                    </Col>

                                    <Col sm="12" className="text-secondary-dark">
                                        <small>Margem: {utils.retornaValorMonetario(valorMargem, tipoMoeda)}</small>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaVendaServico: yup.ObjectSchema<tNovaVendaServico> = yup.object({
    servicoId: yup.string().required("Selecione um serviço."),
    dedutiveisDistribuidor: yup.number().required("Este campo é obrigatório."),
    impostoNota: yup.number().required("Este campo é obrigatório."),
    qntdHorasComercial: yup.number().required("Este campo é obrigatório."),
    qntdHorasEspecial: yup.number().required("Este campo é obrigatório."),
    qntdHorasNaoComercial: yup.number().required("Este campo é obrigatório."),
    markup: yup.number().required("Este campo é necessário."),
    cotacao: yup
        .number()
        .required("Este campo é necessário.")
        .min(0.000001, "O valor da cotação deve ser no mínimo 0.000001"),
    tipoFaturamento: yup
        .mixed<tTipoFaturamento>()
        .oneOf(utils.retornaArrayTipoFaturamento())
        .required("Este campo é necessário."),
});
