import { useCallback, useRef, useState } from "react";
import { Col, Placeholder, Row, Stack } from "react-bootstrap";
import utils from "../../utils";
import Dropdown from "./dropdown";
import { tEstadoVenda, tVendedor } from "../../interfaces";
import useVendedor from "../../hooks/useVendedor";
import BotaoAssincrono from "../botoes/BotaoAssincrono";
import useComissao from "../../hooks/useComissao";
import useFiltros from "../../hooks/useFiltros";

export default function DropdownRelatoriosComissao() {
    //HOOKS
    const { buscaComErro, buscarPorId, estaBuscandoPorId } = useVendedor();
    const { buscarRelatorios } = useComissao();
    const { getFilter } = useFiltros();

    //REFS
    const divRef = useRef<HTMLDivElement>(null);

    //ESTADOS
    const [vendedorSelecionado, setVendedorSelecionado] = useState<tVendedor | null>(null);

    //VARIABLES
    const ano = getFilter("ano") ?? undefined;
    const mesInicial = getFilter("mesInicial") ?? undefined;
    const mesFinal = getFilter("mesFinal") ?? undefined;
    const vendedorId = getFilter("vendedor") ?? undefined;
    const estadoVenda = (getFilter("estadoVenda") as tEstadoVenda) ?? undefined;

    const retornaDatasFiltradasFormatadas = useCallback(() => {
        const datas = utils.datasPorMesesAno(mesInicial, mesFinal, ano);
        const inicio = datas?.dataInicial?.toLocaleDateString("pt-Br", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        const fim = datas?.dataFinal?.toLocaleDateString("pt-Br", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });

        return `${inicio} - ${fim}`;
    }, [mesInicial, mesFinal, ano]);

    //EVENTOS
    const buscarVendedores = useCallback(async () => {
        if (!vendedorId) return setVendedorSelecionado(null);

        try {
            const vendedor = await buscarPorId(vendedorId);
            setVendedorSelecionado(vendedor);
        } catch (err) {
            console.error(err);
        }
    }, [vendedorId, buscarPorId]);

    const buscarRelatoriosDeComissoes = useCallback(async () => {
        const datas = utils.datasPorMesesAno(mesInicial, mesFinal, ano);

        const periodo = `${datas?.dataInicial?.toLocaleDateString("pt-Br", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        })} até ${datas?.dataFinal?.toLocaleDateString("pt-Br", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        })}`.replaceAll("/", "-");

        try {
            const relatorio = await buscarRelatorios({
                where: {
                    dataFim: datas?.dataFinal,
                    dataInicio: datas?.dataInicial,
                    vendedorId,
                    estadoVenda: estadoVenda,
                },
            });

            const a = document.createElement("a");

            const blobFile = new Blob([relatorio], { type: "pdf" });
            const url = window.URL.createObjectURL(blobFile);
            a.href = url;
            a.download = `Comissões ${periodo}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error(err);
        }
    }, [vendedorId, mesInicial, mesFinal, ano, buscarRelatorios, estadoVenda]);

    return (
        <>
            <Dropdown.Root onOpenMenu={buscarVendedores}>
                <Dropdown.Item ref={divRef} className="text-primary">
                    <span>Relatórios</span>
                </Dropdown.Item>
                <Dropdown.Menu rootClose target={divRef.current} placement="bottom-start">
                    <Row>
                        <Col sm="12" className="lh-1">
                            <Stack className="bg-info bg-opacity-25 rounded p-2 border border-info text-info">
                                <small className="mb-2">Relatórios filtrados por:</small>
                                <small className="fw-bold mb-1">Data: {retornaDatasFiltradasFormatadas()}</small>
                                {estaBuscandoPorId || buscaComErro ? (
                                    <Placeholder sm="6" animation="glow" className="opacity-50 small">
                                        <Placeholder className="w-100 rounded" />
                                    </Placeholder>
                                ) : (
                                    <small className="fw-bold mb-1">
                                        Vendedores: {vendedorSelecionado ? vendedorSelecionado.user.name : "Todos"}
                                    </small>
                                )}
                                <small className="fw-bold mb-1">
                                    Estado das vendas:{" "}
                                    {estadoVenda ? utils.retornaTextoEstadoVenda(estadoVenda) : "Todos os estados."}
                                </small>
                            </Stack>
                        </Col>

                        <Col sm="12" className="mt-2 text-end">
                            <BotaoAssincrono size="sm" className="rounded-pill" onClick={buscarRelatoriosDeComissoes}>
                                Baixar relatórios
                            </BotaoAssincrono>
                        </Col>
                    </Row>
                </Dropdown.Menu>
            </Dropdown.Root>
        </>
    );
}
