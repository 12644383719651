import { Col, Overlay, Placeholder, Row, Spinner } from "react-bootstrap";
import { ReactNode, useCallback, useRef, useState } from "react";
import "./styles.css";
import InputSearch from "../inputs/InputSearch";

interface FiltroSelectProps {
    titulo?: string;
    texto?: string;
    onSearch?: (pesquisa?: string) => void;
    children?: ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
    carregando?: boolean;
}

export default function FiltroSelect(props: FiltroSelectProps) {
    //ESTADOS
    const [mostrarOpcoes, setMostrarOpcoes] = useState(false);
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);
    const filtroButtonRef = useRef<HTMLDivElement>(null);

    //VARIAVEIS
    const { onSearch, onOpen, onClose } = props;

    //EVENTOS
    const handleOnChangeText = useCallback(
        (pesquisa?: string) => {
            if (onSearch) onSearch(pesquisa);
            setPesquisa(pesquisa);
        },
        [onSearch]
    );
    const handleOnShow = useCallback(() => {
        setMostrarOpcoes(true);
        if (onOpen) onOpen();
    }, [onOpen]);
    const handleOnHide = useCallback(() => {
        setMostrarOpcoes(false);
        if (onClose) onClose();
    }, [onClose]);

    return (
        <>
            <div
                className="my-div-filter-select d-flex border rounded-pill bg-white ps-2 pe-2 position-relative"
                role="button"
                title={props.texto}
                onClick={handleOnShow}
                ref={filtroButtonRef}
            >
                <div className="my-div-filter-text position-relative d-flex justify-content-between align-items-center w-100">
                    {props.carregando ? (
                        <Placeholder
                            className="d-flex align-items-center w-100 h-100 text-center opacity-25"
                            animation="glow"
                        >
                            <Placeholder sm="10" className="rounded-1" />
                        </Placeholder>
                    ) : (
                        <span className="text-truncate me-3">
                            {props.titulo ? (
                                <span className="me-2 rounded-pill p-1 bg-light bg-opacity-50 text-primary">
                                    <i className="me-1 bi bi-filter-circle-fill text-primary" />
                                    <span className="fw-bold">{props.titulo}</span>
                                </span>
                            ) : (
                                <i className="me-1 bi bi-filter-circle-fill text-primary" />
                            )}

                            {props.texto}
                        </span>
                    )}

                    <i className="bi bi-caret-down-fill small opacity-75" />
                </div>
            </div>
            <Overlay
                target={filtroButtonRef.current}
                show={mostrarOpcoes}
                placement="bottom"
                container={filtroButtonRef.current}
                rootClose
                onHide={handleOnHide}
            >
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...overlayProps
                }) => (
                    <div className="w-100 mt-1 bg-white rounded shadow p-2" {...overlayProps}>
                        <Row>
                            {props.onSearch && (
                                <Col sm="12" className="mb-2">
                                    <InputSearch pesquisa={pesquisa} onChange={handleOnChangeText} />
                                </Col>
                            )}

                            <Col sm="12" className="my-col-filtro-menu-body">
                                {props.carregando ? (
                                    <div className="w-100 text-center">
                                        <Spinner size="sm" /> Carregando...
                                    </div>
                                ) : (
                                    props.children
                                )}
                            </Col>
                        </Row>
                    </div>
                )}
            </Overlay>
        </>
    );
}
