import { tMesesSelecionados } from "./components/filtros/FiltroMes";
import { tNovaVenda } from "./components/forms/FormVenda";
import { tTipoMeta } from "./components/metas/MetasVendedor";
import {
    tAcao,
    tEstadoVenda,
    tMeta,
    tMetricaDashboard,
    tPapelUsuario,
    tTipoFaturamento,
    tTipoGasto,
    tTipoMetaAlternativa,
    tTipoMoeda,
    tVenda,
} from "./interfaces";
import * as XLSX from "xlsx";

const utils = {
    isNil: (value: any) => value === undefined || value === null,
    blErroCancelamento: (err: any): boolean => err?.code === "ERR_CANCELED" || err?.code === undefined,
    retornaValorMonetario: (valor: number, moeda?: tTipoMoeda) => {
        return (
            retornaCifraoMoeda(moeda) +
            " " +
            valor.toLocaleString("pt-Br", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        );
    },
    retornaLetrasDoNome: (nomeUsuario: string) => {
        const partesDoNome = nomeUsuario.split(" ");
        const partesDoNomeDefinidas = partesDoNome.filter((parte) => parte !== undefined);

        const qntDePartes = partesDoNomeDefinidas.length;
        const primeiraParte = partesDoNomeDefinidas[0];
        const ultimaParte = partesDoNomeDefinidas[qntDePartes - 1];

        if (qntDePartes > 1) return primeiraParte[0] + ultimaParte[0];
        else return primeiraParte[0];
    },
    retornaCNPJFormatado: (CNPJ: string) => {
        return CNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    },
    retornaTelefoneFormatado: (telefone: string) => {
        telefone = telefone.replace(/\D/g, ""); // Remove qualquer caractere não numérico

        if (telefone.length === 13 && telefone.startsWith("55")) {
            telefone = telefone.substring(2); // Remove o 55 (código do país)
            return "+55 (" + telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, "$1) $2-$3");
        }

        // Formato para celular com DDD
        if (telefone.length === 11) {
            return telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
        }

        // Formato para telefone fixo com DDD
        if (telefone.length === 10) {
            return telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
        }

        return telefone;
    },
    retornaArrayTipoFaturamento: (): tTipoFaturamento[] => {
        const objTipos: { [Key in tTipoFaturamento]: undefined } = { REVENDA: undefined, DIRETO: undefined };
        return Object.keys(objTipos) as tTipoFaturamento[];
    },
    retornaTextoTipoFaturamento: (tipo: tTipoFaturamento) => {
        switch (tipo) {
            case "DIRETO":
                return "Direto";
            case "REVENDA":
                return "Revenda";
        }
    },
    retornaArrayTipoGasto: (): tTipoGasto[] => {
        const objTipos: { [Key in tTipoGasto]: undefined } = { HOSPEDAGEM: undefined, ALIMENTACAO: undefined };
        return Object.keys(objTipos) as tTipoGasto[];
    },
    retornaTextoTipoGasto: (tipo: tTipoGasto) => {
        switch (tipo) {
            case "ALIMENTACAO":
                return "Alimentação";
            case "HOSPEDAGEM":
                return "Hospedagem";
        }
    },
    retornaArrayTipoMetaAlternativa: (): tTipoMetaAlternativa[] => {
        const objTipos: { [Key in tTipoMetaAlternativa]: undefined } = { VISITA: undefined };
        return Object.keys(objTipos) as tTipoMetaAlternativa[];
    },
    retornaTextoTipoMetaAlternativa: (tipo: tTipoMetaAlternativa) => {
        switch (tipo) {
            case "VISITA":
                return "Visita";
        }
    },
    retornaArrayTipoMeta: (): tTipoMeta[] => {
        const objTipos: { [Key in tTipoMeta]: undefined } = {
            FATURAMENTO: undefined,
            VENDA: undefined,
            VISITA: undefined,
        };
        return Object.keys(objTipos) as tTipoMeta[];
    },
    retornaTextoTipoMeta: (tipo: tTipoMeta) => {
        switch (tipo) {
            case "FATURAMENTO":
                return "Faturamento";
            case "VENDA":
                return "Venda";
            case "VISITA":
                return "Visita";
        }
    },
    retornaArrayEstadoVenda: (): tEstadoVenda[] => {
        const objEstados: { [Key in tEstadoVenda]: undefined } = {
            CRIADA: undefined,
            AGUARDANDO_VERIFICACAO: undefined,
            VERIFICADA: undefined,
        };
        return Object.keys(objEstados) as tEstadoVenda[];
    },
    retornaTextoEstadoVenda: (estado: tEstadoVenda) => {
        switch (estado) {
            case "VERIFICADA":
                return "Venda verificada";
            case "AGUARDANDO_VERIFICACAO":
                return "Aguardando verificação";
            case "CRIADA":
                return "Criada";
        }
    },
    retornaArrayTipoMoeda: (): tTipoMoeda[] => {
        const objTipoMoeda: { [Key in tTipoMoeda]: undefined } = { BRL: undefined, USD: undefined };
        return Object.keys(objTipoMoeda) as tTipoMoeda[];
    },
    retornaTextoTipoMoeda: (tipoMoeda: tTipoMoeda): string => {
        switch (tipoMoeda) {
            case "BRL":
                return "Real";
            case "USD":
                return "Dolar";
        }
    },
    retornaArrayMetricaDashboard: (): tMetricaDashboard[] => {
        const objMetrica: { [Key in tMetricaDashboard]: undefined } = {
            FATURAMENTO: undefined,
            VALOR_VENDA: undefined,
            MARGEM: undefined,
            MARKUP_BRUTO: undefined,
            MARKUP_LIQUIDO: undefined,
        };
        return Object.keys(objMetrica) as tMetricaDashboard[];
    },
    retornaTextoMetricaDasboard: (metrica: tMetricaDashboard): string => {
        switch (metrica) {
            case "VALOR_VENDA":
                return "Valor de Venda";
            case "FATURAMENTO":
                return "Faturamento";
            case "MARGEM":
                return "Margem Bruta";
            case "MARKUP_BRUTO":
                return "Markup Bruto";
            case "MARKUP_LIQUIDO":
                return "Markup Líquido";
        }
    },
    retornaCifraoMoeda,
    retornaTextoPapelUsuario: (papel: tPapelUsuario) => {
        switch (papel) {
            case "VENDEDOR":
                return "Vendedor";
            case "ADMIN":
                return "Administrador";
            case "GERENTE_VENDAS":
                return "Gerente de vendas";
        }
    },
    palavraSimilar: (palavra: string, similares: string[]): boolean => {
        for (let similar of similares) {
            if (palavra.toLowerCase().includes(similar.toLowerCase())) return true;
        }
        return false;
    },
    retornaMensagemErro: (error: any): string => {
        const msg = error?.response?.data?.message ?? error?.message ?? "Erro desconhecido.";
        const codigo = error?.response?.status ?? error?.code ?? "Erro";
        return `${codigo}: ${msg}`;
    },
    retornaDataDMA: (data: Date): string => {
        return data.toLocaleDateString("pt-BR", { day: "2-digit", month: "short", year: "numeric" });
    },
    retornaDataMA: (data: Date): string => {
        return data.toLocaleDateString("pt-BR", { month: "short", year: "2-digit" });
    },
    retornaDataA: (data: Date): string => {
        return data.toLocaleDateString("pt-BR", { year: "numeric" });
    },
    retornaDataM: (data: Date): string => {
        return data.toLocaleDateString("pt-BR", { month: "long" });
    },
    retornaTiposMoedaObjeto: (): { valor: tTipoMoeda; texto: string }[] => {
        const tempMoedasObject: { [key in tTipoMoeda]: undefined } = { BRL: undefined, USD: undefined };
        return (Object.keys(tempMoedasObject) as tTipoMoeda[]).map((tipoMoeda) => ({
            valor: tipoMoeda,
            texto: tipoMoeda === "BRL" ? "Real" : "Dolar",
        }));
    },
    retornaDataAtual: (props: {
        diasAdicionais?: number;
        mesesAdicionais?: number;
        anosAdicionais?: number;
        hora?: number;
        minutos?: number;
        segundos?: number;
    }) => {
        const dataAtual = new Date();
        if (props.diasAdicionais !== undefined) dataAtual.setDate(dataAtual.getDate() + props.diasAdicionais);
        if (props.mesesAdicionais !== undefined) dataAtual.setMonth(dataAtual.getMonth() + props.mesesAdicionais);
        if (props.anosAdicionais !== undefined) dataAtual.setFullYear(dataAtual.getFullYear() + props.anosAdicionais);
        if (props.hora !== undefined) dataAtual.setHours(props.hora);
        if (props.minutos !== undefined) dataAtual.setMinutes(props.minutos);
        if (props.segundos !== undefined) dataAtual.setSeconds(props.segundos);
        dataAtual.setMilliseconds(0);
        return dataAtual;
    },
    retornaData: (props: {
        dia?: number;
        mes?: number;
        ano?: number;
        hora?: number;
        minutos?: number;
        segundos?: number;
        miliSeg?: number;
    }) => {
        const data = new Date();
        if (props.dia !== undefined) data.setDate(props.dia);
        if (props.mes !== undefined) data.setMonth(props.mes);
        if (props.ano !== undefined) data.setFullYear(props.ano);
        if (props.hora !== undefined) data.setHours(props.hora);
        if (props.minutos !== undefined) data.setMinutes(props.minutos);
        if (props.segundos !== undefined) data.setSeconds(props.segundos);
        if (props.miliSeg !== undefined) data.setMilliseconds(props.miliSeg);
        return data;
    },
    retornaPeriodoMeta: (meta: tMeta): string => {
        let mesInicial = utils.retornaDataM(meta.dataInicioMeta);
        const anoInicial = utils.retornaDataA(meta.dataInicioMeta);
        let mesFinal = utils.retornaDataM(meta.dataFimMeta);
        const anoFinal = utils.retornaDataA(meta.dataFimMeta);

        //Formatando mes inicial
        mesInicial = mesInicial[0].toUpperCase() + mesInicial.slice(1);
        mesFinal = mesFinal[0].toUpperCase() + mesFinal.slice(1);
        return `${mesInicial}, ${anoInicial} - ${mesFinal}, ${anoFinal}`;
    },
    datasPorMesesAno: (mesInicial?: string, mesFinal?: string, ano?: string) => {
        const faltamValores = !mesInicial || !mesFinal || !ano;
        const anoErrado = isNaN(Number(ano)) || !Number.isInteger(Number(ano));
        const mesesErrados =
            isNaN(Number(mesInicial)) ||
            isNaN(Number(mesFinal)) ||
            !Number.isInteger(Number(mesInicial)) ||
            !Number.isInteger(Number(mesFinal));

        if (faltamValores || anoErrado || mesesErrados) return undefined;

        const dataInicial = utils.retornaData({
            dia: 1,
            mes: Number(mesInicial),
            ano: Number(ano),
            hora: 0,
            minutos: 0,
            segundos: 0,
            miliSeg: 0,
        });
        const dataFinal = utils.retornaData({
            dia: 1,
            mes: Number(mesFinal),
            ano: Number(ano),
            hora: 23,
            minutos: 59,
            segundos: 59,
            miliSeg: 999,
        });

        dataFinal?.setMonth(dataFinal.getMonth() + 1);
        dataFinal?.setDate(dataFinal.getDate() - 1);

        return { dataInicial, dataFinal };
    },
    retornaDatasFiltroMesAno: (meses: tMesesSelecionados | null, ano?: number | null) => {
        const possivelCalcularDatas = meses !== null && ano !== null && ano !== undefined;
        const dataInicialSelecionada = possivelCalcularDatas
            ? utils.retornaData({
                  dia: 1,
                  mes: meses.inicio,
                  ano,
                  hora: 0,
                  minutos: 0,
                  segundos: 0,
                  miliSeg: 0,
              })
            : undefined;
        const dataFinalSelecionada = possivelCalcularDatas
            ? utils.retornaData({
                  dia: 1,
                  mes: meses.fim,
                  ano,
                  hora: 23,
                  minutos: 59,
                  segundos: 59,
                  miliSeg: 999,
              })
            : undefined;
        dataFinalSelecionada?.setMonth(dataFinalSelecionada.getMonth() + 1);
        dataFinalSelecionada?.setDate(dataFinalSelecionada.getDate() - 1);
        return { dataInicialSelecionada, dataFinalSelecionada };
    },
    retornaTextoAcao: (acao: tAcao): string => {
        switch (acao) {
            case "CREATE":
                return "Criou";
            case "UPDATE":
                return "Atualizou";
            case "DELETE":
                return "Deletou";
        }
    },
    xlsx_to_json: (data: ArrayBuffer): any[] => {
        const dadosTransformados = [];
        const workbook = XLSX.read(data);
        for (let nomeDaPagina of workbook.SheetNames) {
            const dadosJSON = XLSX.utils.sheet_to_json(workbook.Sheets[nomeDaPagina], { defval: "" });
            dadosTransformados.push(...dadosJSON);
        }
        return dadosTransformados;
    },
    json_to_xlsx_url: (nomePlanilha: string, data: any[]): string => {
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, nomePlanilha);

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        return url;
    },
    retornaNovaVendaDeVenda: (venda: tVenda): tNovaVenda => {
        return {
            clienteId: venda.clienteId,
            codigoNota: venda.codigoNota,
            dataFaturamento: venda.dataFaturamento,
            dataVenda: venda.dataVenda,
            estadoVenda: venda.estadoVenda,
            formaParcelamento: venda.formaParcelamento,
            formaPagamentoId: venda.formaPagamentoId,
            numOportunidadeCRM: venda.numOportunidadeCRM,
            numPedidoDistribuidor: venda.numPedidoDistribuidor,
            comissaoPrimeiraParcela: venda.comissaoPrimeiraParcela,
            vendaRenovacao: venda.vendaRenovacao,
            contratoId: venda.contratoId,
            datasBoleto: venda.datasBoleto.map((dataBoleto) => dataBoleto.data),
            vendasProduto: venda.vendasProduto.map((vendaProduto) => ({
                tipoMoeda: vendaProduto.tipoMoeda,
                cotacao: vendaProduto.cotacao,
                valorCustoUnit: vendaProduto.valorCustoUnit,
                quantidade: vendaProduto.quantidade,
                markup: vendaProduto.markup,
                tipoFaturamento: vendaProduto.tipoFaturamento,
                impostoNota: vendaProduto.impostoNota,
                dedutiveisDistribuidor: vendaProduto.dedutiveisDistribuidor,
                produtoId: vendaProduto.produtoId,
                fornecedoresId: vendaProduto.fornecedores.map(({ id }) => id),
            })),
            vendasServico: venda.vendasServico.map((vendaServico) => ({
                cotacao: vendaServico.cotacao,
                markup: vendaServico.markup,
                tipoFaturamento: vendaServico.tipoFaturamento,
                impostoNota: vendaServico.impostoNota,
                dedutiveisDistribuidor: vendaServico.dedutiveisDistribuidor,
                servicoId: vendaServico.servicoId,
                qntdHorasComercial: vendaServico.qntdHorasComercial,
                qntdHorasEspecial: vendaServico.qntdHorasEspecial,
                qntdHorasNaoComercial: vendaServico.qntdHorasNaoComercial,
            })),
            vendedoresId: venda.vendedores.map(({ id }) => id),
        };
    },
    deStringPara: <T>(value: any, type: "number" | "date" | "boolean"): T | undefined => {
        switch (type) {
            case "number":
                const num = Number(value);
                return isNaN(num) ? undefined : (num as T);

            case "date":
                const date = new Date(value);
                return isNaN(date.getTime()) ? undefined : (date as T);
            case "boolean":
                return value === "true" ? (true as T) : value === "false" ? (false as T) : undefined;
            default:
                return undefined;
        }
    },
};

export default utils;

function retornaCifraoMoeda(moeda?: tTipoMoeda) {
    switch (moeda) {
        case "USD":
            return "US$";
        case "BRL":
            return "R$";
        default:
            return "$";
    }
}
