import { ReactNode, useContext } from "react";
import { Container } from "react-bootstrap";
import { InfiniteScroll, TabelaCarregando } from "../../components";
import { InfiniteScrollProps } from "../../components/infiniteScroll/InfiniteScroll";
import { ContextUser } from "../../contexts/ContextUser";
import "./styles.css";

interface LayoutTabelaBodyProps extends InfiniteScrollProps {
    children?: ReactNode;
}

export default function LayoutTabelaBody({ children, ...rest }: LayoutTabelaBodyProps) {
    //CONTEXTOS
    const { carregandoUsuario } = useContext(ContextUser);

    return (
        <div className="d-flex my-div-body position-relative bg-white z-0">
            <InfiniteScroll {...rest}>
                <Container fluid className="position-relative">
                    {carregandoUsuario ? <TabelaCarregando /> : children}
                </Container>
            </InfiniteScroll>
        </div>
    );
}
