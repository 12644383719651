import { ReactNode } from "react";
import "./styles.css";

interface ILayoutTabelaHeaderLeft {
    children?: ReactNode;
}

export default function LayoutTabelaHeaderLeft(props: ILayoutTabelaHeaderLeft) {
    return (
        <div className="d-flex my-div-session-filter-filter justify-content-start align-items-center gap-2">
            {props.children}
        </div>
    );
}
