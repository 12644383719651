import { Col, Form, Row, Stack } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import { PartialEntity, tServico, tTipoMoeda } from "../../interfaces";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import InputValores from "../inputs/InputValores";
import InputTextArea from "../inputs/InputTextArea";
import SelectTipoProdutoServico from "../selects/SelectTipoProdutoServico";
import { v4 } from "uuid";

//TYPE
export type tNovoServico = PartialEntity<
    tServico,
    "name" | "observacoes" | "tipoMoeda" | "tipoProdutoServicoId" | "valorCustoHora"
>;

interface IFormServicoProps {
    formId?: string;
    onSubmit: (servico: tNovoServico) => void | Promise<void>;
    valoresIniciais: tNovoServico;
}

//FORM
export default function FormServico(props: IFormServicoProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (servico: tNovoServico, helpers: FormikHelpers<tNovoServico>) => {
            try {
                await onSubmit(servico);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaServico}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Nome do serviço</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.name}
                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>

                            <Col sm="12">
                                <Row>
                                    <Form.Group as={Col} className="col-5" controlId={v4()}>
                                        <Form.Label className="mb-1">Moeda</Form.Label>
                                        <Form.Select
                                            isInvalid={!!errors.tipoMoeda}
                                            required
                                            onChange={(e) =>
                                                setValues({ ...values, tipoMoeda: e.target.value as tTipoMoeda })
                                            }
                                            value={values.tipoMoeda}
                                        >
                                            {utils.retornaTiposMoedaObjeto().map((tipoMoeda) => (
                                                <option key={tipoMoeda.valor} value={tipoMoeda.valor}>
                                                    {tipoMoeda.texto}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.tipoMoeda}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-7" controlId={v4()}>
                                        <Form.Label className="mb-1">Custo por hora</Form.Label>
                                        <InputValores
                                            required
                                            moeda={values.tipoMoeda}
                                            valor={values.valorCustoHora}
                                            onAccept={(valorCustoHora) => setValues({ ...values, valorCustoHora })}
                                            isInvalid={!!errors.valorCustoHora}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.valorCustoHora}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Col>

                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Observações</Form.Label>
                                <InputTextArea
                                    rows={4}
                                    required
                                    max={256}
                                    value={values.observacoes ?? ""}
                                    onChange={(e) => setValues({ ...values, observacoes: e.target.value })}
                                    isInvalid={!!errors.observacoes}
                                />
                                <Form.Control.Feedback type="invalid">{errors.observacoes}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm="12" className="text-end">
                                <Form.Label className="mb-0">
                                    <Stack className="d-flex align-items-center w-100">
                                        <span className="mb-1">Tipo de Produto/Serviço</span>
                                        <SelectTipoProdutoServico
                                            setIdSelecionado={(id) =>
                                                setValues({ ...values, tipoProdutoServicoId: id ?? "" })
                                            }
                                            idSelecionado={values.tipoProdutoServicoId}
                                        />
                                    </Stack>
                                </Form.Label>
                                <Form.Control className="d-none" isInvalid={!!errors.tipoProdutoServicoId} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.tipoProdutoServicoId}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaServico: yup.ObjectSchema<tNovoServico> = yup.object({
    name: yup.string().required("Campo necessário.").max(256, "Deve ter no máximo 256 carecteres."),
    valorCustoHora: yup
        .number()
        .defined()
        .required("Campo necessário.")
        .min(0.01, "O valor mínimo deve ser maior que 0.01"),
    tipoMoeda: yup.mixed<tTipoMoeda>().oneOf(["BRL", "USD"]).required("Campo necessário"),
    observacoes: yup.string().required("Adicione uma observação."),
    tipoProdutoServicoId: yup.string().required("Selecione um tipo."),
});
