import { Button, Col, Row } from "react-bootstrap";
import { tAuditoria, tServico, tVenda } from "../../interfaces";
import { ModalBase } from "./ModalBase";
import { IModalBaseRoot } from "./ModalBase/ModalBaseRoot";
import { useCallback, useEffect, useState } from "react";
import useAuditoria, { LIMITE_POR_BUSCA_AUDITORIA } from "../../hooks/useAuditoria";
import InfiniteScroll from "../infiniteScroll/InfiniteScroll";
import TimeLine from "../timeline/Timeline";
import utils from "../../utils";
import "./styles.css";
import { tNovaVenda } from "../forms/FormVenda";

export interface ModalAuditoriaVendaProps extends IModalBaseRoot {
    venda: tVenda | null;
}
export default function ModalAuditoriaVenda({ venda, ...rest }: ModalAuditoriaVendaProps) {
    //HOOKS
    const { buscaComErro, buscar, estaBuscando, cancelar } = useAuditoria();

    //ESTADOS
    const [auditorias, setAuditorias] = useState<tAuditoria[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);

    //VARIAVEIS
    const buscarAuditorias = useCallback(
        async (venda: tVenda, pagina?: number) => {
            try {
                const novasAuditorias = await buscar({
                    limit: LIMITE_POR_BUSCA_AUDITORIA,
                    page: pagina,
                    where: { tabela: "VENDA", dadoId: venda.id },
                });
                if (pagina === undefined) {
                    setAuditorias(novasAuditorias);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setAuditorias((atuais) => [...atuais, ...novasAuditorias]);
                return novasAuditorias.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [buscar]
    );

    //EVENTOS
    useEffect(() => {
        if (!venda) return setAuditorias([]);

        buscarAuditorias(venda);
        return () => cancelar();
    }, [venda, cancelar, buscarAuditorias]);

    return (
        <ModalBase.Root {...rest}>
            <ModalBase.Header>
                <h4>Auditoria da Venda</h4>
            </ModalBase.Header>
            <ModalBase.Body>
                <Row className="my-auditoria-modal-body">
                    <InfiniteScroll
                        buscando={estaBuscando || buscaComErro}
                        paginaAtual={paginaAtual}
                        proximaPagina={proximaPagina}
                        setPaginaAtual={setPaginaAtual}
                        setProximaPagina={setProximaPagina}
                    >
                        {auditorias.length < 1 && !estaBuscando && !buscaComErro && (
                            <div className="d-flex w-100 justify-content-center">
                                <h6 className="mb-0 text-secondary-dark">Não existe nenhum registro nesta auditoria</h6>
                            </div>
                        )}
                        {auditorias.length > 0 && (
                            <TimeLine
                                dados={auditorias.map((auditoria) => {
                                    const dado: tNovaVenda = auditoria.dado as any;

                                    const markupMedioProdutos =
                                        dado.vendasProduto.length > 0
                                            ? (
                                                  dado.vendasProduto.reduce(
                                                      (acc, produto) => (acc += produto.markup),
                                                      0
                                                  ) / dado.vendasProduto.length
                                              ).toFixed(2)
                                            : "--,--";
                                    const markupMedioServicos =
                                        dado.vendasServico.length > 0
                                            ? (
                                                  dado.vendasServico.reduce(
                                                      (acc, servico) => (acc += servico.markup),
                                                      0
                                                  ) / dado.vendasServico.length
                                              ).toFixed(2)
                                            : "--,--";

                                    const valorVendaProdutos = dado.vendasProduto.reduce((acc, produto) => {
                                        const custo = produto.quantidade * produto.valorCustoUnit * produto.cotacao;
                                        const markup = (produto.markup * custo) / 100;
                                        return (acc += custo + markup);
                                    }, 0);
                                    const valorVendaServicos = dado.vendasServico.reduce((acc, servico) => {
                                        const custoH = (servico as unknown as tServico).valorCustoHora;
                                        const _custo =
                                            custoH * servico.qntdHorasComercial +
                                            custoH * servico.qntdHorasNaoComercial * 1.5 +
                                            custoH * servico.qntdHorasEspecial * 2;
                                        const custo = _custo * servico.cotacao;
                                        const markup = (servico.markup * custo) / 100;
                                        return (acc += custo + markup);
                                    }, 0);

                                    return {
                                        feitoPor: auditoria.usuario,
                                        data: auditoria.data,
                                        descricao: (
                                            <Row>
                                                {auditoria.acao === "CREATE" || auditoria.acao === "UPDATE" ? (
                                                    <>
                                                        <Col sm="12">
                                                            {utils.retornaTextoAcao(auditoria.acao)} a venda com os
                                                            seguintes valores:
                                                        </Col>
                                                        <Col sm="12">
                                                            <strong>Estado da venda:</strong>{" "}
                                                            {utils.retornaTextoEstadoVenda(dado.estadoVenda)}
                                                        </Col>
                                                        <Col sm="12">
                                                            <strong>Markup médio produtos:</strong>{" "}
                                                            {markupMedioProdutos} %
                                                        </Col>
                                                        <Col sm="12">
                                                            <strong>Markup médio serviços:</strong>{" "}
                                                            {markupMedioServicos} %
                                                        </Col>
                                                        <Col sm="12">
                                                            <strong>Nota fiscal:</strong> {dado.codigoNota}
                                                        </Col>
                                                        <Col sm="12">
                                                            <strong>Parcelamentos:</strong> {dado.formaParcelamento}
                                                        </Col>
                                                        <Col sm="12">
                                                            <strong>Valor da venda:</strong>{" "}
                                                            {utils.retornaValorMonetario(
                                                                valorVendaProdutos + valorVendaServicos,
                                                                "BRL"
                                                            )}
                                                        </Col>
                                                    </>
                                                ) : undefined}
                                            </Row>
                                        ),
                                    };
                                })}
                            />
                        )}
                    </InfiniteScroll>
                </Row>
            </ModalBase.Body>
            <ModalBase.Footer>
                <Row>
                    <Col>
                        <Button variant="outline-secondary" className="rounded-pill" onClick={rest.onHide}>
                            Fechar
                        </Button>
                    </Col>
                </Row>
            </ModalBase.Footer>
        </ModalBase.Root>
    );
}
