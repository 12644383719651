import { useCallback, useContext, useEffect, useState } from "react";
import { tEstadoVenda } from "../interfaces";
import { tPossivelMes } from "../components/filtros/FiltroMes";
import { useSearchParams } from "react-router-dom";
import { ContextUser } from "../contexts/ContextUser";

export type tFiltro =
    | "ano"
    | "mesInicial"
    | "mesFinal"
    | "categoria"
    | "comissaoPaga"
    | "estadoVenda"
    | "segmento"
    | "tipoProdServ"
    | "vendedor";

export interface useFiltrosProps {
    ano?: number;
    mesInicial?: tPossivelMes;
    mesFinal?: tPossivelMes;
    categoriaId?: string;
    comissaoPaga?: boolean;
    estadoVenda?: tEstadoVenda;
    segmentoId?: string;
    tipoProdServId?: string;
    vendedorId?: string;
}

//TODO: Ver a necessidade do getFilter já retornar os valores desejados pela aplicação ao inves de string pura;
//Ex: estadoVenda deve ser um tEstadoVenda ou undefined.
//Também deve se remover o codigo retornaDatasPorFiltroMesAno das utils e deixar somente o datasPorMesesAno
export default function useFiltros(contextInitialValues?: "dashboard" | "comissoes" | "vendas" | "visitas") {
    //ESTADOS
    const [inicializado, setInicializado] = useState(false);

    //HOOKS
    const { usuario, podeVisualizarVisita, podeVisualizarComissao, podeVisualizarVenda } = useContext(ContextUser);
    const [searchParams, setSearchParams] = useSearchParams();

    //VARIABLES
    const vendedorId = usuario?.vendedorUser?.id;

    const getFilter = useCallback(
        (type: tFiltro) => {
            return searchParams.get(type);
        },
        [searchParams]
    );

    const removeFilter = useCallback(
        (type: tFiltro) => {
            const currentParams = searchParams;
            currentParams.delete(type);
            setSearchParams(currentParams);
        },
        [setSearchParams, searchParams]
    );

    const setFilter = useCallback(
        (type: tFiltro, value: string) => {
            const currentParams = searchParams;
            removeFilter(type);
            currentParams.append(type, value);
            setSearchParams(currentParams, { replace: true });
        },
        [setSearchParams, searchParams, removeFilter]
    );

    //EVENTOS
    useEffect(() => {
        if (!contextInitialValues || inicializado) return;
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear().toString();

        const paramsToSet = (useVendedor?: boolean): Partial<Record<tFiltro, string>> => {
            const params = { ano: anoAtual, mesInicial: "0", mesFinal: "11" };
            if (useVendedor) return { ...params, vendedor: vendedorId };
            return params;
        };

        setSearchParams(paramsToSet());
        setInicializado(true);

        if (podeVisualizarComissao({})) return;
        
        switch (contextInitialValues) {
            case "comissoes":
                if (podeVisualizarComissao({ vendedorId })) return setSearchParams(paramsToSet(true));
                return;
            case "dashboard":
                if (podeVisualizarVenda({ vendedorId })) return setSearchParams(paramsToSet(true));
                return;
            case "vendas":
                if (podeVisualizarVenda({ vendedorId })) return setSearchParams(paramsToSet(true));
                return;
            case "visitas":
                if (podeVisualizarVisita({ vendedorId })) return setSearchParams(paramsToSet(true));
                return;
        }
    }, [
        contextInitialValues,
        setSearchParams,
        vendedorId,
        podeVisualizarComissao,
        podeVisualizarVenda,
        podeVisualizarVisita,
        inicializado,
    ]);

    return { setFilter, getFilter, removeFilter };
}
