import { Stack, Tab, Tabs } from "react-bootstrap";
import { LayoutInfo } from "../layouts";
import { InputTexto, MetasVendedor } from "../components";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { tVendedor } from "../interfaces";
import { ContextAlerta } from "../contexts/ContextAlert";
import { tNovoVendedor, yupEsquemaVendedor } from "../components/forms/FormVendedor";
import useVendedor from "../hooks/useVendedor";
import { ContextUser } from "../contexts/ContextUser";

export default function Vendedor() {
    //HOOKS
    const { buscarPorId, estaBuscandoPorId, cancelar, buscaComErro, editar } = useVendedor();

    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);
    const { podeEditarVendedor } = useContext(ContextUser);

    //ESTADOS
    const [vendedorComUsuario, setVendedorComUsuario] = useState<tVendedor | null>(null);
    const [dadosVendedor, setDadosVendedor] = useState<tNovoVendedor | null>(null);

    //VARIAVEIS
    const params = useParams();
    const vendedorId = params.id;

    //EVENTOS
    const buscaVendedor = useCallback(
        async (id?: string) => {
            if (id === undefined) return setPerigo("Não foi encontrado o ID do vendedor.");
            try {
                const vendedor = await buscarPorId(id);
                setDadosVendedor({ name: vendedor.user.name, email: vendedor.user.email });
                setVendedorComUsuario(vendedor);
            } catch (err) {
                console.error(err);
            }
        },
        [buscarPorId, setPerigo]
    );

    useEffect(() => {
        buscaVendedor(vendedorId);
        return () => cancelar();
    }, [vendedorId, buscaVendedor, cancelar]);

    const handleOnCancelEdit = useCallback(() => cancelar(), [cancelar]);
    const handleOnEdit = useCallback(
        async (vendedorAtualizado: tNovoVendedor) => {
            if (!vendedorId) return setPerigo("Não está sendo passado o ID do vendedor.");

            try {
                await yupEsquemaVendedor.validate(vendedorAtualizado);
                await editar({ id: vendedorId, ...vendedorAtualizado });
                buscaVendedor(vendedorId);
            } catch (err) {
                throw err;
            }
        },
        [buscaVendedor, vendedorId, editar, setPerigo]
    );

    return (
        <LayoutInfo.Root>
            <LayoutInfo.Header>
                <LayoutInfo.HeaderLeft carregando={estaBuscandoPorId || buscaComErro}>
                    <h5 className="m-0 text-truncate">{dadosVendedor?.name}</h5>
                </LayoutInfo.HeaderLeft>
                <LayoutInfo.HeaderRight />
            </LayoutInfo.Header>
            <LayoutInfo.Body>
                <LayoutInfo.BodyLeft carregando={estaBuscandoPorId || buscaComErro}>
                    {dadosVendedor && (
                        <Stack className="d-flex w-100 mt-3">
                            <span className="text-secondary-dark mb-2">Informações do vendedor</span>
                            <span className="d-flex w-100 align-items-center gap-3 fw-bold lh-1 mb-3" title="Nome">
                                <i className="bi bi-person-fill" />
                                <InputTexto
                                    cannotEdit={!podeEditarVendedor({})}
                                    valor={dadosVendedor.name}
                                    setValor={(name) => handleOnEdit({ ...dadosVendedor, name: name })}
                                    onCancel={handleOnCancelEdit}
                                />
                            </span>
                            <span className="d-flex w-100 align-items-center gap-3 fw-bold lh-1 mb-3" title="E-mail">
                                <i className="bi bi-envelope-at-fill" />
                                <InputTexto
                                    cannotEdit={!podeEditarVendedor({})}
                                    valor={dadosVendedor.email}
                                    setValor={(email) => handleOnEdit({ ...dadosVendedor, email })}
                                />
                            </span>
                        </Stack>
                    )}
                </LayoutInfo.BodyLeft>
                <LayoutInfo.BodyRight carregando={estaBuscandoPorId || buscaComErro}>
                    <div className="w-100 h-100 pt-2">
                        <Tabs defaultActiveKey={"metas"} id={"tabs-vendedor-info"}>
                            <Tab eventKey={"metas"} title={"Metas"} className="h-100">
                                <MetasVendedor vendedor={vendedorComUsuario} />
                            </Tab>
                        </Tabs>
                    </div>
                </LayoutInfo.BodyRight>
            </LayoutInfo.Body>
        </LayoutInfo.Root>
    );
}
