import { Button, Col, Container, Form, ProgressBar, Row, Stack } from "react-bootstrap";
import { tMetaComValoresAtingidos, tTipoMetaAlternativa, tVendedor } from "../../interfaces";
import utils from "../../utils";
import BotaoAdicionarDado from "../botoes/BotaoAdicionarDado";
import { useCallback, useContext, useEffect, useState } from "react";
import OffcanvasMeta from "../offcanvas/OffcanvasMeta";
import useMeta, { LIMITE_POR_BUSCA_META } from "../../hooks/useMeta";
import InfiniteScroll from "../infiniteScroll/InfiniteScroll";
import { ContextUser } from "../../contexts/ContextUser";
import "./styles.css";

export type tTipoMeta = "FATURAMENTO" | "VENDA" | tTipoMetaAlternativa;
const tipoMetasSelecionaveis: tTipoMeta[] = ["FATURAMENTO", "VENDA", "VISITA"];

interface IMetasVendedorProps {
    vendedor?: tVendedor | null;
}

export default function MetasVendedor({ vendedor }: IMetasVendedorProps) {
    //CONTEXTOS
    const { podeAdicionarMeta, podeEditarMeta, podeDeletarMeta } = useContext(ContextUser);

    //HOOKS
    const { buscar, buscaComErro, estaBuscando, cancelar } = useMeta();

    //ESTADOS
    const [tipoMetaSelecionada, setTipoMetaSelecionada] = useState<tTipoMeta>("FATURAMENTO");
    const [metas, setMetas] = useState<tMetaComValoresAtingidos[]>([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);

    const [mostrarOffcanvasAdd, setMostrarOffcanvasAdd] = useState(false);

    const [metaEditando, setMetaEditando] = useState<tMetaComValoresAtingidos | null>(null);
    const [metaDeletando, setMetaDeletando] = useState<tMetaComValoresAtingidos | null>(null);

    //VARIAVEIS
    const returnInfoMeta = useCallback(
        (meta: tMetaComValoresAtingidos) => {
            switch (tipoMetaSelecionada) {
                case "FATURAMENTO": {
                    const nowMeta = (meta.valorFaturamentoAtingido * 100) / meta.valorFaturamento;
                    return {
                        nowMeta,
                        labelMeta: `${nowMeta.toFixed(2)}%`,
                        valorMeta: meta.valorFaturamento,
                        valorMetaAtingido: meta.valorFaturamentoAtingido,
                        blMetaMonetaria: true,
                    };
                }
                case "VENDA": {
                    const nowMeta = (meta.valorVendaAtingido * 100) / meta.valorVenda;
                    return {
                        nowMeta,
                        labelMeta: `${nowMeta.toFixed(2)}%`,
                        valorMeta: meta.valorVenda,
                        valorMetaAtingido: meta.valorVendaAtingido,
                        blMetaMonetaria: true,
                    };
                }
                case "VISITA": {
                    const metaVisita = meta.metasAlternativas.find((ma) => ma.tipoMeta === "VISITA");
                    const nowMeta = metaVisita ? (metaVisita.valorAtingido * 100) / metaVisita.valor : 0;
                    return {
                        nowMeta,
                        labelMeta: `${nowMeta.toFixed(2)}%`,
                        valorMeta: metaVisita?.valor ?? 0,
                        valorMetaAtingido: metaVisita?.valorAtingido ?? 0,
                        blMetaMonetaria: false,
                    };
                }
            }
        },
        [tipoMetaSelecionada]
    );

    //EVENTOS
    const buscarMetas = useCallback(
        async (vendedorId?: string, pagina?: number) => {
            try {
                const novasMetas = await buscar({ limit: LIMITE_POR_BUSCA_META, page: pagina, where: { vendedorId } });
                if (pagina === undefined) {
                    setMetas(novasMetas);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setMetas((atuais) => [...atuais, ...novasMetas]);
                return novasMetas.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [buscar]
    );

    useEffect(() => {
        if (vendedor === null) return;

        buscarMetas(vendedor?.id).catch((err) => console.error(err));
        return () => cancelar();
    }, [vendedor, buscarMetas, cancelar]);

    const handleOnClickNovaMeta = useCallback(() => setMostrarOffcanvasAdd(true), []);

    const handleOnClickEditar = useCallback((meta: tMetaComValoresAtingidos) => setMetaEditando(meta), []);

    const handleOnClickDeletar = useCallback((meta: tMetaComValoresAtingidos) => setMetaDeletando(meta), []);

    return (
        <>
            <InfiniteScroll
                paginaAtual={paginaAtual}
                proximaPagina={proximaPagina}
                setPaginaAtual={setPaginaAtual}
                setProximaPagina={setProximaPagina}
                buscando={estaBuscando || buscaComErro}
                buscar={(pagina) => buscarMetas(vendedor?.id, pagina)}
            >
                <Container fluid className="pt-1 pb-3">
                    <Row>
                        <Col sm="12" className="my-col-select-add mb-3 sticky-top bg-white pb-2 pt-2">
                            <Row>
                                <Col sm="6" xl="3">
                                    <Form.Label>Selecione o tipo de meta para visualizar</Form.Label>
                                    <Form.Select
                                        value={tipoMetaSelecionada}
                                        onChange={(e) => setTipoMetaSelecionada(e.target.value as tTipoMeta)}
                                    >
                                        {tipoMetasSelecionaveis.map((tipoMeta) => (
                                            <option key={tipoMeta} value={tipoMeta}>
                                                {tipoMeta}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                {podeAdicionarMeta({}) && (
                                    <Col sm="6" xl="9" className="my-div-button-nova-meta text-end mb-2">
                                        <BotaoAdicionarDado texto="Meta" onClickAdd={handleOnClickNovaMeta} />
                                    </Col>
                                )}
                            </Row>
                        </Col>

                        {metas.length < 1 && !estaBuscando && !buscaComErro && (
                            <Col sm="12" className="text-center text-secondary fs-5 mt-3">
                                Nenhuma meta registrada
                            </Col>
                        )}
                        {metas.map((meta) => {
                            const { nowMeta, labelMeta, valorMeta, valorMetaAtingido, blMetaMonetaria } =
                                returnInfoMeta(meta);

                            return (
                                <Col sm="12" lg="6" key={meta.id} className=" mb-3">
                                    <Stack className="d-flex w-100 gap-3 border rounded shadow bg-white overflow-hidden p-2">
                                        <span>{utils.retornaPeriodoMeta(meta)}</span>
                                        <Col sm="12">
                                            <Stack>
                                                <span>Meta {tipoMetaSelecionada}</span>
                                                <ProgressBar
                                                    className="my-progress-bar-meta"
                                                    variant="info"
                                                    striped
                                                    label={labelMeta}
                                                    now={nowMeta}
                                                />
                                                {blMetaMonetaria ? (
                                                    <small className="text-secondary-dark text-end">
                                                        {utils.retornaValorMonetario(valorMetaAtingido, "BRL")}/
                                                        {utils.retornaValorMonetario(valorMeta, "BRL")}
                                                    </small>
                                                ) : (
                                                    <small className="text-secondary-dark text-end">
                                                        {valorMetaAtingido}/{valorMeta}
                                                    </small>
                                                )}
                                            </Stack>
                                        </Col>
                                        {(podeEditarMeta({}) || podeDeletarMeta({})) && (
                                            <Col sm="12" className="d-flex justify-content-end gap-2">
                                                {podeEditarMeta({}) && (
                                                    <Button
                                                        size="sm"
                                                        className="rounded-pill"
                                                        variant="outline-primary"
                                                        onClick={() => handleOnClickEditar(meta)}
                                                    >
                                                        Editar
                                                    </Button>
                                                )}
                                                {podeDeletarMeta({}) && (
                                                    <Button
                                                        size="sm"
                                                        className="rounded-pill"
                                                        variant="outline-danger"
                                                        onClick={() => handleOnClickDeletar(meta)}
                                                    >
                                                        Deletar
                                                    </Button>
                                                )}
                                            </Col>
                                        )}
                                    </Stack>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </InfiniteScroll>

            {vendedor && (
                <>
                    <OffcanvasMeta
                        tipo="criar"
                        dados={{ vendedorId: vendedor.id }}
                        mostrarForm={mostrarOffcanvasAdd}
                        onCancel={() => {
                            cancelar();
                            setMostrarOffcanvasAdd(false);
                        }}
                        onTerminou={() => buscarMetas(vendedor.id).catch((err) => console.error(err))}
                    />

                    <OffcanvasMeta
                        tipo="editar"
                        mostrarForm={metaEditando !== null}
                        dados={metaEditando}
                        onCancel={() => {
                            cancelar();
                            setMetaEditando(null);
                        }}
                        onTerminou={() => {
                            buscarMetas(vendedor.id).catch((err) => console.error(err));
                            setMetaEditando(null);
                        }}
                    />

                    <OffcanvasMeta
                        tipo="deletar"
                        mostrarForm={metaDeletando !== null}
                        dados={metaDeletando}
                        onCancel={() => {
                            cancelar();
                            setMetaDeletando(null);
                        }}
                        onTerminou={() => {
                            buscarMetas(vendedor.id).catch((err) => console.error(err));
                            setMetaDeletando(null);
                        }}
                    />
                </>
            )}
        </>
    );
}
