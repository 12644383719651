import { useCallback, useContext, useEffect, useState } from "react";
import {
    BotaoAdicionarDado,
    FiltroAnos,
    FiltroEstadosVenda,
    FiltroMes,
    FiltroVendedores,
    OffcanvasVenda,
    TabelaVenda,
} from "../components";
import { LayoutTabela } from "../layouts";
import { tEstadoVenda, tVenda } from "../interfaces";
import { ContextUser } from "../contexts/ContextUser";
import useVenda, { LIMITE_POR_BUSCA_VENDAS } from "../hooks/useVenda";
import { ContextAlerta } from "../contexts/ContextAlert";
import { tNovaVenda } from "../components/forms/FormVenda";
import { tPossivelMes } from "../components/filtros/FiltroMes";
import utils from "../utils";
import useFiltros from "../hooks/useFiltros";

export default function Vendas() {
    //HOOKS
    const { buscar, estaBuscando, cancelar, buscaComErro, editar } = useVenda();
    const { getFilter } = useFiltros("vendas");

    //CONTEXTOS
    const { carregandoUsuario, podeAdicionarVenda, usuario, podeVisualizarVenda } = useContext(ContextUser);
    const { setProcessando } = useContext(ContextAlerta);

    //ESTADOS
    const [vendas, setVendas] = useState<tVenda[]>([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);

    const [vendaEditando, setVendaEditando] = useState<tVenda | null>(null);
    const [vendaDeletando, setVendaDeletando] = useState<tVenda | null>(null);
    const [mostrarOffcanvasAdicionar, setMostrarOffcanvasAdicionar] = useState(false);

    //VARIAVEIS
    const mesInicial = utils.deStringPara<tPossivelMes>(getFilter("mesInicial"), "number");
    const mesFinal = utils.deStringPara<tPossivelMes>(getFilter("mesFinal"), "number");
    const anoSelecionado = utils.deStringPara<number>(getFilter("ano"), "number");
    const estadoVendaSelecionado = (getFilter("estadoVenda") as tEstadoVenda | null) ?? undefined;
    const vendedorIdSelecionado = getFilter("vendedor") ?? undefined;

    //EVENTOS
    const buscarVendas = useCallback(
        async (pagina?: number, pesquisa?: string) => {
            try {
                const { dataFinalSelecionada, dataInicialSelecionada } = utils.retornaDatasFiltroMesAno(
                    { inicio: mesInicial, fim: mesFinal },
                    anoSelecionado
                );

                const where = {
                    vendedorId: vendedorIdSelecionado,
                    estadoVenda: estadoVendaSelecionado,
                    dataInicio: dataInicialSelecionada,
                    dataFim: dataFinalSelecionada,
                    campoPesquisa: pesquisa,
                };
                if (!podeVisualizarVenda(where)) return 0;

                const novasVendas = await buscar({
                    limit: LIMITE_POR_BUSCA_VENDAS,
                    page: pagina,
                    where,
                    sortBy: "dataVenda",
                    sortType: "asc",
                });
                if (pagina === undefined) {
                    setVendas(novasVendas);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setVendas((atuais) => [...atuais, ...novasVendas]);
                return novasVendas.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [
            buscar,
            vendedorIdSelecionado,
            mesInicial,
            mesFinal,
            anoSelecionado,
            estadoVendaSelecionado,
            podeVisualizarVenda,
        ]
    );

    useEffect(() => {
        buscarVendas().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarVendas, vendedorIdSelecionado, mesInicial, mesFinal, anoSelecionado, estadoVendaSelecionado]);

    const handleOnClickAdd = useCallback(() => setMostrarOffcanvasAdicionar(true), []);
    const handleOnClickEdit = useCallback((venda: tVenda) => setVendaEditando(venda), []);
    const handleOnClickDelete = useCallback((venda: tVenda) => setVendaDeletando(venda), []);

    const handleOnPesquisar = useCallback((pesquisa?: string) => buscarVendas(undefined, pesquisa), [buscarVendas]);

    const handleOnMudarEstado = useCallback(
        async (venda: tVenda, novoEstado: tEstadoVenda) => {
            try {
                setProcessando(true);
                const novaVenda: tNovaVenda = utils.retornaNovaVendaDeVenda({ ...venda, estadoVenda: novoEstado });
                await editar({ id: venda.id, ...novaVenda });

                buscarVendas().catch((err) => console.error(err));
            } catch (err) {
                console.error(err);
            } finally {
                setProcessando(false);
            }
        },
        [editar, buscarVendas, setProcessando]
    );

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroEstadosVenda />
                        <FiltroAnos />
                        <FiltroMes />
                        <FiltroVendedores />
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarVenda({ vendedoresId: [usuario?.vendedorUser?.id ?? ""] }) && (
                            <BotaoAdicionarDado texto="Venda" onClickAdd={handleOnClickAdd} />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>

                <LayoutTabela.Body
                    buscando={estaBuscando || carregandoUsuario || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarVendas}
                >
                    <TabelaVenda
                        vendas={vendas}
                        onEdit={handleOnClickEdit}
                        onDelete={handleOnClickDelete}
                        onChangeState={handleOnMudarEstado}
                        buscar={handleOnPesquisar}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasVenda
                tipo="criar"
                mostrarForm={mostrarOffcanvasAdicionar}
                onCancel={() => {
                    setMostrarOffcanvasAdicionar(false);
                    cancelar();
                }}
                onTerminou={() => buscarVendas().catch((err) => console.error(err))}
            />

            <OffcanvasVenda
                tipo="editar"
                mostrarForm={vendaEditando !== null}
                onCancel={() => {
                    setVendaEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVendaEditando(null);
                    buscarVendas().catch((err) => console.error(err));
                }}
                dados={vendaEditando}
            />

            <OffcanvasVenda
                tipo="deletar"
                mostrarForm={vendaDeletando !== null}
                onCancel={() => {
                    setVendaDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVendaDeletando(null);
                    buscarVendas().catch((err) => console.error(err));
                }}
                dados={vendaDeletando}
            />
        </>
    );
}
