import { Placeholder, Table } from "react-bootstrap";
import "./styles.css";

export default function TabelaCarregando() {
    return (
        <Table striped bordered hover className="my-table-data mt-3">
            <thead className="my-sticky-header rounded-3 rounded-top sticky-top bg-white shadow-sm">
                <tr>
                    <th>
                        <Placeholder animation="glow" className="opacity-25">
                            <Placeholder sm="12" className="rounded" size="sm" />
                        </Placeholder>
                    </th>
                    <th>
                        <Placeholder animation="glow" className="opacity-25">
                            <Placeholder sm="12" className="rounded" size="sm" />
                        </Placeholder>
                    </th>
                    <th>
                        <Placeholder animation="glow" className="opacity-25">
                            <Placeholder sm="12" className="rounded" size="sm" />
                        </Placeholder>
                    </th>
                </tr>
            </thead>
            <tbody>
                {[1, 2, 3, 4, 5].map((index) => (
                    <tr key={index}>
                        <td>
                            <Placeholder animation="glow" className="opacity-25">
                                <Placeholder sm="5" className="rounded" size="sm" />
                            </Placeholder>
                        </td>
                        <td>
                            <Placeholder animation="glow" className="opacity-25">
                                <Placeholder sm="5" className="rounded" size="sm" />
                            </Placeholder>
                        </td>
                        <td>
                            <Placeholder animation="glow" className="opacity-25">
                                <Placeholder sm="5" className="rounded" size="sm" />
                            </Placeholder>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
