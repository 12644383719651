import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { v4 } from "uuid";
import InputSenha from "../inputs/InputSenha";

//TYPES
export type tNovaSenha = { password: string; confirmPass: string };

export interface IFormNovaSenhaProps {
    formId?: string;
    onSubmit: (dadosSenha: tNovaSenha, helpersFormik: FormikHelpers<tNovaSenha>) => void | Promise<void>;
    valoresIniciais: tNovaSenha;
}

//FORM
export default function FormNovaSenha(props: IFormNovaSenhaProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (dadosSenha: tNovaSenha, helpers: FormikHelpers<tNovaSenha>) => {
            if (dadosSenha.password !== dadosSenha.confirmPass)
                return helpers.setErrors({ confirmPass: "As senhas não coincidem." });
            try {
                await onSubmit(dadosSenha, helpers);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaMeta}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-1">
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label>Nova senha</Form.Label>
                                <InputSenha
                                    required
                                    valor={values.password}
                                    onAccept={(password) => setValues({ ...values, password })}
                                    isInvalid={!!errors.password}
                                    error={errors.password}
                                />
                            </Form.Group>

                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label>Confirme a senha</Form.Label>
                                <InputSenha
                                    required
                                    valor={values.confirmPass}
                                    onAccept={(confirmPass) => setValues({ ...values, confirmPass })}
                                    isInvalid={!!errors.confirmPass}
                                    error={errors.confirmPass}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaMeta: yup.ObjectSchema<tNovaSenha> = yup.object({
    password: yup
        .string()
        .required("Este campo é necessário.")
        .min(8, "A senha deve conter no mínimo 8 caracteres.")
        .matches(/^(?=.*[a-zA-Z])(?=.*\d).+$/, "Coloque ao menos uma letra e um número"),
    confirmPass: yup.string().required("Este campo é necessário."),
});
