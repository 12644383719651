import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback, useState } from "react";
import { tEstadoVenda } from "../../interfaces";
import utils from "../../utils";
import useFiltros from "../../hooks/useFiltros";

const NENHUM_FILTRO = "Todos os estados";

export default function FiltroEstadosVenda() {
    //HOOKS
    const { setFilter, removeFilter, getFilter } = useFiltros();

    //ESTADOS
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);

    //VARIAVEIS
    const estados = utils.retornaArrayEstadoVenda();
    const estadoSelecionado = getFilter("estadoVenda") as tEstadoVenda | null;
    const estadosBuscados =
        pesquisa === undefined
            ? estados
            : estados.filter((estado) =>
                  utils.retornaTextoEstadoVenda(estado).toLowerCase().includes(pesquisa.toLowerCase())
              );

    const retornaTextoFiltro = useCallback(() => {
        if (estadoSelecionado === null) return NENHUM_FILTRO;
        else return utils.retornaTextoEstadoVenda(estadoSelecionado);
    }, [estadoSelecionado]);

    //EVENTOS
    const handleOnSelecionarSegmento = useCallback(
        (estado: tEstadoVenda | null) => {
            if (estado === null) return removeFilter("estadoVenda");
            setFilter("estadoVenda", estado);
        },
        [setFilter, removeFilter]
    );

    return (
        <FiltroSelect texto={retornaTextoFiltro()} onSearch={setPesquisa} titulo="Estado Venda">
            <ListGroup className="small">
                <ListGroup.Item
                    className={`my-item-filtro ${estadoSelecionado === null ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarSegmento(null)}
                >
                    {NENHUM_FILTRO}
                </ListGroup.Item>
                {estadosBuscados.map((estado) => (
                    <ListGroup.Item
                        className={`my-item-filtro ${estadoSelecionado === estado ? "bg-light" : ""}`}
                        key={estado}
                        onClick={() => handleOnSelecionarSegmento(estado)}
                    >
                        <div className="d-flex align-items-center justify-content-between gap-2">
                            {utils.retornaTextoEstadoVenda(estado)}{" "}
                            {estado === "CRIADA" ? (
                                <div className="p-2 border rounded-circle" />
                            ) : estado === "AGUARDANDO_VERIFICACAO" ? (
                                <div className="p-2 bg-warning border border-warning rounded-circle" />
                            ) : (
                                <div className="p-2 bg-success border border-success rounded-circle" />
                            )}
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </FiltroSelect>
    );
}
