import { tEstadoVenda, tVenda } from "../../interfaces";
import { Tabela } from "./Tabela";
import SpanVendaProduto from "../span/SpanVendaProduto";
import SpanVendaImposto from "../span/SpanVendaImposto";
import SpanVendaValor from "../span/SpanVendaValor";
import SpanVendaServico from "../span/SpanVendaServico";
import { useCallback, useContext, useState } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import utils from "../../utils";
import ModalAuditoriaVenda from "../modals/ModalAuditoriaVenda";
import { ContextAlerta } from "../../contexts/ContextAlert";
import SpanVendaVendedores from "../span/SpanVendaVendedores";
import SpanVendaParcelamento from "../span/SpanVendaParcelamento";
import InputSearch from "../inputs/InputSearch";
import { tHeaderTable } from "../dropdowns/DropdownCustomizacaoTabela";
import { returnCustomHeaders } from "./Tabela/TabelaRoot";
import "./styles.css";

const CUSTOMIZATION_NAME = "customizacoes-venda";
const DEFAULT_HEADERS: tHeaderTable[] = [
    { name: "Data Venda (CRM)", show: true, value: "data_venda_crm" },
    { name: "Número CRM", show: true, value: "numero_crm" },
    { name: "Número Pedido", show: true, value: "numero_pedido" },
    { name: "Nota Fiscal", show: true, value: "nota_fiscal" },
    { name: "Vendedores", show: true, value: "vendedores" },
    { name: "Cliente", show: true, value: "cliente" },
    { name: "Produtos", show: true, value: "produtos" },
    { name: "Serviços", show: true, value: "servicos" },
    { name: "Impostos", show: true, value: "impostos" },
    { name: "Parcelamento", show: true, value: "parcelamento" },
    { name: "Valores", show: true, value: "valores" },
    { name: "Tipo Venda", show: true, value: "tipo_venda" },
    { name: "Contrato", show: true, value: "contrato" },
    { name: "Data Faturamento", show: true, value: "data_faturamento" },
];

interface ITabelaVendaProps {
    vendas: tVenda[];
    onEdit: (venda: tVenda) => void;
    onDelete: (venda: tVenda) => void;
    onChangeState: (venda: tVenda, novoEstado: tEstadoVenda) => void;
    buscar?: (pesquisa?: string) => Promise<any> | any;
}

export default function TabelaVenda({ onChangeState, onEdit, onDelete, buscar, ...props }: ITabelaVendaProps) {
    //CONTEXTOS
    const { usuario, podeEditarVenda, podeDeletarVenda, podeVisualizarAuditoria } = useContext(ContextUser);
    const { setAviso } = useContext(ContextAlerta);
    const [customHeaders, setCustomHeaders] = useState<tHeaderTable[]>(
        returnCustomHeaders(CUSTOMIZATION_NAME, DEFAULT_HEADERS)
    );

    //ESTADOS
    const [pesquisa, setPesquisa] = useState<undefined | string>(undefined);
    const [vendaAuditando, setVendaAuditando] = useState<tVenda | null>(null);

    //EVENTOS
    const handleOnChangePesquisa = useCallback(
        async (novaPesquisa?: string) => {
            try {
                setPesquisa(novaPesquisa);
                if (buscar) await buscar(novaPesquisa);
            } catch (err) {
                console.error(err);
            }
        },
        [buscar]
    );

    const handleOnClickOpenAuditoria = useCallback((venda: tVenda) => setVendaAuditando(venda), []);
    const handleOnHideAuditoria = useCallback(() => setVendaAuditando(null), []);

    const handleOnEdit = useCallback(
        (venda: tVenda) => {
            const vendedoresId = venda.vendedores.map(({ id }) => id);
            if (!podeEditarVenda({ vendedoresId, estadoVenda: venda.estadoVenda }))
                return setAviso("Você não possui permissão para realizar esta ação.");
            return onEdit(venda);
        },
        [onEdit, podeEditarVenda, setAviso]
    );
    const handleOnDelete = useCallback(
        (venda: tVenda) => {
            if (!podeDeletarVenda({ vendedorId: usuario?.vendedorUser?.id, ...venda }))
                return setAviso("Você não possui permissão para realizar esta ação.");
            return onDelete(venda);
        },
        [usuario, onDelete, podeDeletarVenda, setAviso]
    );
    const handleOnCustom = useCallback(
        (venda: tVenda) => {
            if (venda.estadoVenda === "CRIADA") return onChangeState(venda, "AGUARDANDO_VERIFICACAO");

            if (!podeEditarVenda({ estadoVenda: "VERIFICADA" }))
                return setAviso("Você não possui permissão para realizar esta ação.");

            if (venda.estadoVenda === "AGUARDANDO_VERIFICACAO") return onChangeState(venda, "VERIFICADA");
            return onChangeState(venda, "AGUARDANDO_VERIFICACAO");
        },
        [onChangeState, podeEditarVenda, setAviso]
    );
    const handleOnAuditoria = useCallback(
        (venda: tVenda) => {
            if (!podeVisualizarAuditoria({ tabela: "VENDA" }))
                return setAviso("Você não possui permissão para realizar esta ação.");
            return handleOnClickOpenAuditoria(venda);
        },
        [podeVisualizarAuditoria, handleOnClickOpenAuditoria, setAviso]
    );

    //TODO: Buscar alguma forma de refatorar a customização de tabelas
    return (
        <Tabela.Root customizationsName={CUSTOMIZATION_NAME} headers={customHeaders} setHeaders={setCustomHeaders}>
            {buscar && (
                <Tabela.ComponentTop>
                    <Col sm="6" md="5" xl="3">
                        <InputSearch
                            placeholder="Núm. CRM, núm. pedido, nota fiscal ou nome cliente"
                            pesquisa={pesquisa}
                            onChange={handleOnChangePesquisa}
                        />
                    </Col>
                </Tabela.ComponentTop>
            )}

            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        {customHeaders
                            .filter((header) => header.show)
                            .map((header) => (
                                <th key={header.value}>{header.name}</th>
                            ))}
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.vendas}
                    campoUnico="id"
                    onEdit={handleOnEdit}
                    onDelete={handleOnDelete}
                    onClickAuditoria={handleOnAuditoria}
                    onClickCustomButton={handleOnCustom}
                    customButton={useCustomButton}
                    disabledCustom={(venda: tVenda) =>
                        !podeEditarVenda({
                            estadoVenda: venda.estadoVenda,
                            vendedoresId: [usuario?.vendedorUser?.id ?? ""],
                        })
                    }
                    disabledDelete={(venda: tVenda) =>
                        !podeDeletarVenda({
                            estadoVenda: venda.estadoVenda,
                            vendedorId: usuario?.vendedorUser?.id ?? "",
                        })
                    }
                    disabledEdit={(venda: tVenda) =>
                        !podeEditarVenda({
                            estadoVenda: venda.estadoVenda,
                            vendedoresId: [usuario?.vendedorUser?.id ?? ""],
                        })
                    }
                >
                    {(venda: tVenda) => {
                        const variantEstadoVenda =
                            venda.estadoVenda === "CRIADA"
                                ? "transparent"
                                : venda.estadoVenda === "VERIFICADA"
                                ? "success"
                                : "warning";

                        return (
                            <>
                                {customHeaders
                                    .filter((header) => header.show)
                                    .map((header, index) => {
                                        return (
                                            <td className="position-relative" key={header.value}>
                                                {index === 0 && (
                                                    <OverlayTrigger
                                                        placement="bottom-start"
                                                        overlay={
                                                            <Tooltip>
                                                                {utils.retornaTextoEstadoVenda(venda.estadoVenda)}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div
                                                            className={`d-flex position-absolute h-100 start-0 top-0 p-1 pe-0 bg-${variantEstadoVenda}`}
                                                        />
                                                    </OverlayTrigger>
                                                )}
                                                {header.value === DEFAULT_HEADERS[0].value ? (
                                                    venda.dataVenda.toLocaleDateString("pt-Br", {
                                                        day: "2-digit",
                                                        month: "2-digit",
                                                        year: "numeric",
                                                    })
                                                ) : header.value === DEFAULT_HEADERS[1].value ? (
                                                    venda.numOportunidadeCRM
                                                ) : header.value === DEFAULT_HEADERS[2].value ? (
                                                    venda.numPedidoDistribuidor
                                                ) : header.value === DEFAULT_HEADERS[3].value ? (
                                                    venda.codigoNota ? (
                                                        venda.codigoNota
                                                    ) : (
                                                        "-- Aguardando --"
                                                    )
                                                ) : header.value === DEFAULT_HEADERS[4].value ? (
                                                    <SpanVendaVendedores venda={venda} />
                                                ) : header.value === DEFAULT_HEADERS[5].value ? (
                                                    venda.cliente.name
                                                ) : header.value === DEFAULT_HEADERS[6].value ? (
                                                    <SpanVendaProduto produtos={venda.vendasProduto} />
                                                ) : header.value === DEFAULT_HEADERS[7].value ? (
                                                    <SpanVendaServico servicos={venda.vendasServico} />
                                                ) : header.value === DEFAULT_HEADERS[8].value ? (
                                                    <SpanVendaImposto venda={venda} />
                                                ) : header.value === DEFAULT_HEADERS[9].value ? (
                                                    <SpanVendaParcelamento venda={venda} />
                                                ) : header.value === DEFAULT_HEADERS[10].value ? (
                                                    <SpanVendaValor venda={venda} />
                                                ) : header.value === DEFAULT_HEADERS[11].value ? (
                                                    `${venda.vendaRenovacao ? "Renovação" : "Nova Venda"}`
                                                ) : header.value === DEFAULT_HEADERS[12].value ? (
                                                    `${venda.contrato?.name ?? "--- NENHUM ---"}`
                                                ) : (
                                                    venda.dataFaturamento.toLocaleDateString("pt-Br", {
                                                        day: "2-digit",
                                                        month: "2-digit",
                                                        year: "numeric",
                                                    })
                                                )}
                                            </td>
                                        );
                                    })}
                            </>
                        );
                    }}
                </Tabela.Body>
            </Tabela.Table>

            <ModalAuditoriaVenda venda={vendaAuditando} show={vendaAuditando !== null} onHide={handleOnHideAuditoria} />
        </Tabela.Root>
    );
}

function useCustomButton(props: tVenda) {
    const tooltip =
        props.estadoVenda === "CRIADA"
            ? "Enviar para verificação"
            : props.estadoVenda === "AGUARDANDO_VERIFICACAO"
            ? "Marcar como verificada"
            : "Retroceder para verificação";

    const children =
        props.estadoVenda === "AGUARDANDO_VERIFICACAO" ? (
            <i className="bi bi-check-square text-secondary-dark" />
        ) : (
            <i className="bi bi-clock text-secondary-dark" />
        );
    return { tooltip, children };
}
