import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback, useEffect, useState } from "react";
import { tCategoria } from "../../interfaces";
import useCategoria from "../../hooks/useCategoria";
import useFiltros from "../../hooks/useFiltros";

const NENHUM_FILTRO = "Todas as categorias";

export default function FiltroCategorias() {
    //HOOKS
    const { buscar, buscaComErro, cancelar, estaBuscando } = useCategoria();
    const { setFilter, removeFilter, getFilter } = useFiltros();

    //ESTADOS
    const [categorias, setCategorias] = useState<tCategoria[]>([]);
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);

    //VARIAVEIS
    const categoriaIdSlecionada = getFilter("categoria");
    const categoriaSelecionada = categorias.find((c) => c.id === categoriaIdSlecionada);

    const categoriasBuscadas =
        pesquisa === undefined
            ? categorias
            : categorias.filter((categoria) => categoria.name.toLowerCase().includes(pesquisa.toLowerCase()));

    const retornaTextoFiltro = useCallback(() => {
        if (!categoriaSelecionada) return NENHUM_FILTRO;
        else return categoriaSelecionada.name;
    }, [categoriaSelecionada]);

    //EVENTOS
    const buscaCategorias = useCallback(async () => {
        try {
            const categorias = await buscar();
            setCategorias(categorias);
        } catch (err) {
            console.error(err);
        }
    }, [buscar]);

    useEffect(() => {
        buscaCategorias();
        return () => cancelar();
    }, [buscaCategorias, cancelar]);

    const handleOnOpenFiltros = useCallback(() => {
        if (buscaComErro) buscaCategorias();
    }, [buscaComErro, buscaCategorias]);

    const handleOnSelecionarCategoria = useCallback(
        (categoria: tCategoria | null) => {
            if (categoria === null) removeFilter("categoria");
            else setFilter("categoria", categoria.id);
        },
        [setFilter, removeFilter]
    );

    return (
        <FiltroSelect
            texto={retornaTextoFiltro()}
            onSearch={setPesquisa}
            carregando={estaBuscando || buscaComErro}
            onOpen={handleOnOpenFiltros}
            titulo="Categoria"
        >
            <ListGroup className="small">
                <ListGroup.Item
                    className={`my-item-filtro ${categoriaSelecionada === null ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarCategoria(null)}
                >
                    {NENHUM_FILTRO}
                </ListGroup.Item>
                {categoriasBuscadas.map((categoria) => (
                    <ListGroup.Item
                        className={`my-item-filtro ${categoriaSelecionada?.id === categoria.id ? "bg-light" : ""}`}
                        key={categoria.id}
                        onClick={() => handleOnSelecionarCategoria(categoria)}
                    >
                        {categoria.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </FiltroSelect>
    );
}
