import { useCallback } from "react";
import { PartialEntity, tDistribuicaoComissao } from "../../interfaces";
import { OffcanvasNovoDado } from "./OffcanvasNovoDado";
import { OffcanvasEditarDado } from "./OffcanvasEditarDado.tsx";
import { OffcanvasDeletarDado } from "./OffcanvasDeletarDado.tsx";
import useDistribuicaoComissao from "../../hooks/useDistribuicaoComissao";
import FormDistribuicaoComissao, { tNovaDistribuicaoComissao } from "../forms/FormDistribuicaoComissao";

export type IOffcanvasDistribuicaoComissaoProps = {
    mostrarForm: boolean;
    onTerminou?: () => void;
    onCancel?: () => void;
} & (
    | { tipo: "criar"; dados?: null }
    | { tipo: "editar"; dados: null | PartialEntity<tDistribuicaoComissao, "id" | "vendedoresComissao"> }
    | { tipo: "deletar"; dados: null | tDistribuicaoComissao }
);

const FORM = "form-distribuicao-comissao";

export default function OffcanvasDistribuicaoComissao(props: IOffcanvasDistribuicaoComissaoProps) {
    //HOOKS
    const { criar, editar, deletar, cancelar, estaCriando, estaEditando, estaDeletando } = useDistribuicaoComissao();

    //VARAIVEIS
    const { onTerminou, onCancel, dados, tipo } = props;

    //EVENTS
    const handleOnCancel = useCallback(() => {
        cancelar();
        if (onCancel) onCancel();
    }, [onCancel, cancelar]);

    
    const handleOnCriar = useCallback(
        async (distribuicao: tNovaDistribuicaoComissao) => {
            try {
                await criar([distribuicao]);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [criar, onTerminou]
    );

    const handleOnEditar = useCallback(
        async (distribuicao: tNovaDistribuicaoComissao) => {
            if(!dados) return;
            try {
                await editar({ ...distribuicao, id: dados?.id });
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [editar, onTerminou, dados]
    );

    const handleOnDeletar = useCallback(
        async (id: string) => {
            try {
                await deletar(id);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [deletar, onTerminou]
    );

    return tipo === "criar" ? (
        <OffcanvasNovoDado.Root show={props.mostrarForm}>
            <OffcanvasNovoDado.Header>
                <h4>Nova Distribuição</h4>
            </OffcanvasNovoDado.Header>
            <OffcanvasNovoDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ carregando: estaCriando, type: "submit", form: FORM, children: "Confirmar" }}
            >
                <FormDistribuicaoComissao
                    formId={FORM}
                    valoresIniciais={{ vendedoresComissao: [] }}
                    onSubmit={handleOnCriar}
                />
            </OffcanvasNovoDado.Body>
        </OffcanvasNovoDado.Root>
    ) : tipo === "editar" ? (
        <OffcanvasEditarDado.Root show={props.mostrarForm}>
            <OffcanvasEditarDado.Header>
                <h4>Editar Distribuição</h4>
            </OffcanvasEditarDado.Header>
            <OffcanvasEditarDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ form: FORM, carregando: estaEditando, type: "submit" }}
            >
                {dados && (
                    <FormDistribuicaoComissao
                        formId={FORM}
                        valoresIniciais={{
                            vendedoresComissao: dados.vendedoresComissao.map(({ vendedorId, comissao }) => ({
                                vendedorId,
                                comissao,
                            })),
                        }}
                        onSubmit={handleOnEditar}
                    />
                )}
            </OffcanvasEditarDado.Body>
        </OffcanvasEditarDado.Root>
    ) : tipo === "deletar" ? (
        <OffcanvasDeletarDado.Root show={props.mostrarForm}>
            <OffcanvasDeletarDado.Header>
                <h4>Deletar Distribuição</h4>
            </OffcanvasDeletarDado.Header>
            {dados && (
                <OffcanvasDeletarDado.Body
                    acceptButtonProps={{ onClick: () => handleOnDeletar(dados.id), carregando: estaDeletando }}
                    cancelButtonProps={{ onClick: handleOnCancel }}
                >
                    <span>
                        Você está prestes a deletar a distribuição dos vendedores{" "}
                        {dados.vendedoresComissao
                            .map((vendedorComissao) => vendedorComissao.vendedor.user.name)
                            .join(", ")}
                        . Se deseja prosseguir clique no botão <label className="text-danger">Deletar</label> abaixo.
                    </span>
                </OffcanvasDeletarDado.Body>
            )}
        </OffcanvasDeletarDado.Root>
    ) : undefined;
}
