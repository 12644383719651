import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tVendedorComissao } from "../../interfaces";
import SelectVendedor, { ISelectVendedorProps } from "../selects/SelectVendedor";
import InputPorcentagem from "../inputs/InputPorcentagem";
import { v4 } from "uuid";

//TYPES
export type tNovoVendedorComissao = PartialEntity<tVendedorComissao, "vendedorId" | "comissao">;

export interface IFormVendedorComissaoProps extends ISelectVendedorProps {
    formId?: string;
    onSubmit: (vendedorComissao: tNovoVendedorComissao) => void | Promise<void>;
    valoresIniciais: tNovoVendedorComissao;
}

//FORM
export default function FormVendedorComissao({
    formId,
    onSubmit,
    valoresIniciais,
    ...rest
}: IFormVendedorComissaoProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const onSubmitFormik = useCallback(
        async (vendedorComissao: tNovoVendedorComissao, helpers: FormikHelpers<tNovoVendedorComissao>) => {
            try {
                await onSubmit(vendedorComissao);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaVendedorComissao}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row>
                            <Col sm="12" className="pe-4 text-end mb-2">
                                <Form.Label className="mb-1">Vendedor</Form.Label>
                                <SelectVendedor
                                    {...rest}
                                    idSelecionado={values.vendedorId}
                                    setIdSelecionado={(id) => setValues({ ...values, vendedorId: id ?? "" })}
                                />
                                <Form.Control isInvalid={!!errors.vendedorId} className="d-none" />
                                <Form.Control.Feedback type="invalid">{errors.vendedorId}</Form.Control.Feedback>
                            </Col>

                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Comissão</Form.Label>
                                <InputPorcentagem
                                    required
                                    valor={values.comissao}
                                    onAccept={(comissao) => setValues({ ...values, comissao })}
                                    isInvalid={!!errors.comissao}
                                />
                                <Form.Control.Feedback type="invalid">{errors.comissao}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaVendedorComissao: yup.ObjectSchema<tNovoVendedorComissao> = yup.object({
    vendedorId: yup.string().required("Selecione um vendedor."),
    comissao: yup.number().required("Este campo é necessário."),
});
