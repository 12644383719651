import { useCallback } from "react";
import { PartialEntity, tUser } from "../../interfaces";
import { OffcanvasNovoDado } from "./OffcanvasNovoDado";
import { OffcanvasEditarDado } from "./OffcanvasEditarDado.tsx";
import { OffcanvasDeletarDado } from "./OffcanvasDeletarDado.tsx";
import FormAdmin, { tNovoUser } from "../forms/FormAdmin";
import useUser from "../../hooks/useUser";

export type IOffcanvasAdminProps = {
    mostrarForm: boolean;
    onTerminou?: () => void;
    onCancel?: () => void;
} & (
    | { tipo: "criar"; dados?: null }
    | { tipo: "editar"; dados: null | (tNovoUser & PartialEntity<tUser, "id">) }
    | { tipo: "deletar"; dados: null | tUser }
);

const FORM = "form-admin";

export default function OffcanvasAdmin(props: IOffcanvasAdminProps) {
    //HOOKS
    const { criar, editar, deletar, estaCriando, estaEditando, estaDeletando, cancelar } = useUser();

    //VARAIVEIS
    const { onTerminou, onCancel, dados, tipo } = props;

    //EVENTS
    const handleOnCancel = useCallback(() => {
        cancelar();
        if (onCancel) onCancel();
    }, [onCancel, cancelar]);

    const handleOnCriar = useCallback(
        async (user: tNovoUser) => {
            try {
                await criar([user]);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [criar, onTerminou]
    );

    const handleOnEditar = useCallback(
        async (user: tNovoUser & PartialEntity<tUser, "id" | "bloqueado">) => {
            try {
                await editar(user);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [editar, onTerminou]
    );

    const handleOnDeletar = useCallback(
        async (id: string) => {
            try {
                await deletar(id);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [deletar, onTerminou]
    );

    return props.tipo === "criar" ? (
        <OffcanvasNovoDado.Root show={props.mostrarForm}>
            <OffcanvasNovoDado.Header>
                <h4>Novo Adminitrador</h4>
            </OffcanvasNovoDado.Header>
            <OffcanvasNovoDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ carregando: estaCriando, type: "submit", form: FORM }}
            >
                <FormAdmin
                    formId={FORM}
                    valoresIniciais={{
                        name: "",
                        email: "",
                        role: "ADMIN",
                        bloqueado: false,
                    }}
                    onSubmit={handleOnCriar}
                />
            </OffcanvasNovoDado.Body>
        </OffcanvasNovoDado.Root>
    ) : tipo === "editar" ? (
        <OffcanvasEditarDado.Root show={props.mostrarForm}>
            <OffcanvasEditarDado.Header>
                <h4>Editar Administrador</h4>
            </OffcanvasEditarDado.Header>
            <OffcanvasEditarDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ form: FORM, carregando: estaEditando, type: "submit" }}
            >
                {dados && (
                    <FormAdmin
                        formId={FORM}
                        valoresIniciais={{
                            name: dados.name,
                            email: dados.email,
                            role: dados.role,
                            bloqueado: dados.bloqueado,
                        }}
                        onSubmit={(user) => handleOnEditar({ id: dados.id, ...user })}
                    />
                )}
            </OffcanvasEditarDado.Body>
        </OffcanvasEditarDado.Root>
    ) : tipo === "deletar" ? (
        <OffcanvasDeletarDado.Root show={props.mostrarForm}>
            <OffcanvasDeletarDado.Header>
                <h4>Deletar Adminitrador</h4>
            </OffcanvasDeletarDado.Header>
            {dados && (
                <OffcanvasDeletarDado.Body
                    acceptButtonProps={{ onClick: () => handleOnDeletar(dados.id), carregando: estaDeletando }}
                    cancelButtonProps={{ onClick: handleOnCancel }}
                >
                    <p>
                        Você está prestes a deletar permanentemente do sistema o administrador de nome{" "}
                        <strong>{dados.name}</strong>. Clique no botão <strong className="text-danger">Deletar</strong>{" "}
                        logo abaixo para prosseguir.
                    </p>
                </OffcanvasDeletarDado.Body>
            )}
        </OffcanvasDeletarDado.Root>
    ) : undefined;
}
