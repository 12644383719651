import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import { PartialEntity, tFabricante } from "../../interfaces";
import * as yup from "yup";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import { v4 } from "uuid";

//TYPES
export type tNovoFabricante = PartialEntity<tFabricante, "name">;

export interface IFormFabricanteProps {
    formId?: string;
    onSubmit: (fabricante: tNovoFabricante) => void | Promise<void>;
    valoresIniciais: tNovoFabricante;
}

//FORM
export default function FormFabricante(props: IFormFabricanteProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    const onSubmitFormik = useCallback(
        async (fabricante: tNovoFabricante, helpers: FormikHelpers<tNovoFabricante>) => {
            try {
                await onSubmit(fabricante);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaFabricante}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Nome do fabricante</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.name}
                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaFabricante: yup.ObjectSchema<tNovoFabricante> = yup
    .object({
        name: yup.string().required("Campo necessário.").max(256, "Deve ter no máximo 256 carecteres."),
    })
    .strict();
