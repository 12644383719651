import {
    Col,
    Dropdown,
    ListGroup,
    Offcanvas,
    OffcanvasProps,
    Overlay,
    OverlayTrigger,
    Placeholder,
    Popover,
    Row,
    Spinner,
    Stack,
    Tooltip,
} from "react-bootstrap";
import BotaoFechar from "../botoes/BotaoFechar";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import apiLocal from "../../services/api";
import { tUser } from "../../interfaces";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import Botao from "../botoes/Botao";
import useUser from "../../hooks/useUser";
import OffcanvasVendedor from "./OffcanvasVendedor";
import "./styles.css";
import OffcanvasAdmin from "./OffcanvasAdmin";
import OffcanvasGerente from "./OffcanvasGerenteVendas";

interface IOffcanvasUsuarioProps extends OffcanvasProps {
    onHide: () => void;
}

export default function OffcanvasUsuario({ onHide, show, ...rest }: IOffcanvasUsuarioProps) {
    //HOOKS
    const { estaBuscando, buscar, cancelar, buscaComErro } = useUser();

    //ESTADOS
    const [users, setUsers] = useState<tUser[]>([]);
    const [showOffcanvasAddVendedor, setShowOffcanvasAddVendedor] = useState(false);
    const [showOffcanvasAddAdministrador, setShowOffcanvasAddAdministrador] = useState(false);
    const [showOffcanvasAddGerente, setShowOffcanvasAddGerente] = useState(false);

    const [usuarioEditando, setUsuarioEditando] = useState<tUser | null>(null);
    const [usuarioDeletando, setUsuarioDeletando] = useState<tUser | null>(null);

    //EVENTOS
    const buscarUsers = useCallback(() => {
        buscar()
            .then((users) => setUsers(users))
            .catch((err) => console.error(err));
    }, [buscar]);

    useEffect(() => {
        if (!show) return;
        buscarUsers();
        return () => cancelar();
    }, [buscarUsers, show, cancelar]);

    const handleOnClickAddGerente = useCallback(() => setShowOffcanvasAddGerente(true), []);
    const handleOnClickAddVendedor = useCallback(() => setShowOffcanvasAddVendedor(true), []);
    const handleOnClickAddAdministrador = useCallback(() => setShowOffcanvasAddAdministrador(true), []);

    return (
        <>
            <Offcanvas show={show} {...rest}>
                <Offcanvas.Header className="border-bottom bg-light bg-opacity-25">
                    <Row>
                        <Col sm="12" className="position-absolute d-flex w-100 justify-content-end top-0 mt-2">
                            <BotaoFechar darkTheme onClick={onHide} />
                        </Col>
                        <Col sm="12">
                            <h4>Usuarios</h4>
                        </Col>
                    </Row>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        {!estaBuscando && !buscaComErro && (
                            <small className="text-secondary-dark lh-1 mb-4">
                                Clique nos usuários para ver as opções.
                            </small>
                        )}

                        {!estaBuscando && !buscaComErro && users.length < 1 && (
                            <Col sm="12" className="text-center mb-4">
                                <span className="text-secondary-dark fs-5">Nenhum usuário registrado.</span>
                            </Col>
                        )}

                        {estaBuscando || buscaComErro ? (
                            <Col sm="12" className="mb-4">
                                {[1, 2, 3, 4, 5].map((index) => (
                                    <Row key={index}>
                                        <Col sm="12">
                                            <ComponenteUsuario />
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        ) : (
                            users.map((usuario) => (
                                <Col sm="12" key={usuario.id}>
                                    <ComponenteUsuario
                                        usuario={usuario}
                                        atualizaUsuarios={buscarUsers}
                                        onDeleteUser={setUsuarioDeletando}
                                        onEditUser={setUsuarioEditando}
                                    />
                                </Col>
                            ))
                        )}

                        {!estaBuscando && !buscaComErro && (
                            <Col sm="12" className="sticky-bottom text-end mt-2">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        className="rounded-pill"
                                        variant="primary"
                                        id="dropdown-novo-usuario"
                                    >
                                        Novo usuário
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={handleOnClickAddAdministrador}
                                            href="#/adicionar-administrador"
                                        >
                                            Administrador
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={handleOnClickAddGerente}
                                            href="#/adicionar-gerente-vendas"
                                        >
                                            Gerente de Vendas
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={handleOnClickAddVendedor} href="#/adicionar-vendedor">
                                            Vendedor
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        )}
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>

            <OffcanvasVendedor
                tipo="criar"
                mostrarForm={showOffcanvasAddVendedor}
                onCancel={() => {
                    setShowOffcanvasAddVendedor(false);
                    cancelar();
                }}
                onTerminou={buscarUsers}
            />
            <OffcanvasVendedor
                tipo="editar"
                mostrarForm={usuarioEditando !== null && usuarioEditando.role === "VENDEDOR"}
                dados={
                    usuarioEditando && usuarioEditando.vendedorUser
                        ? {
                              ...usuarioEditando.vendedorUser,
                              name: usuarioEditando.name,
                              email: usuarioEditando.email,
                          }
                        : null
                }
                onCancel={() => {
                    setUsuarioEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    buscarUsers();
                    setUsuarioEditando(null);
                }}
            />
            <OffcanvasVendedor
                tipo="deletar"
                mostrarForm={usuarioDeletando !== null && usuarioDeletando.role === "VENDEDOR"}
                dados={
                    usuarioDeletando && usuarioDeletando.vendedorUser
                        ? {
                              ...usuarioDeletando.vendedorUser,
                              user: usuarioDeletando,
                          }
                        : null
                }
                onCancel={() => {
                    setUsuarioDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    buscarUsers();
                    setUsuarioDeletando(null);
                }}
            />

            <OffcanvasAdmin
                tipo="criar"
                mostrarForm={showOffcanvasAddAdministrador}
                onCancel={() => {
                    setShowOffcanvasAddAdministrador(false);
                    cancelar();
                }}
                onTerminou={buscarUsers}
            />
            <OffcanvasAdmin
                tipo="editar"
                mostrarForm={usuarioEditando !== null && usuarioEditando.role === "ADMIN"}
                dados={usuarioEditando}
                onCancel={() => {
                    setUsuarioEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setUsuarioEditando(null);
                    buscarUsers();
                }}
            />
            <OffcanvasAdmin
                tipo="deletar"
                mostrarForm={usuarioDeletando !== null && usuarioDeletando.role === "ADMIN"}
                dados={usuarioDeletando}
                onCancel={() => {
                    setUsuarioDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setUsuarioDeletando(null);
                    buscarUsers();
                }}
            />

            <OffcanvasGerente
                tipo="criar"
                mostrarForm={showOffcanvasAddGerente}
                onCancel={() => {
                    setShowOffcanvasAddGerente(false);
                    cancelar();
                }}
                onTerminou={buscarUsers}
            />
            <OffcanvasGerente
                tipo="editar"
                mostrarForm={usuarioEditando !== null && usuarioEditando.role === "GERENTE_VENDAS"}
                dados={usuarioEditando}
                onCancel={() => {
                    setUsuarioEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setUsuarioEditando(null);
                    buscarUsers();
                }}
            />
            <OffcanvasGerente
                tipo="deletar"
                mostrarForm={usuarioDeletando !== null && usuarioDeletando.role === "GERENTE_VENDAS"}
                dados={usuarioDeletando}
                onCancel={() => {
                    setUsuarioDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setUsuarioDeletando(null);
                    buscarUsers();
                }}
            />
        </>
    );
}

function ComponenteUsuario(props: {
    usuario?: tUser;
    atualizaUsuarios?: () => void | Promise<void>;
    onDeleteUser?: (user: tUser) => void | Promise<void>;
    onEditUser?: (user: tUser) => void | Promise<void>;
}) {
    //CONTEXTOS
    const { setSucesso, setPerigo } = useContext(ContextAlerta);

    //ESTADOS
    const [editandoAcessoPermissao, setEditandoAcessoPermissao] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    //VARIAVEIS
    const { usuario, atualizaUsuarios, onDeleteUser, onEditUser } = props;
    const refDiv = useRef<HTMLDivElement>(null);

    //EVENTOS
    const handleOnHideMenu = useCallback(() => setShowMenu(false), []);
    const handleOnShowMenu = useCallback(() => setShowMenu(true), []);

    const handleOnClickAccessPermission = useCallback(async () => {
        if (!usuario) return;

        setEditandoAcessoPermissao(true);
        try {
            await apiLocal.editarUser({
                id: usuario.id,
                name: usuario.name,
                email: usuario.email,
                role: usuario.role,
                bloqueado: !usuario.bloqueado,
            });
            setSucesso("Permissão de usuário atualizada.");
            if (atualizaUsuarios) atualizaUsuarios();
        } catch (err) {
            console.error(err);
            setPerigo(utils.retornaMensagemErro(err));
        } finally {
            setEditandoAcessoPermissao(false);
            setShowMenu(false);
        }
    }, [usuario, setSucesso, setPerigo, atualizaUsuarios]);

    const handleOnEditUser = useCallback(async () => {
        if (usuario && onEditUser) onEditUser(usuario);
        setShowMenu(false);
    }, [onEditUser, usuario]);

    const handleOnDeleteUser = useCallback(async () => {
        if (usuario && onDeleteUser) onDeleteUser(usuario);
        setShowMenu(false);
    }, [onDeleteUser, usuario]);

    return (
        <>
            <OverlayTrigger
                delay={200}
                flip
                placement="left"
                overlay={
                    <Tooltip className={`${!props.usuario || showMenu ? "d-none" : ""}`}>
                        {props.usuario ? utils.retornaTextoPapelUsuario(props.usuario.role) : undefined}
                    </Tooltip>
                }
            >
                <div
                    className="my-div-root-user-name position-relative d-flex gap-2 align-items-center rounded p-1"
                    role="button"
                    ref={refDiv}
                    onClick={handleOnShowMenu}
                >
                    {props.usuario ? (
                        <div
                            className={`my-div-circle-user-name d-flex align-items-center justify-content-center border rounded-circle ${
                                props.usuario?.role === "ADMIN"
                                    ? "bg-primary border-light text-light"
                                    : props.usuario?.role === "GERENTE_VENDAS"
                                    ? "bg-secondary-dark text-light"
                                    : "bg-secondary"
                            }`}
                        >
                            <span className="fs-5">{utils.retornaLetrasDoNome(props.usuario.name)}</span>
                        </div>
                    ) : (
                        <Placeholder animation="glow" className="my-div-circle-user-name opacity-25">
                            <Placeholder sm="12" className="rounded-circle h-100" />
                        </Placeholder>
                    )}
                    <Stack className="d-flex w-100 h-100 justify-content-center">
                        {props.usuario ? (
                            <>
                                <span className="lh-1">{props.usuario.name}</span>
                                <small className="lh-1 text-secondary-dark">{props.usuario.email}</small>
                            </>
                        ) : (
                            <>
                                <Placeholder animation="glow" className="opacity-25">
                                    <Placeholder sm="7" className="rounded" />
                                </Placeholder>
                                <Placeholder animation="glow" className="opacity-25 small">
                                    <Placeholder sm="5" className="rounded" />
                                </Placeholder>
                            </>
                        )}
                    </Stack>
                    {props.usuario && (
                        <div className="d-flex">
                            {props.usuario.bloqueado ? (
                                <i className="bi bi-ban text-danger" />
                            ) : (
                                <i className="bi bi-check-lg text-success" />
                            )}
                        </div>
                    )}
                </div>
            </OverlayTrigger>
            <Overlay
                placement="bottom"
                flip
                target={refDiv.current}
                rootClose
                onHide={handleOnHideMenu}
                show={showMenu && !!props.usuario}
            >
                <Popover className="rounded p-1 bg-white shadow">
                    <ListGroup className="rounded">
                        {props.usuario && (
                            <>
                                <Botao size="sm" onClick={handleOnClickAccessPermission}>
                                    <span className="text-truncate">
                                        {props.usuario.bloqueado ? "Dar acesso" : "Retirar acesso"}
                                    </span>
                                    {editandoAcessoPermissao && (
                                        <Spinner size="sm" animation="grow" className="text-secondary-dark ms-2" />
                                    )}
                                </Botao>

                                <Botao size="sm" onClick={handleOnEditUser}>
                                    <span className="text-truncate">Editar usuario</span>
                                </Botao>

                                <Botao size="sm" onClick={handleOnDeleteUser}>
                                    <span className="text-truncate">Deletar usuario</span>
                                </Botao>
                            </>
                        )}
                    </ListGroup>
                </Popover>
            </Overlay>
        </>
    );
}
