import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import InputDate from "../inputs/InputDate";
import { v4 } from "uuid";
import { PartialEntity, tMeta } from "../../interfaces";
import InputValores from "../inputs/InputValores";
import FormMetaAlternativa, { tNovaMetaAlternativa, yupEsquemaMetaAlternativa } from "./FormMetaAlternativa";
import BotaoFechar from "../botoes/BotaoFechar";
import BotaoLabel from "../botoes/BotaoLabel";
import { OffcanvasNovoDado } from "../offcanvas/OffcanvasNovoDado";

const FORM_META_ALT = "form-meta-alternativa";

//TYPES
export type tNovaMeta = PartialEntity<
    tMeta,
    "dataInicioMeta" | "dataFimMeta" | "valorFaturamento" | "valorVenda" | "vendedorId"
> & { metasAlternativas: tNovaMetaAlternativa[] };

export interface IFormMetaProps {
    formId?: string;
    onSubmit: (meta: tNovaMeta) => void | Promise<void>;
    valoresIniciais: tNovaMeta;
}

//FORM
export default function FormMeta(props: IFormMetaProps) {
    //CONTEXTOS
    const { setPerigo, setAviso } = useContext(ContextAlerta);

    //ESTADOS
    const [showOffcanvasAddMetaAlternativa, setShowOffcanvasAddMetaAlternativa] = useState(false);
    const [metasAlternativas, setMetasAlternativas] = useState<tNovaMetaAlternativa[]>([]);
    const [metaAlternativaEditando, setMetaAlternativaEditando] = useState<tNovaMetaAlternativa | null>(null);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    useEffect(() => {
        setMetasAlternativas(valoresIniciais.metasAlternativas);
    }, [valoresIniciais]);

    const onSubmitFormik = useCallback(
        async (meta: tNovaMeta, helpers: FormikHelpers<tNovaMeta>) => {
            try {
                await onSubmit({ ...meta, metasAlternativas });
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo, metasAlternativas]
    );

    const handleOnClickAddMetaAlternativa = useCallback(() => setShowOffcanvasAddMetaAlternativa(true), []);

    const handleOnClickDeleteMetaAlternativa = useCallback(
        (metaAlternativa: tNovaMetaAlternativa) =>
            setMetasAlternativas((current) => current.filter((ma) => ma.tipoMeta !== metaAlternativa.tipoMeta)),
        []
    );

    const handleOnSubmitMetaAlternativa = useCallback(
        (metaAlternativa: tNovaMetaAlternativa) => {
            if (metaAlternativaEditando) {
                setMetasAlternativas((current) =>
                    current.map((meta) => (meta.tipoMeta === metaAlternativa.tipoMeta ? metaAlternativa : meta))
                );
                return setMetaAlternativaEditando(null);
            }

            if (metasAlternativas.some((meta) => meta.tipoMeta === metaAlternativa.tipoMeta)) {
                setAviso("Este tipo de meta já foi definido.");
                throw new Error("Meta já definida");
            }

            setMetasAlternativas((current) => [...current, metaAlternativa]);
            setShowOffcanvasAddMetaAlternativa(false);
        },
        [metaAlternativaEditando, metasAlternativas, setAviso]
    );

    return (
        <>
            <Formik
                validateOnChange={false}
                validationSchema={yupEsquemaMeta}
                onSubmit={onSubmitFormik}
                initialValues={valoresIniciais}
            >
                {({ handleSubmit, values, errors, setValues }) => {
                    return (
                        <Form id={formId} onSubmit={handleSubmit}>
                            <Row className="gap-3">
                                <Form.Group as={Col} sm="12" controlId={v4()}>
                                    <Form.Label>Data inicial</Form.Label>
                                    <InputDate
                                        valor={values.dataInicioMeta}
                                        onAccept={(dataInicioMeta) => setValues({ ...values, dataInicioMeta })}
                                        isInvalid={!!errors.dataInicioMeta}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.dataInicioMeta as string}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm="12" controlId={v4()}>
                                    <Form.Label>Data final</Form.Label>
                                    <InputDate
                                        dataFinal
                                        valor={values.dataFimMeta}
                                        onAccept={(dataFimMeta) => setValues({ ...values, dataFimMeta })}
                                        isInvalid={!!errors.dataFimMeta}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.dataFimMeta as string}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm="12" controlId={v4()}>
                                    <Form.Label>Valor Meta de Venda</Form.Label>
                                    <InputValores
                                        moeda="BRL"
                                        valor={values.valorVenda}
                                        onAccept={(valorVenda) =>
                                            setValues({ ...values, valorVenda, valorFaturamento: valorVenda })
                                        }
                                        isInvalid={!!errors.valorVenda}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.valorVenda}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm="12" controlId={v4()}>
                                    <Form.Label>Valor Meta de Faturamento</Form.Label>
                                    <InputValores
                                        moeda="BRL"
                                        valor={values.valorFaturamento}
                                        onAccept={(valorFaturamento) => setValues({ ...values, valorFaturamento })}
                                        isInvalid={!!errors.valorFaturamento}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.valorFaturamento}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Col sm="12" className="border-top pt-2">
                                    <Row>
                                        <Col sm="12" className="mb-1">
                                            <h6>Outras metas</h6>
                                        </Col>
                                        <Form.Control isInvalid={!!errors.metasAlternativas} className="d-none" />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.metasAlternativas as string}
                                        </Form.Control.Feedback>
                                        <Col sm="12">
                                            <ListaMetasAlternativas
                                                metasAlternativas={metasAlternativas}
                                                onClickAdd={handleOnClickAddMetaAlternativa}
                                                onClickDelete={handleOnClickDeleteMetaAlternativa}
                                                onClickEdit={setMetaAlternativaEditando}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>

            <OffcanvasNovoDado.Root show={showOffcanvasAddMetaAlternativa}>
                <OffcanvasNovoDado.Header>
                    <h4>Adicionar Outra Meta</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: () => setShowOffcanvasAddMetaAlternativa(false) }}
                    acceptButtonProps={{ type: "submit", form: FORM_META_ALT }}
                >
                    <FormMetaAlternativa
                        onSubmit={handleOnSubmitMetaAlternativa}
                        valoresIniciais={{ tipoMeta: "VISITA", valor: 0 }}
                        formId={FORM_META_ALT}
                    />
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>

            <OffcanvasNovoDado.Root show={!!metaAlternativaEditando}>
                <OffcanvasNovoDado.Header>
                    <h4>Editar Gasto da Visita</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: () => setMetaAlternativaEditando(null) }}
                    acceptButtonProps={{ type: "submit", form: FORM_META_ALT, children: "Editar" }}
                >
                    {!!metaAlternativaEditando && (
                        <FormMetaAlternativa
                            onSubmit={handleOnSubmitMetaAlternativa}
                            valoresIniciais={metaAlternativaEditando}
                            formId={FORM_META_ALT}
                        />
                    )}
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>
        </>
    );
}

//FUNCOES AUXILIARES
function ListaMetasAlternativas(props: {
    metasAlternativas: tNovaMetaAlternativa[];
    onClickAdd: () => void;
    onClickEdit: (metaAlternativa: tNovaMetaAlternativa) => void;
    onClickDelete: (metaAlternativa: tNovaMetaAlternativa) => void;
}) {
    return (
        <Row className="gap-2">
            {props.metasAlternativas.map((metaAlternativa) => {
                return (
                    <Col sm="12" key={v4()}>
                        <Stack className="d-flex w-100 position-relative border rounded shadow-sm p-2">
                            <div className="position-absolute end-0 top-0 mt-1 me-1 z-1">
                                <BotaoFechar onClick={() => props.onClickDelete(metaAlternativa)} />
                            </div>
                            <BotaoLabel
                                variant="info"
                                texto={utils.retornaTextoTipoMetaAlternativa(metaAlternativa.tipoMeta)}
                                onClick={() => props.onClickEdit(metaAlternativa)}
                            />
                            <div className="d-flex w-100 justify-content-between">
                                <small>Meta</small>
                                {metaAlternativa.tipoMeta === "VISITA" ? (
                                    <small>{metaAlternativa.valor}</small>
                                ) : undefined}
                            </div>
                        </Stack>
                    </Col>
                );
            })}
            <Col sm="12" className="text-end">
                <Button className="rounded-pill" size="sm" onClick={props.onClickAdd}>
                    Adicionar
                </Button>
            </Col>
        </Row>
    );
}

export const yupEsquemaMeta: yup.ObjectSchema<tNovaMeta> = yup.object({
    vendedorId: yup.string().required("Este campo é necessário."),
    dataInicioMeta: yup.date().required("Este campo é necessário."),
    dataFimMeta: yup.date().required("Este campo é necessário."),
    valorFaturamento: yup.number().required("Esta campo é necessário."),
    valorVenda: yup.number().required("Este campo é necessário."),
    metasAlternativas: yup.array(yupEsquemaMetaAlternativa).required("Este campo é necessário."),
});
