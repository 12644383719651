import { tServico } from "../../interfaces";
import utils from "../../utils";
import { Tabela } from "./Tabela";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "./styles.css";
import { v4 } from "uuid";
import React, { useCallback, useContext, useState } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import ModalAuditoriaServico from "../modals/ModalAuditoriaServico";

interface ITabelaServicoProps {
    servicos: tServico[];
    onEdit: (servico: tServico) => void;
    onDelete: (servico: tServico) => void;
}

export default function TabelaServico(props: ITabelaServicoProps) {
    //CONTEXTO
    const { podeEditarServico, podeDeletarServico, podeVisualizarAuditoria } = useContext(ContextUser);

    //ESTADOS
    const [servicoAuditando, setServicoAuditando] = useState<tServico | null>(null);

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((servico: tServico) => setServicoAuditando(servico), []);
    const handleOnHideAuditoria = useCallback(() => setServicoAuditando(null), []);

    return (
        <>
            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        <th>Serviço</th>
                        <th>Custo Hora</th>
                        <th>Tipo Produto/Serviço</th>
                        <th>Observações</th>
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.servicos}
                    campoUnico="id"
                    campoReferencia="name"
                    onEdit={podeEditarServico({}) ? props.onEdit : undefined}
                    onDelete={podeDeletarServico({}) ? props.onDelete : undefined}
                    onClickAuditoria={
                        podeVisualizarAuditoria({ tabela: "SERVICO" }) ? handleOnClickOpenAuditoria : undefined
                    }
                >
                    {(servico: tServico) => (
                        <>
                            <td>{servico.name}</td>
                            <td>{utils.retornaValorMonetario(servico.valorCustoHora, servico.tipoMoeda)}</td>
                            <td>{servico.tipoProdutoServico.name}</td>
                            <td>
                                <ElementoObservacao observacao={servico.observacoes} />
                            </td>
                        </>
                    )}
                </Tabela.Body>
            </Tabela.Table>

            <ModalAuditoriaServico
                servico={servicoAuditando}
                show={servicoAuditando !== null}
                onHide={handleOnHideAuditoria}
            />
        </>
    );
}

export function ElementoObservacao(props: { observacao?: string }) {
    return props.observacao === undefined || props.observacao.trim() === "" ? (
        <span className="pe-none">--- Nenhuma observação ---</span>
    ) : (
        <OverlayTrigger
            flip
            placement="bottom"
            trigger={["click", "focus"]}
            rootClose
            overlay={
                <Popover className="shadow">
                    <Popover.Body className="p-2">
                        {props.observacao.split("\n").map((texto?: string) =>
                            !texto ? (
                                <br key={v4()} />
                            ) : (
                                <React.Fragment key={v4()}>
                                    <span>{texto}</span>
                                    <br />
                                </React.Fragment>
                            )
                        )}
                    </Popover.Body>
                </Popover>
            }
        >
            <span className="text-info" role="button">
                <i className="bi bi-file-earmark-text-fill me-2" />
                Clique para visualizar
            </span>
        </OverlayTrigger>
    );
}
