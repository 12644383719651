import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback } from "react";
import useFiltros from "../../hooks/useFiltros";
import utils from "../../utils";

const NENHUM_FILTRO = "Todas as comissões";
const COMISSAO_PAGA = "Comissões pagas";
const COMISSAO_N_PAGA = "Comissões não pagas";

export default function FiltroComissaoPaga() {
    //HOOKS
    const { setFilter, removeFilter, getFilter } = useFiltros();

    //VARIAVEIS
    const comissaoPaga = utils.deStringPara<boolean>(getFilter("comissaoPaga"), "boolean");
    const retornaTextoFiltro = useCallback(() => {
        if (comissaoPaga === undefined) return NENHUM_FILTRO;
        else return comissaoPaga ? "Pagas" : "Não pagas";
    }, [comissaoPaga]);

    //EVENTOS
    const handleOnSelecionarFiltro = useCallback(
        (estado: boolean | null) => {
            if (estado === null) return removeFilter("comissaoPaga");
            setFilter("comissaoPaga", estado.toString());
        },
        [setFilter, removeFilter]
    );
    
    return (
        <FiltroSelect texto={retornaTextoFiltro()} titulo="Estado comissão">
            <ListGroup className="small">
                <ListGroup.Item
                    className={`my-item-filtro ${comissaoPaga === undefined ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarFiltro(null)}
                >
                    {NENHUM_FILTRO}
                </ListGroup.Item>
                <ListGroup.Item
                    className={`my-item-filtro ${comissaoPaga === false ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarFiltro(false)}
                >
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        {COMISSAO_N_PAGA} <div className="p-2 bg-warning rounded-circle" />
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    className={`my-item-filtro ${comissaoPaga === true ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarFiltro(true)}
                >
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        {COMISSAO_PAGA} <div className="p-2 bg-success rounded-circle" />
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </FiltroSelect>
    );
}
