import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { LayoutPrincipal } from "./layouts";
import ProviderUser from "./contexts/ContextUser";
import LayoutLogin from "./layouts/LayoutLogin/LayoutLogin";
import {
    Clientes,
    Fornecedores,
    Vendedores,
    Produtos,
    Vendas,
    Vendedor,
    Servicos,
    ErrorInfoUser,
    RecuperarSenha,
    CriarNovaSenha,
    Comissoes,
    Visitas,
} from "./views";
import Dashboard from "./views/Dashboard";
import { useEffect, useState } from "react";
import api from "./services/api";

const version = process.env.REACT_APP_VERSION;

export const routes = {
    login: "/login",
    logado: "/ambiente-logado",
    dashboard: "/ambiente-logado/dashboard",
    clientes: "/ambiente-logado/clientes",
    fornecedores: "/ambiente-logado/fornecedores",
    vendedores: "/ambiente-logado/vendedores",
    vendedor: "/ambiente-logado/vendedor/:id",
    produtos: "/ambiente-logado/produtos",
    servicos: "/ambiente-logado/servicos",
    comissoes: "/ambiente-logado/comissoes",
    vendas: "/ambiente-logado/vendas",
    visitas: "/ambiente-logado/visitas",
    errorInfouser: "/error-info-user/:error",
    recuperarSenha: "/recuperar-senha",
    criarNovaSenha: "/criar-senha",
};

export default function Router() {
    //STATES
    const [apiVersion, setApiVersion] = useState("");

    //VARIAVEIS
    const router = createBrowserRouter([
        { path: "/", element: <Navigate to={routes.logado} /> },
        { path: routes.login, element: <LayoutLogin /> },
        { path: routes.recuperarSenha, element: <RecuperarSenha /> },
        {
            path: routes.logado,
            element: (
                <ProviderUser>
                    <LayoutPrincipal />
                </ProviderUser>
            ),
            children: [
                { index: true, element: <Navigate to={routes.dashboard} /> },
                { path: routes.dashboard, element: <Dashboard /> },
                { path: routes.clientes, element: <Clientes /> },
                { path: routes.fornecedores, element: <Fornecedores /> },
                { path: routes.vendedores, element: <Vendedores /> },
                { path: routes.vendedor, element: <Vendedor /> },
                { path: routes.produtos, element: <Produtos /> },
                { path: routes.servicos, element: <Servicos /> },
                { path: routes.vendas, element: <Vendas /> },
                { path: routes.comissoes, element: <Comissoes /> },
                { path: routes.visitas, element: <Visitas /> },
            ],
        },
        { path: routes.errorInfouser, element: <ErrorInfoUser /> },
        { path: routes.criarNovaSenha, element: <CriarNovaSenha /> },
    ]);

    //EVENTOS
    useEffect(() => {
        api.getApiVersion()
            .then((res) => setApiVersion(res.data))
            .catch((err) => console.error(err));
    }, []);

    return (
        <>
            <RouterProvider router={router} />
            <code className="position-fixed bottom-0 end-0 me-4 text-dark opacity-50">
                v{version} <i className="bi bi-lightning-charge-fill" /> {apiVersion}
            </code>
        </>
    );
}
