import { Stack } from "react-bootstrap";
import { routes } from "../../router";
import MenuLink from "../../components/links/MenuLink";
import { useContext } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import "./styles.css";

interface Props {
    carregando?: boolean;
}

export default function Menu(props: Props) {
    //CONTEXTOS
    const {
        podeVisualizarCliente,
        podeVisualizarVendedor,
        podeVisualizarFornecedor,
        podeVisualizarProduto,
        podeVisualizarServico,
        podeVisualizarVenda,
        podeVisualizarComissao,
        podeVisualizarVisita,
        usuario,
    } = useContext(ContextUser);

    return (
        <div className="d-flex my-div-menu-layout-principal bg-primary">
            <Stack className="d-flex w-100">
                <MenuLink
                    carregando={props.carregando}
                    nome="Dashboard"
                    path={routes.dashboard}
                    icon="bar-chart-line-fill"
                />
                {(podeVisualizarComissao({}) ||
                    podeVisualizarComissao({ vendedorId: usuario?.vendedorUser?.id ?? "" })) && (
                    <MenuLink carregando={props.carregando} nome="Comissões" path={routes.comissoes} icon="cash-coin" />
                )}
                {podeVisualizarCliente({}) && (
                    <MenuLink carregando={props.carregando} nome="Clientes" path={routes.clientes} icon="people-fill" />
                )}
                {podeVisualizarVendedor({}) && (
                    <MenuLink
                        carregando={props.carregando}
                        nome="Vendedores"
                        path={routes.vendedores}
                        icon="person-fill"
                    />
                )}
                {podeVisualizarFornecedor({}) && (
                    <MenuLink
                        carregando={props.carregando}
                        nome="Fornecedores"
                        path={routes.fornecedores}
                        icon="building-fill"
                    />
                )}
                {podeVisualizarProduto({}) && (
                    <MenuLink
                        carregando={props.carregando}
                        nome="Produtos"
                        path={routes.produtos}
                        icon="box-seam-fill"
                    />
                )}
                {podeVisualizarServico({}) && (
                    <MenuLink carregando={props.carregando} nome="Serviços" path={routes.servicos} icon="award-fill" />
                )}
                {(podeVisualizarVisita({}) ||
                    podeVisualizarVisita({ vendedorId: usuario?.vendedorUser?.id ?? "" })) && (
                    <MenuLink carregando={props.carregando} nome="Visitas" path={routes.visitas} icon="pin-map-fill" />
                )}
                {(podeVisualizarVenda({}) || podeVisualizarVenda({ vendedorId: usuario?.vendedorUser?.id ?? "" })) && (
                    <MenuLink carregando={props.carregando} nome="Vendas" path={routes.vendas} icon="basket3-fill" />
                )}
            </Stack>
        </div>
    );
}
