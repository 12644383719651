import { Button, Card, Col, Spinner, Stack } from "react-bootstrap";
import { FormikHelpers } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { routes } from "../../router";
import api from "../../services/api";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import FormNovaSenha, { tNovaSenha } from "../forms/FormNovaSenha";
import "./styles.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import MyLink from "../links/MyLink";

let abortController: AbortController | undefined;

const FORM_CRIAR_NOVA_SENHA_ID = "form-criar-nova-senha";

export default function CardCriarNovaSenha() {
    //CONTEXTOS
    const { setPerigo, setSucesso } = useContext(ContextAlerta);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()

    //ESTADOS
    const [mudandoSenha, setMudandoSenha] = useState(false);

    //EVENTOS
    useEffect(() => {
        return () => abortController?.abort();
    }, []);

    const handleSubmit = useCallback(
        async (dadosSenha: tNovaSenha, helpersFormik: FormikHelpers<tNovaSenha>) => {
            const token = searchParams.get("token");
            if (!token) return setPerigo("Este link está corrompido. Obtenha um novo link de recuperação.");

            abortController = new AbortController();
            const signal = abortController.signal;

            setMudandoSenha(true);
            try {
                await api.mudarSenhaPerdida({ password: dadosSenha.password, token }, signal);
                helpersFormik.setSubmitting(false);
                setSucesso("Sua senha foi modificada!");
                navigate(routes.login)
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                console.error(err);
                setPerigo(utils.retornaMensagemErro(err));
            } finally {
                setMudandoSenha(false);
            }
        },
        [setPerigo, setSucesso, searchParams, navigate]
    );

    return (
        <Card className="my-card-criar-nova-senha shadow border-primary">
            <Card.Body>
                <Col sm="12" className="text-center mb-3">
                    <Stack className="d-flex align-items-center gap-1">
                        <img className="my-img-logo" alt="logo" src="/logo192-col.png" />
                        <h4 className="mb-0 text-primary">NextGen ComissionPro</h4>
                    </Stack>
                </Col>
                <Col sm="12">
                    <FormNovaSenha
                        formId={FORM_CRIAR_NOVA_SENHA_ID}
                        valoresIniciais={{ password: "", confirmPass: "" }}
                        onSubmit={handleSubmit}
                    />
                </Col>
                <Col sm="12">
                    <MyLink to={routes.login}>Realizar login?</MyLink>
                </Col>
                <Col sm="12" className="text-end mt-3">
                    <Button type="submit" form={FORM_CRIAR_NOVA_SENHA_ID}>
                        Mudar Senha
                        {mudandoSenha && <Spinner size="sm" animation="grow" className="ms-1" />}
                    </Button>
                </Col>
            </Card.Body>
        </Card>
    );
}
