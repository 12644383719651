import { Col, Placeholder, Stack } from "react-bootstrap";
import Menu from "./Menu";
import { Outlet } from "react-router-dom";
import { routes } from "../../router";
import { BotaoPerfil, BotaoUser } from "../../components";
import { useContext } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import "./styles.css";

export default function LayoutPrincipal() {
    //CONTEXTOS
    const { podeVisualizarUser, carregandoUsuario } = useContext(ContextUser);

    return (
        <div className="my-div-root-layout-principal bg-light">
            <Stack className="d-flex w-100 h-100">
                <div className="d-flex my-div-header-layout-principal bg-primary">
                    <div className="d-flex my-div-header-icon-lp justify-content-center align-items-center">
                        {carregandoUsuario ? (
                            <Placeholder className="my-img-logo-header-lp" animation="glow">
                                <Placeholder className="w-100 h-100 rounded" />
                            </Placeholder>
                        ) : (
                            <a href={routes.dashboard}>
                                <img alt="logo" src="/logo192.png" className="my-img-logo-header-lp" />
                            </a>
                        )}
                    </div>
                    <div className="d-flex my-div-header-lp">
                        <div className="d-flex align-items-center w-100 h-100">
                            {carregandoUsuario ? (
                                <Placeholder as={Col} sm="6" lg="3" animation="glow">
                                    <Placeholder as="h4" className="w-100 rounded mb-0" />
                                </Placeholder>
                            ) : (
                                <h4 className="mb-0 text-white text-nowrap">NextGen CommissionPro</h4>
                            )}
                        </div>
                    </div>
                    <div className="d-flex my-div-header-profile-lp justify-content-end align-items-center me-2 gap-1">
                        {carregandoUsuario ? (
                            <>
                                <Placeholder className="w-75 h-100 p-1" animation="glow">
                                    <Placeholder className="w-100 h-100 rounded-pill" />
                                </Placeholder>
                                <Placeholder className="w-25 h-100 pt-1 pb-1" animation="glow">
                                    <Placeholder className="w-100 h-100 rounded" />
                                </Placeholder>
                            </>
                        ) : (
                            <>
                                {podeVisualizarUser({}) && <BotaoUser />}
                                <BotaoPerfil />
                            </>
                        )}
                    </div>
                </div>
                <div className="my-div-body-layout-principal align-items-start">
                    <div className="d-flex w-100 h-100">
                        <Menu carregando={carregandoUsuario} />
                        <div className="my-div-body-lp">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </Stack>
        </div>
    );
}
