import { Stack } from "react-bootstrap";
import { ReactNode } from "react";
import "./styles.css";

interface LayoutTabelaRootProps {
    children: ReactNode;
}

export default function LayoutTabelaRoot(props: LayoutTabelaRootProps) {
    return <Stack className="d-flex w-100 h-100">{props.children}</Stack>;
}
