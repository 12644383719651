import { useCallback, useContext, useState } from "react";
import { PartialEntity, tComissao } from "../interfaces";
import { ContextAlerta } from "../contexts/ContextAlert";
import api, { tQueryParamsComissao, tQueryParamsComissaoRelatorio } from "../services/api";
import utils from "../utils";

let abortController: AbortController | undefined;
export const LIMITE_POR_BUSCA_COMISSAO = 50;

export default function useComissao() {
    //CONTEXTOS
    const { setPerigo, setSucesso } = useContext(ContextAlerta);

    //ESTADOS
    const [arrBuscando, setArrBuscando] = useState<true[]>([]);
    const [arrBuscandoPorId, setArrBuscandoPorId] = useState<true[]>([]);
    const [arrBuscandoRelatorios, setArrBuscandoRelatorios] = useState<true[]>([]);
    const [estaEditando, setEstaEditando] = useState(false);
    const [buscaComErro, setBuscaComErro] = useState(false);
    const [buscaRelatorioComErro, setBuscaRelatorioComErro] = useState(false);

    const buscar = useCallback(
        async (params?: tQueryParamsComissao): Promise<tComissao[]> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setArrBuscando((current) => [...current, true]);
                    const res = await api.listaComissoes(params, signal);
                    setBuscaComErro(false);
                    resolve(res.data);
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    setBuscaComErro(true);
                    reject(err);
                } finally {
                    setArrBuscando((current) => current.slice(1));
                }
            });
        },
        [setPerigo]
    );

    const buscarPorId = useCallback(
        async (id: string): Promise<tComissao> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise<tComissao>(async (resolve, reject) => {
                try {
                    setArrBuscandoPorId((current) => [...current, true]);
                    const res = await api.getComissao(id, signal);
                    setBuscaComErro(false);
                    resolve(res.data);
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    setBuscaComErro(true);
                    reject(err);
                } finally {
                    setArrBuscandoPorId((current) => current.slice(1));
                }
            });
        },
        [setPerigo]
    );

    const buscarRelatorios = useCallback(
        async (params?: tQueryParamsComissaoRelatorio): Promise<Blob> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setArrBuscandoRelatorios((current) => [...current, true]);
                    const res = await api.buscarRelatoriosComissao(params, signal);
                    setBuscaRelatorioComErro(false);
                    resolve(res.data);
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    setBuscaRelatorioComErro(true);
                    reject(err);
                } finally {
                    setArrBuscandoRelatorios((current) => current.slice(1));
                }
            });
        },
        [setPerigo]
    );

    const editarVarias = useCallback(
        async (comissoesId: string[], comissaoPaga: boolean) => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise<void>(async (resolve, reject) => {
                try {
                    setEstaEditando(true);
                    await api.editarVarias(comissoesId, comissaoPaga, signal);
                    setSucesso("Comissões pagas!");
                    resolve();
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    reject(err);
                } finally {
                    setEstaEditando(false);
                }
            });
        },
        [setSucesso, setPerigo]
    );

    const editar = useCallback(
        async (comissao: PartialEntity<tComissao, "id" | "comissaoPaga">): Promise<void> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise<void>(async (resolve, reject) => {
                try {
                    setEstaEditando(true);
                    await api.editarComissao(comissao, signal);
                    setSucesso("Comissão editada!");
                    resolve();
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    reject(err);
                } finally {
                    setEstaEditando(false);
                }
            });
        },
        [setPerigo, setSucesso]
    );

    const cancelar = useCallback(() => abortController?.abort(), []);

    return {
        estaBuscando: arrBuscando.length > 0,
        estaBuscandoPorId: arrBuscandoPorId.length > 0,
        estaBuscandoRelatorios: arrBuscandoRelatorios.length > 0,
        estaEditando,
        buscaComErro,
        buscaRelatorioComErro,
        editarVarias,
        editar,
        buscar,
        buscarPorId,
        buscarRelatorios,
        cancelar,
    };
}
