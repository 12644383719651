import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { PartialEntity, tVendedor, tVendedorComissao } from "../../interfaces";
import * as yup from "yup";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import { v4 } from "uuid";
import BotaoFechar from "../botoes/BotaoFechar";
import BotaoLabel from "../botoes/BotaoLabel";
import FormVendedorComissao, { tNovoVendedorComissao, yupEsquemaVendedorComissao } from "./FormVendedorComissao";
import { OffcanvasNovoDado } from "../offcanvas/OffcanvasNovoDado";
import { OffcanvasEditarDado } from "../offcanvas/OffcanvasEditarDado.tsx";
import useVendedor from "../../hooks/useVendedor";

//TYPES
export type tNovaDistribuicaoComissao = { vendedoresComissao: tNovoVendedorComissao[] };

export interface IFormDistribuicaoComissaoProps {
    formId?: string;
    onSubmit: (distribuicao: tNovaDistribuicaoComissao) => void | Promise<void>;
    valoresIniciais: tNovaDistribuicaoComissao;
}

//FORM
export default function FormDistribuicaoComissao(props: IFormDistribuicaoComissaoProps) {
    //HOOKS
    const { buscar, cancelar } = useVendedor();

    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //ESTADOS
    const [vendedores, setVendedores] = useState<tVendedor[]>([]);
    const [vendedoresComissao, setVendedoresComissao] = useState<tNovoVendedorComissao[]>([]);
    const [mostrarAddVendedorComissao, setMostrarAddVendedorComissao] = useState(false);
    const [vendedorComissaoEditando, setVendedorComissaoEditando] = useState<null | tNovoVendedorComissao>(null);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;
    const FORM_VENDEDOR_COMISSAO = "form-add-edit-vendedor-comissao";

    //EVENTOS
    useEffect(() => {
        buscar()
            .then((vendedores) => setVendedores(vendedores))
            .catch((err) => console.error(err));
        setVendedoresComissao(valoresIniciais.vendedoresComissao);

        return () => cancelar();
    }, [buscar, cancelar, valoresIniciais]);

    const handleOnClickAddVendedorComissao = useCallback(() => setMostrarAddVendedorComissao(true), []);
    const handleOnClickCancelAddVendedorComissao = useCallback(() => setMostrarAddVendedorComissao(false), []);

    const handleOnClickCancelEditVendedorComissao = useCallback(() => setVendedorComissaoEditando(null), []);
    const handleOnClickEditVendedorComissao = useCallback(
        (vendedorComsissao: tNovoVendedorComissao) => setVendedorComissaoEditando(vendedorComsissao),
        []
    );

    const handleOnRemoveVendedorComissao = useCallback((vendedorComissao: tNovoVendedorComissao) => {
        setVendedoresComissao((current) =>
            current.filter(({ vendedorId }) => vendedorComissao.vendedorId !== vendedorId)
        );
    }, []);

    const handleOnSubmitVendedorComissao = useCallback(
        (vendedorComissao: tNovoVendedorComissao) => {
            const vendedorExisteNaDistribuicao = vendedoresComissao.some(
                ({ vendedorId }) => vendedorId === vendedorComissao.vendedorId
            );

            if (
                vendedorExisteNaDistribuicao &&
                (!vendedorComissaoEditando || vendedorComissaoEditando.vendedorId !== vendedorComissao.vendedorId)
            )
                return setPerigo("Este vendedor já existe na distribuição.");

            //Adiciona vendedorComissao na distribuicao
            if (!vendedorComissaoEditando) {
                setVendedoresComissao((current) => [...current, vendedorComissao]);
                return setMostrarAddVendedorComissao(false);
            }

            //Edita vendedorComissao na distribuicao
            setVendedoresComissao((current) =>
                current.map((vc) => (vc.vendedorId === vendedorComissaoEditando.vendedorId ? vendedorComissao : vc))
            );
            setVendedorComissaoEditando(null);
        },
        [vendedoresComissao, vendedorComissaoEditando, setPerigo]
    );

    const onSubmitFormik = useCallback(
        async (_: tNovaDistribuicaoComissao, helpers: FormikHelpers<tNovaDistribuicaoComissao>) => {
            if (vendedoresComissao.length < 1)
                return helpers.setErrors({ vendedoresComissao: "Insira ao menos 1 vendedor com comissão." });
            try {
                await onSubmit({ vendedoresComissao });
                helpers.setSubmitting(false);
                helpers.resetForm();
                setVendedoresComissao([]);
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo, vendedoresComissao]
    );

    return (
        <>
            <Formik
                validateOnChange={false}
                validationSchema={yupEsquemaDistribuicaoComissao}
                onSubmit={onSubmitFormik}
                initialValues={valoresIniciais}
            >
                {({ handleSubmit, errors }) => {
                    return (
                        <Form id={formId} onSubmit={handleSubmit}>
                            <Row className="gap-3">
                                <Form.Group as={Col} sm="12" controlId={v4()}>
                                    <h6>Vendedores c/ comissão</h6>
                                    <Form.Control isInvalid={!!errors.vendedoresComissao} className="d-none" />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vendedoresComissao as string}
                                    </Form.Control.Feedback>
                                    <ListaVendedoresComissao
                                        vendedoresComissao={vendedoresComissao}
                                        vendedores={vendedores}
                                        onClickAdd={handleOnClickAddVendedorComissao}
                                        onClickEdit={handleOnClickEditVendedorComissao}
                                        onClickDelete={handleOnRemoveVendedorComissao}
                                    />
                                </Form.Group>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>

            <OffcanvasNovoDado.Root show={mostrarAddVendedorComissao}>
                <OffcanvasNovoDado.Header>
                    <h4>Adicionar Vendedor c/ Comissão</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: handleOnClickCancelAddVendedorComissao }}
                    acceptButtonProps={{ type: "submit", form: FORM_VENDEDOR_COMISSAO }}
                >
                    <FormVendedorComissao
                        formId={FORM_VENDEDOR_COMISSAO}
                        onSubmit={handleOnSubmitVendedorComissao}
                        valoresIniciais={{ vendedorId: "", comissao: 0 }}
                    />
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>

            <OffcanvasEditarDado.Root show={!!vendedorComissaoEditando}>
                <OffcanvasEditarDado.Header>
                    <h4>Editar Vendedor c/ Comissão</h4>
                </OffcanvasEditarDado.Header>
                <OffcanvasEditarDado.Body
                    cancelButtonProps={{ onClick: handleOnClickCancelEditVendedorComissao }}
                    acceptButtonProps={{ form: FORM_VENDEDOR_COMISSAO, type: "submit" }}
                >
                    {vendedorComissaoEditando ? (
                        <FormVendedorComissao
                            formId={FORM_VENDEDOR_COMISSAO}
                            valoresIniciais={vendedorComissaoEditando}
                            onSubmit={handleOnSubmitVendedorComissao}
                        />
                    ) : undefined}
                </OffcanvasEditarDado.Body>
            </OffcanvasEditarDado.Root>
        </>
    );
}

//FUNCOES AUXILIARES
function ListaVendedoresComissao(props: {
    vendedoresComissao: PartialEntity<tVendedorComissao, "comissao" | "vendedorId">[];
    vendedores: tVendedor[];
    onClickAdd: () => void;
    onClickEdit: (vendedorComissao: PartialEntity<tVendedorComissao, "comissao" | "vendedorId">) => void;
    onClickDelete: (vendedorComissao: PartialEntity<tVendedorComissao, "comissao" | "vendedorId">) => void;
}) {
    return (
        <Row className="gap-2">
            {props.vendedoresComissao.map((vendedorComissao) => {
                const vendedor = props.vendedores.find(({ id }) => id === vendedorComissao.vendedorId);
                return (
                    <Col sm="12" key={v4()}>
                        <Stack className="d-flex w-100 position-relative border rounded shadow-sm p-2">
                            <div className="position-absolute end-0 top-0 mt-1 me-1 z-1">
                                <BotaoFechar onClick={() => props.onClickDelete(vendedorComissao)} />
                            </div>
                            <BotaoLabel
                                variant="info"
                                texto={vendedor?.user.name ?? ""}
                                onClick={() => props.onClickEdit(vendedorComissao)}
                            />
                            <div className="d-flex w-100 justify-content-between">
                                <small>Comissão:</small>
                                <small>{vendedorComissao.comissao.toFixed(2)} %</small>
                            </div>
                        </Stack>
                    </Col>
                );
            })}

            <Col sm="12" className="text-end">
                <Button className="rounded-pill" size="sm" onClick={props.onClickAdd}>
                    Adicionar
                </Button>
            </Col>
        </Row>
    );
}

export const yupEsquemaDistribuicaoComissao: yup.ObjectSchema<tNovaDistribuicaoComissao> = yup.object({
    vendedoresComissao: yup.array(yupEsquemaVendedorComissao).required("Este campo é requerido."),
});
