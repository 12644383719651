import { Col, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import { PartialEntity, tCliente } from "../../interfaces";
import * as yup from "yup";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import SelectSegmento from "../selects/SelectSegmento";
import { v4 } from "uuid";
import InputTelefone from "../inputs/InputTelefone";

//TYPES
export type tNovoCliente = PartialEntity<tCliente, "name" | "email" | "segmentoId" | "telefone" | "cnpj">;

export interface IFormClienteProps {
    formId?: string;
    onSubmit: (cliente: tNovoCliente) => void | Promise<void>;
    valoresIniciais: tNovoCliente;
}

//FORM
export default function FormCliente(props: IFormClienteProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    const onSubmitFormik = useCallback(
        async (cliente: tNovoCliente, helpers: FormikHelpers<tNovoCliente>) => {
            try {
                await onSubmit(cliente);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaCliente}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Nome do cliente</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.name}
                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">CNPJ do cliente</Form.Label>
                                <Form.Control
                                    as={IMaskInput}
                                    required
                                    type="text"
                                    unmask
                                    mask="00.000.000/0000-00"
                                    value={values.cnpj}
                                    onAccept={(CNPJ: string) => setValues({ ...values, cnpj: CNPJ })}
                                    isInvalid={!!errors.cnpj}
                                />
                                <Form.Control.Feedback type="invalid">{errors.cnpj}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">E-mail</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={values.email}
                                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" controlId={v4()}>
                                <Form.Label className="mb-1">Telefone para contato</Form.Label>
                                <InputTelefone
                                    required
                                    valor={values.telefone}
                                    onAccept={(telefone: string) => setValues({ ...values, telefone })}
                                    isInvalid={!!errors.telefone}
                                />
                                <Form.Control.Feedback type="invalid">{errors.telefone}</Form.Control.Feedback>
                            </Form.Group>
                            <Col as={Col} sm="12" className="text-end">
                                <Form.Label className="mb-1">Segmento</Form.Label>
                                <SelectSegmento
                                    idSelecionado={values.segmentoId}
                                    setIdSelecionado={(segmentoId) =>
                                        setValues({ ...values, segmentoId: segmentoId ?? "" })
                                    }
                                />
                                <Form.Control id={v4()} className="d-none" isInvalid={!!errors.segmentoId} />
                                <Form.Control.Feedback type="invalid">{errors.segmentoId}</Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaCliente: yup.ObjectSchema<tNovoCliente> = yup.object({
    name: yup.string().required("Campo necessário.").max(256, "Deve ter no máximo 256 carecteres."),
    cnpj: yup
        .string()
        .required("Campo necessário.")
        .min(14, "Um CNPJ válido deve ter 14 dígitos.")
        .max(14, "Um CNPJ válido deve ter 14 dígitos."),
    email: yup
        .string()
        .email("Insira um e-mail válido.")
        .required("Campo necessário.")
        .max(256, "Deve ter no máximo 256 caracteres."),
    telefone: yup.string().required("Campo necessário."),
    segmentoId: yup.string().required("Campo necessário."),
});
