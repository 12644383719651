import { useCallback, useContext, useEffect, useState } from "react";
import {
    BotaoAdicionarDado,
    FiltroAnos,
    FiltroMes,
    FiltroVendedores,
    OffcanvasVisita,
    TabelaVisita,
} from "../components";
import { LayoutTabela } from "../layouts";
import { tVisita } from "../interfaces";
import { ContextUser } from "../contexts/ContextUser";
import { tPossivelMes } from "../components/filtros/FiltroMes";
import utils from "../utils";
import useVisita, { LIMITE_POR_BUSCA_VISITAS } from "../hooks/useVisita";
import useFiltros from "../hooks/useFiltros";

export default function Visitas() {
    //HOOKS
    const { buscar, estaBuscando, cancelar, buscaComErro } = useVisita();
    const { getFilter } = useFiltros("visitas");

    //CONTEXTOS
    const { carregandoUsuario, podeAdicionarVisita, usuario, podeVisualizarVisita } = useContext(ContextUser);

    //ESTADOS
    const [visitas, setVisitas] = useState<tVisita[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);

    const [visitaEditando, setVisitaEditando] = useState<tVisita | null>(null);
    const [visitaDeletando, setVisitaDeletando] = useState<tVisita | null>(null);
    const [mostrarOffcanvasAdicionar, setMostrarOffcanvasAdicionar] = useState(false);

    //VARIAVEIS
    const vendedorIdSelecionado = getFilter("vendedor") ?? undefined;
    const mesInicial = utils.deStringPara<tPossivelMes>(getFilter("mesInicial"), "number");
    const mesFinal = utils.deStringPara<tPossivelMes>(getFilter("mesFinal"), "number");
    const anoSelecionado = utils.deStringPara<number>(getFilter("ano"), "number");

    //EVENTOS
    const buscarVisita = useCallback(
        async (pagina?: number) => {
            try {
                const { dataFinalSelecionada, dataInicialSelecionada } = utils.retornaDatasFiltroMesAno(
                    { inicio: mesInicial, fim: mesFinal },
                    anoSelecionado
                );

                const where = {
                    vendedorId: vendedorIdSelecionado,
                    dataInicio: dataInicialSelecionada,
                    dataFim: dataFinalSelecionada,
                };
                if (!podeVisualizarVisita(where)) return 0;

                const novasVisitas = await buscar({
                    limit: LIMITE_POR_BUSCA_VISITAS,
                    page: pagina,
                    where,
                    sortBy: "dataVisita",
                    sortType: "asc",
                });
                if (pagina === undefined) {
                    setVisitas(novasVisitas);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setVisitas((atuais) => [...atuais, ...novasVisitas]);
                return novasVisitas.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [buscar, vendedorIdSelecionado, mesFinal, mesInicial, anoSelecionado, podeVisualizarVisita]
    );

    useEffect(() => {
        buscarVisita().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarVisita]);

    const handleOnClickAdd = useCallback(() => setMostrarOffcanvasAdicionar(true), []);
    const handleOnClickEdit = useCallback((visita: tVisita) => setVisitaEditando(visita), []);
    const handleOnClickDelete = useCallback((visita: tVisita) => setVisitaDeletando(visita), []);

    //TODO: As notas precisam ser verificadas como as vendas?
    //    const handleOnMudarEstado = useCallback(
    //        async (visita: tVisita) => {
    //            try {
    //                setProcessando(true);
    //                const novaVisita: tNovaVisita = {
    //                    vendedorId: visita.vendedorId,
    //                    clienteId: visita.clienteId,
    //                    dataVisita: visita.dataVisita,
    //                    gastos: visita.gastos,
    //                    carroEmpresa: visita.kmRodados,
    //                    kmRodados: visita.kmRodados,
    //                };
    //                await editar({ id: visita.id, ...novaVisita });
    //
    //                buscarVisita().catch((err) => console.error(err));
    //            } catch (err) {
    //                console.error(err);
    //            } finally {
    //                setProcessando(false);
    //            }
    //        },
    //        [editar, buscarVisita, setProcessando]
    //    );

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroAnos />
                        <FiltroMes />
                        <FiltroVendedores />
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarVisita({ vendedorId: usuario?.vendedorUser?.id }) && (
                            <BotaoAdicionarDado texto="Visita" onClickAdd={handleOnClickAdd} />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || carregandoUsuario || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarVisita}
                >
                    {!estaBuscando && !buscaComErro && visitas.length < 1 ? (
                        <div className="w-100 text-center text-secondary fs-5 mt-3">Nenhuma visita existente</div>
                    ) : (
                        <TabelaVisita visitas={visitas} onEdit={handleOnClickEdit} onDelete={handleOnClickDelete} />
                    )}
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasVisita
                tipo="criar"
                mostrarForm={mostrarOffcanvasAdicionar}
                onCancel={() => {
                    setMostrarOffcanvasAdicionar(false);
                    cancelar();
                }}
                onTerminou={() => buscarVisita().catch((err) => console.error(err))}
            />

            <OffcanvasVisita
                tipo="editar"
                mostrarForm={visitaEditando !== null}
                onCancel={() => {
                    setVisitaEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVisitaEditando(null);
                    buscarVisita().catch((err) => console.error(err));
                }}
                dados={visitaEditando}
            />

            <OffcanvasVisita
                tipo="deletar"
                mostrarForm={visitaDeletando !== null}
                onCancel={() => {
                    setVisitaDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVisitaDeletando(null);
                    buscarVisita().catch((err) => console.error(err));
                }}
                dados={visitaDeletando}
            />
        </>
    );
}
