import { useCallback, useContext, useEffect, useState } from "react";
import {
    BotaoAdicionarDado,
    FiltroCategorias,
    FiltroTiposProdServ,
    OffcanvasProduto,
    TabelaProduto,
} from "../components";
import { LayoutTabela } from "../layouts";
import { tProduto } from "../interfaces";
import { ContextUser } from "../contexts/ContextUser";
import useProduto, { LIMITE_POR_BUSCA_PRODUTO } from "../hooks/useProduto";
import useFiltros from "../hooks/useFiltros";

export default function Produtos() {
    //HOOKS
    const { estaBuscando, buscar, cancelar, buscaComErro } = useProduto();
    const { getFilter } = useFiltros();

    //CONTEXTOS
    const { podeAdicionarProduto } = useContext(ContextUser);

    //ESTADOS
    const [produtos, setProdutos] = useState<tProduto[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);

    const [produtoEditando, setProdutoEditando] = useState<tProduto | null>(null);
    const [produtoDeletando, setProdutoDeletando] = useState<tProduto | null>(null);
    const [mostrarOffcanvasAdd, setMostrarOffcanvasAdd] = useState(false);

    //VARIABLES
    const tipoProdServIdSelecionado = getFilter("tipoProdServ") ?? undefined;
    const categoriaIdSelecionada = getFilter("categoria") ?? undefined;

    //EVENTOS
    const buscarProdutos = useCallback(
        async (pagina?: number) => {
            try {
                const novosProdutos = await buscar({
                    limit: LIMITE_POR_BUSCA_PRODUTO,
                    page: pagina,
                    where: {
                        categoriaId: categoriaIdSelecionada,
                        tipoProdutoServicoId: tipoProdServIdSelecionado,
                    },
                });
                if (pagina === undefined) {
                    setProdutos(novosProdutos);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setProdutos((atuais) => [...atuais, ...novosProdutos]);
                return novosProdutos.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [buscar, categoriaIdSelecionada, tipoProdServIdSelecionado]
    );

    useEffect(() => {
        buscarProdutos().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarProdutos, categoriaIdSelecionada, tipoProdServIdSelecionado]);

    const handleOnClickAdd = useCallback(() => setMostrarOffcanvasAdd(true), []);
    const handleOnClickDelete = useCallback((produto: tProduto) => setProdutoDeletando(produto), []);
    const handleOnClickEdit = useCallback((produto: tProduto) => {
        setProdutoEditando(produto);
    }, []);

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroCategorias />
                        <FiltroTiposProdServ />
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarProduto({}) && (
                            <BotaoAdicionarDado texto="Produto" onClickAdd={handleOnClickAdd} />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarProdutos}
                >
                    {!estaBuscando && !buscaComErro && produtos.length < 1 ? (
                        <div className="w-100 text-center text-secondary fs-5 mt-3">Nenhum produto existente</div>
                    ) : (
                        <TabelaProduto produtos={produtos} onEdit={handleOnClickEdit} onDelete={handleOnClickDelete} />
                    )}
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasProduto
                tipo="criar"
                mostrarForm={mostrarOffcanvasAdd}
                onCancel={() => {
                    setMostrarOffcanvasAdd(false);
                    cancelar();
                }}
                onTerminou={() => buscarProdutos().catch((err) => console.error(err))}
            />

            <OffcanvasProduto
                tipo="editar"
                mostrarForm={produtoEditando !== null}
                onCancel={() => {
                    setProdutoEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setProdutoEditando(null);
                    buscarProdutos().catch((err) => console.error(err));
                }}
                dados={produtoEditando}
            />

            <OffcanvasProduto
                tipo="deletar"
                mostrarForm={produtoDeletando !== null}
                onCancel={() => {
                    setProdutoDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setProdutoDeletando(null);
                    buscarProdutos().catch((err) => console.error(err));
                }}
                dados={produtoDeletando}
            />
        </>
    );
}
