import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useState } from "react";
import useFiltros from "../../hooks/useFiltros";

export default function FiltroAnos() {
    //HOOKS
    const { getFilter, setFilter } = useFiltros();

    //ESTADOS
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);

    //VARIAVEIS
    const anoSelecionado = getFilter("ano");

    const filtrosBuscados =
        pesquisa === undefined
            ? filtros
            : filtros.filter((filtro) => filtro.toLowerCase().includes(pesquisa.toLowerCase()));

    return (
        <FiltroSelect titulo="Ano" texto={anoSelecionado ?? undefined} onSearch={setPesquisa}>
            <ListGroup className="small">
                {filtrosBuscados.map((filtro) => (
                    <ListGroup.Item
                        className={`text-center my-item-filtro ${anoSelecionado === filtro ? "bg-light" : ""}`}
                        key={filtro}
                        onClick={() => setFilter("ano", filtro)}
                    >
                        {filtro}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </FiltroSelect>
    );
}

const dataAtual = new Date();
const ANO_INICIAL = 2024;
const ANO_ATUAL = dataAtual.getFullYear();
const filtros = new Array(ANO_ATUAL - ANO_INICIAL + 2).fill(0).map((_, index) => (ANO_INICIAL + index).toString());
