import { useCallback, useContext, useState } from "react";
import { tProduto } from "../../interfaces";
import { Tabela } from "./Tabela";
import { ContextUser } from "../../contexts/ContextUser";
import ModalAuditoriaProduto from "../modals/ModalAuditoriaProduto";
import "./styles.css";

interface ITabelaProdutoProps {
    produtos: tProduto[];
    onEdit: (produto: tProduto) => void;
    onDelete: (produto: tProduto) => void;
}

export default function TabelaProduto(props: ITabelaProdutoProps) {
    //CONTEXTOS
    const { podeEditarProduto, podeDeletarProduto, podeVisualizarAuditoria } = useContext(ContextUser);

    //ESTADOS
    const [produtoAuditando, setProdutoAuditando] = useState<tProduto | null>(null);

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((produto: tProduto) => setProdutoAuditando(produto), []);
    const handleOnHideAuditoria = useCallback(() => setProdutoAuditando(null), []);

    return (
        <>
            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        <th>Produto</th>
                        <th>Fabricante</th>
                        <th>PartNumber</th>
                        <th>Categoria</th>
                        <th>Tipo Produto/Serviço</th>
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.produtos}
                    campoUnico="id"
                    campoReferencia="name"
                    onEdit={podeEditarProduto({}) ? props.onEdit : undefined}
                    onDelete={podeDeletarProduto({}) ? props.onDelete : undefined}
                    onClickAuditoria={
                        podeVisualizarAuditoria({ tabela: "PRODUTO" }) ? handleOnClickOpenAuditoria : undefined
                    }
                >
                    {(produto: tProduto) => (
                        <>
                            <td>{produto.name}</td>
                            <td>{produto.fabricante.name}</td>
                            <td>{produto.partNumber}</td>
                            <td>{produto.categoria.name}</td>
                            <td>{produto.tipoProdutoServico.name}</td>
                        </>
                    )}
                </Tabela.Body>
            </Tabela.Table>

            <ModalAuditoriaProduto
                produto={produtoAuditando}
                show={produtoAuditando !== null}
                onHide={handleOnHideAuditoria}
            />
        </>
    );
}
