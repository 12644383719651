import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import InputDate from "../inputs/InputDate";
import { v4 } from "uuid";
import { PartialEntity, tVisita } from "../../interfaces";
import SelectVendedor from "../selects/SelectVendedor";
import SelectCliente from "../selects/SelectCliente";
import BotaoFechar from "../botoes/BotaoFechar";
import BotaoLabel from "../botoes/BotaoLabel";
import { OffcanvasNovoDado } from "../offcanvas/OffcanvasNovoDado";
import FormVisitaGasto, { tNovoGasto } from "./FormVisitaGasto";
import InputTextArea from "../inputs/InputTextArea";

//TYPES
export type tNovaVisita = PartialEntity<
    tVisita,
    "dataVisita" | "vendedorId" | "carroEmpresa" | "kmInicial" | "kmFinal" | "clienteId" | "comentarios" | "remoto"
> & {
    gastos: tNovoGasto[];
};

const FORM_GASTO = "FORM_GASTO";

export interface IFormVisitaProps {
    formId?: string;
    onSubmit: (visita: tNovaVisita) => void | Promise<void>;
    valoresIniciais: tNovaVisita;
}

//FORM
export default function FormVisita(props: IFormVisitaProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //ESTADOS
    const [gastos, setGastos] = useState<tNovoGasto[]>([]);
    const [showOffcanvasAddGasto, setShowOffcanvasAddGasto] = useState(false);
    const [idxGastoEditando, setIdxGastoEditando] = useState<number | null>(null);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    useEffect(() => {
        setGastos(valoresIniciais.gastos);
    }, [valoresIniciais]);

    const onSubmitFormik = useCallback(
        async (visita: tNovaVisita, helpers: FormikHelpers<tNovaVisita>) => {
            try {
                await onSubmit({ ...visita, gastos });
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo, gastos]
    );

    const handleOnClickAddGasto = useCallback(() => setShowOffcanvasAddGasto(true), []);
    const handleOnClickCancelAddGasto = useCallback(() => setShowOffcanvasAddGasto(false), []);
    const handleOnClickEditGasto = useCallback((index: number) => setIdxGastoEditando(index), []);
    const handleOnClickDeleteGasto = useCallback((index: number) => {
        setGastos((current) => current.filter((_, idx) => idx !== index));
    }, []);

    const handleOnSubmitGastos = useCallback(
        (gasto: tNovoGasto) => {
            if (idxGastoEditando === null) {
                setGastos((current) => [...current, gasto]);
                return setShowOffcanvasAddGasto(false);
            }

            setGastos((current) => current.map((g, idx) => (idx === idxGastoEditando ? gasto : g)));
            setIdxGastoEditando(null);
        },
        [idxGastoEditando]
    );

    return (
        <>
            <Formik
                validateOnChange={false}
                validationSchema={yupEsquemaVisita}
                onSubmit={onSubmitFormik}
                initialValues={valoresIniciais}
            >
                {({ handleSubmit, values, errors, setValues }) => {
                    return (
                        <Form id={formId} onSubmit={handleSubmit}>
                            <Row className="gap-3">
                                <Form.Group as={Col} sm="12" className="text-end">
                                    <Form.Label className="mb-0">
                                        <Stack className="w-100 d-flex align-items-end">
                                            <span className="mb-1">Vendedor</span>
                                            <SelectVendedor
                                                idSelecionado={values.vendedorId}
                                                setIdSelecionado={(id) =>
                                                    setValues({ ...values, vendedorId: id as string })
                                                }
                                            />
                                        </Stack>
                                    </Form.Label>
                                    <Form.Control className="d-none" isInvalid={!!errors.vendedorId} />
                                    <Form.Control.Feedback type="invalid">{errors.vendedorId}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm="12" className="text-end">
                                    <Form.Label className="mb-0">
                                        <Stack className="w-100 d-flex align-items-end">
                                            <span className="mb-1">Cliente</span>
                                            <SelectCliente
                                                setIdSelecionado={(id) =>
                                                    setValues({ ...values, clienteId: id as string })
                                                }
                                                idSelecionado={values.clienteId}
                                            />
                                        </Stack>
                                    </Form.Label>
                                    <Form.Control className="d-none" isInvalid={!!errors.clienteId} />
                                    <Form.Control.Feedback type="invalid">{errors.clienteId}</Form.Control.Feedback>
                                </Form.Group>

                                <Col sm="12" className="border-top pt-2">
                                    <Row>
                                        <Col sm="12" className="mb-1">
                                            <h6>Visita remota ou presencial?</h6>
                                        </Col>
                                        <Form.Group as={Col} sm="auto" controlId={v4()} className="mb-2 float-end">
                                            <Form.Label className="mb-1">Remota</Form.Label>
                                            <Form.Check
                                                role="button"
                                                type="switch"
                                                id={v4()}
                                                onChange={(e) => setValues({ ...values, remoto: e.target.checked })}
                                                label={values.remoto ? "Sim" : "Não"}
                                                checked={values.remoto ?? false}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.remoto}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Col>

                                {!values.remoto && (
                                    <>
                                        <Col sm="12" className="border-top pt-2">
                                            <Row>
                                                <Col sm="12" className="mb-1">
                                                    <h6>Carro</h6>
                                                </Col>
                                                <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                                    <Form.Label className="mb-1">Usou o carro da empresa?</Form.Label>
                                                    <Form.Check
                                                        role="button"
                                                        type="switch"
                                                        id={v4()}
                                                        onChange={(e) =>
                                                            setValues({ ...values, carroEmpresa: e.target.checked })
                                                        }
                                                        label={values.carroEmpresa ? "Sim" : "Não"}
                                                        checked={values.carroEmpresa ?? false}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.carroEmpresa}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} sm="6" controlId={v4()} className="mb-2">
                                                    <Form.Label className="mb-1">Km Inicial</Form.Label>
                                                    <Form.Control
                                                        required
                                                        min={0}
                                                        type="number"
                                                        value={values.kmInicial ?? 0}
                                                        onChange={(e) =>
                                                            setValues({
                                                                ...values,
                                                                kmInicial: Number(e.target.value),
                                                            })
                                                        }
                                                        isInvalid={!!errors.kmInicial}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.kmInicial}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} sm="6" controlId={v4()} className="mb-2">
                                                    <Form.Label className="mb-1">Km Final</Form.Label>
                                                    <Form.Control
                                                        required
                                                        min={0}
                                                        type="number"
                                                        value={values.kmFinal ?? 0}
                                                        onChange={(e) =>
                                                            setValues({
                                                                ...values,
                                                                kmFinal: Number(e.target.value),
                                                            })
                                                        }
                                                        isInvalid={!!errors.kmFinal}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.kmFinal}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                        </Col>

                                        <Col sm="12" className="border-top pt-2">
                                            <Row>
                                                <Col sm="12" className="mb-1">
                                                    <h6>Gastos</h6>
                                                </Col>
                                                <Form.Control isInvalid={!!errors.gastos} className="d-none" />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gastos as string}
                                                </Form.Control.Feedback>
                                                <Col sm="12">
                                                    <ListaVendaGastos
                                                        gastos={gastos}
                                                        onClickAdd={handleOnClickAddGasto}
                                                        onClickDelete={handleOnClickDeleteGasto}
                                                        onClickEdit={handleOnClickEditGasto}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )}

                                <Form.Group as={Col} sm="12" controlId={v4()} className="border-top pt-2">
                                    <Form.Label>Data da visita</Form.Label>
                                    <InputDate
                                        valor={values.dataVisita}
                                        onAccept={(dataVisita) => setValues({ ...values, dataVisita })}
                                        isInvalid={!!errors.dataVisita}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.dataVisita as string}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} sm="12" controlId={v4()}>
                                    <Form.Label className="mb-1">Comentarios</Form.Label>
                                    <InputTextArea
                                        rows={4}
                                        max={256}
                                        value={values.comentarios ?? ""}
                                        onChange={(e) => setValues({ ...values, comentarios: e.target.value })}
                                        isInvalid={!!errors.comentarios}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.comentarios}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>

            <OffcanvasNovoDado.Root show={showOffcanvasAddGasto}>
                <OffcanvasNovoDado.Header>
                    <h4>Adicionar Gasto à Visita</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: handleOnClickCancelAddGasto }}
                    acceptButtonProps={{ type: "submit", form: FORM_GASTO }}
                >
                    <FormVisitaGasto
                        onSubmit={handleOnSubmitGastos}
                        valoresIniciais={{ tipoGasto: "ALIMENTACAO", nota: "", valor: 0 }}
                        formId={FORM_GASTO}
                    />
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>

            <OffcanvasNovoDado.Root show={idxGastoEditando !== null}>
                <OffcanvasNovoDado.Header>
                    <h4>Editar Gasto da Visita</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: () => setIdxGastoEditando(null) }}
                    acceptButtonProps={{ type: "submit", form: FORM_GASTO, children: "Editar" }}
                >
                    {idxGastoEditando !== null && (
                        <FormVisitaGasto
                            onSubmit={handleOnSubmitGastos}
                            valoresIniciais={gastos[idxGastoEditando]}
                            formId={FORM_GASTO}
                        />
                    )}
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>
        </>
    );
}

//FUNCOES AUXILIARES
function ListaVendaGastos(props: {
    gastos: tNovoGasto[];
    onClickAdd: () => void;
    onClickEdit: (index: number) => void;
    onClickDelete: (index: number) => void;
}) {
    const totalGasto = props.gastos.reduce((acc, gasto) => (acc += gasto.valor), 0);

    return (
        <Row className="gap-2">
            {props.gastos.map((gasto, index) => {
                return (
                    <Col sm="12" key={v4()}>
                        <Stack className="d-flex w-100 position-relative border rounded shadow-sm p-2">
                            <div className="position-absolute end-0 top-0 mt-1 me-1 z-1">
                                <BotaoFechar onClick={() => props.onClickDelete(index)} />
                            </div>
                            <BotaoLabel
                                variant="info"
                                texto={utils.retornaTextoTipoGasto(gasto.tipoGasto)}
                                onClick={() => props.onClickEdit(index)}
                            />
                            <div className="d-flex w-100 justify-content-between">
                                <small>Valor</small>
                                <small>{utils.retornaValorMonetario(gasto.valor, "BRL")}</small>
                            </div>
                        </Stack>
                    </Col>
                );
            })}
            <Col sm="12" className="text-secondary-dark">
                <small>Gastos totais: {utils.retornaValorMonetario(totalGasto, "BRL")}</small>
            </Col>
            <Col sm="12" className="text-end">
                <Button className="rounded-pill" size="sm" onClick={props.onClickAdd}>
                    Adicionar
                </Button>
            </Col>
        </Row>
    );
}

export const yupEsquemaVisita: yup.ObjectSchema<tNovaVisita> = yup.object({
    vendedorId: yup.string().required("Este campo é necessário."),
    clienteId: yup.string().required("Este campo é necessário."),
    dataVisita: yup.date().required("Este campo é necessário."),
    carroEmpresa: yup.boolean().required("Este campo é necessário").nullable(),
    kmInicial: yup.number().required("Este campo é necessário").nullable(),
    kmFinal: yup.number().required("Este campo é necessário").nullable(),
    gastos: yup.array().required("Este campo é necessário."),
    comentarios: yup.string().required("Este campo é necessário.").nullable(),
    remoto: yup.boolean().required("Este campo é necessário."),
});
