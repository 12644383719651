import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tMetaAlternativa, tTipoMetaAlternativa } from "../../interfaces";
import { v4 } from "uuid";

//TYPES
export type tNovaMetaAlternativa = PartialEntity<tMetaAlternativa, "tipoMeta" | "valor">;

export interface FormMetaAlternativaProps {
    formId?: string;
    onSubmit: (metaAlternativa: tNovaMetaAlternativa) => void | Promise<void>;
    valoresIniciais: tNovaMetaAlternativa;
}

//FORM
export default function FormMetaAlternativa({ formId, onSubmit, valoresIniciais }: FormMetaAlternativaProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (metaAlternativa: tNovaMetaAlternativa, helpers: FormikHelpers<tNovaMetaAlternativa>) => {
            try {
                await onSubmit(metaAlternativa);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaMetaAlternativa}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                <Form.Label className="mb-1">Tipo da Meta</Form.Label>
                                <Form.Select
                                    isInvalid={!!errors.tipoMeta}
                                    required
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            tipoMeta: e.target.value as tTipoMetaAlternativa,
                                        })
                                    }
                                    value={values.tipoMeta}
                                >
                                    {utils.retornaArrayTipoMetaAlternativa().map((tipoMeta) => (
                                        <option key={tipoMeta} value={tipoMeta}>
                                            {utils.retornaTextoTipoMetaAlternativa(tipoMeta)}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.tipoMeta}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm="12" className="mb-2" controlId={v4()}>
                                {values.tipoMeta === "VISITA" ? (
                                    <>
                                        <Form.Label className="mb-1">Meta de visitas</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={values.valor}
                                            onChange={(e) => setValues({ ...values, valor: Number(e.target.value) })}
                                            isInvalid={!!errors.valor}
                                        />
                                    </>
                                ) : undefined}

                                <Form.Control.Feedback type="invalid">{errors.valor}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaMetaAlternativa: yup.ObjectSchema<tNovaMetaAlternativa> = yup.object({
    tipoMeta: yup.mixed<tTipoMetaAlternativa>().oneOf(["VISITA"]).required("Este campo é necessário."),
    valor: yup.number().required("Esta campo é necessário.").notOneOf([0], "O valor não pode ser zero."),
});
