import { Button, ButtonProps } from "react-bootstrap";
import "./styles.css";

export default function Botao(props: ButtonProps) {
    return (
        <Button variant="none" className={`my-button text-start ${props.className}`} {...props}>
            {props.children}
        </Button>
    );
}
