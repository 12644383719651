import LayoutTabelaRoot from "./LayoutTabelaRoot";
import LayoutTabelaBody from "./LayoutTabelaBody";
import LayoutTabelaHeader from "./LayoutTabelaHeader";
import LayoutTabelaHeaderLeft from "./LayoutTabelaHeaderLeft";
import LayoutTabelaHeaderRight from "./LayoutTabelaHeaderRight";

export const LayoutTabela = {
    Root: LayoutTabelaRoot,
    Body: LayoutTabelaBody,
    Header: LayoutTabelaHeader,
    HeaderRight: LayoutTabelaHeaderRight,
    HeaderLeft: LayoutTabelaHeaderLeft,
};
