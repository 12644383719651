import { tVisita } from "../../interfaces";
import { Tabela } from "./Tabela";
import React, { useCallback, useContext, useState } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import { ContextAlerta } from "../../contexts/ContextAlert";
import ModalAuditoriaVisita from "../modals/ModalAuditoriaVisita";
import SpanVisitaGasto from "../span/SpanVisitaGasto";
import SpanVisitaKilometragem from "../span/SpanVisitaKilometragem";
import "./styles.css";
import utils from "../../utils";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { v4 } from "uuid";

interface ITabelaVisitaProps {
    visitas: tVisita[];
    onEdit: (visita: tVisita) => void;
    onDelete: (visita: tVisita) => void;
}

export default function TabelaVisita({ onEdit, onDelete, ...props }: ITabelaVisitaProps) {
    //CONTEXTOS
    const { podeEditarVisita, podeDeletarVisita, podeVisualizarAuditoria } = useContext(ContextUser);
    const { setAviso } = useContext(ContextAlerta);

    //ESTADOS
    const [visitaAuditando, setVisitaAuditando] = useState<tVisita | null>(null);

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((visita: tVisita) => setVisitaAuditando(visita), []);
    const handleOnHideAuditoria = useCallback(() => setVisitaAuditando(null), []);

    const handleOnEdit = useCallback(
        (visita: tVisita) => {
            if (!podeEditarVisita({ vendedorId: visita.vendedorId }))
                return setAviso("Você não possui permissão para realizar esta ação.");
            return onEdit(visita);
        },
        [onEdit, podeEditarVisita, setAviso]
    );
    const handleOnDelete = useCallback(
        (visita: tVisita) => {
            if (!podeDeletarVisita({ ...visita }))
                return setAviso("Você não possui permissão para realizar esta ação.");
            return onDelete(visita);
        },
        [onDelete, podeDeletarVisita, setAviso]
    );
    const handleOnAuditoria = useCallback(
        (visita: tVisita) => {
            if (!podeVisualizarAuditoria({ tabela: "VISITA" }))
                return setAviso("Você não possui permissão para realizar esta ação.");
            return handleOnClickOpenAuditoria(visita);
        },
        [podeVisualizarAuditoria, handleOnClickOpenAuditoria, setAviso]
    );

    return (
        <>
            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        <th>Data da Visita</th>
                        <th>Vendedor</th>
                        <th>Cliente</th>
                        <th>Visita Remota</th>
                        <th>Carro da empresa</th>
                        <th>Comentários</th>
                        <th>Kilometragem</th>
                        <th>Gastos e notas</th>
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.visitas}
                    campoUnico="id"
                    onEdit={handleOnEdit}
                    onDelete={handleOnDelete}
                    onClickAuditoria={handleOnAuditoria}
                >
                    {(visita: tVisita) => (
                        <>
                            <td className="position-relative">
                                {visita.dataVisita.toLocaleDateString("pt-Br", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}
                            </td>
                            <td>{visita.vendedor.user.name}</td>
                            <td>{visita.cliente.name}</td>
                            <td>{visita.remoto ? "Sim" : "Não"}</td>
                            <td>{utils.isNil(visita.carroEmpresa) ? "----" : visita.carroEmpresa ? "Sim" : "Não"}</td>
                            <td>
                                <ElementoComentario comentarios={visita.comentarios} />
                            </td>
                            <td>
                                <SpanVisitaKilometragem visita={visita} />
                            </td>
                            <td>
                                <SpanVisitaGasto visita={visita} />
                            </td>
                        </>
                    )}
                </Tabela.Body>
            </Tabela.Table>

            <ModalAuditoriaVisita
                visita={visitaAuditando}
                show={visitaAuditando !== null}
                onHide={handleOnHideAuditoria}
            />
        </>
    );
}

//TODO: Isto pode virar um span como os outros que pode ser reutilizável
export function ElementoComentario(props: { comentarios?: string | null }) {
    return utils.isNil(props.comentarios) || (props.comentarios as string).trim() === "" ? (
        <span className="pe-none">--- Nenhum comentário ---</span>
    ) : (
        <OverlayTrigger
            flip
            placement="bottom"
            trigger={["click", "focus"]}
            rootClose
            overlay={
                <Popover className="shadow">
                    <Popover.Body className="p-2">
                        {(props.comentarios as string).split("\n").map((texto?: string) =>
                            !texto ? (
                                <br key={v4()} />
                            ) : (
                                <React.Fragment key={v4()}>
                                    <span>{texto}</span>
                                    <br />
                                </React.Fragment>
                            )
                        )}
                    </Popover.Body>
                </Popover>
            }
        >
            <span className="text-info" role="button">
                <i className="bi bi-file-earmark-text-fill me-2" />
                Clique para visualizar
            </span>
        </OverlayTrigger>
    );
}
