import { tFornecedor } from "../../interfaces";
import { Tabela } from "./Tabela";
import utils from "../../utils";
import "./styles.css";
import { useCallback, useContext, useState } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import ModalAuditoriaFornecedor from "../modals/ModalAuditoriaFornecedor";

interface ITabelaFornecedorProps {
    fornecedores: tFornecedor[];
    onEdit: (fornecedor: tFornecedor) => void;
    onDelete: (fornecedor: tFornecedor) => void;
}

export default function TabelaFornecedor(props: ITabelaFornecedorProps) {
    //CONTEXTOS
    const { podeEditarFornecedor, podeDeletarFornecedor, podeVisualizarAuditoria } = useContext(ContextUser);

    //ESTADOS
    const [fornecedorAuditando, setFornecedorAuditando] = useState<tFornecedor | null>(null);

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((fornecedor: tFornecedor) => setFornecedorAuditando(fornecedor), []);
    const handleOnHideAuditoria = useCallback(() => setFornecedorAuditando(null), []);

    return (
        <>
            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        <th>Nome do fornecedor</th>
                        <th>CNPJ</th>
                        <th>E-mail do responsável</th>
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.fornecedores}
                    campoUnico="id"
                    campoReferencia="name"
                    onEdit={podeEditarFornecedor({}) ? props.onEdit : undefined}
                    onDelete={podeDeletarFornecedor({}) ? props.onDelete : undefined}
                    onClickAuditoria={
                        podeVisualizarAuditoria({ tabela: "FORNECEDOR" }) ? handleOnClickOpenAuditoria : undefined
                    }
                >
                    {(fornecedor: tFornecedor) => (
                        <>
                            <td>{fornecedor.name}</td>
                            <td>{utils.retornaCNPJFormatado(fornecedor.cnpj)}</td>
                            <td>{fornecedor.email}</td>
                        </>
                    )}
                </Tabela.Body>
            </Tabela.Table>

            <ModalAuditoriaFornecedor
                fornecedor={fornecedorAuditando}
                show={fornecedorAuditando !== null}
                onHide={handleOnHideAuditoria}
            />
        </>
    );
}
