import { Col, Form, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tTipoFaturamento, tTipoMoeda, tVendaProduto } from "../../interfaces";
import { v4 } from "uuid";
import InputValores from "../inputs/InputValores";
import InputPorcentagem from "../inputs/InputPorcentagem";
import SelectProduto, { ISelectProdutoProps } from "../selects/SelectProduto";
import SelectFornecedor from "../selects/SelectFornecedor";
import { INFO_MARKUP_LIQUIDO } from "./FormVendaServico";

//TYPES
export type tNovaVendaProduto = PartialEntity<
    tVendaProduto,
    | "tipoMoeda"
    | "cotacao"
    | "dedutiveisDistribuidor"
    | "valorCustoUnit"
    | "quantidade"
    | "markup"
    | "tipoFaturamento"
    | "impostoNota"
    | "produtoId"
> & { fornecedoresId: string[] };

export interface IFormVendaProdutoProps extends ISelectProdutoProps {
    formId?: string;
    onSubmit: (vendaProduto: tNovaVendaProduto) => void | Promise<void>;
    valoresIniciais: tNovaVendaProduto;
}

//FORM
export default function FormVendaProduto({ formId, onSubmit, valoresIniciais, ...rest }: IFormVendaProdutoProps) {
    //CONTEXTOS
    const { setPerigo, setAviso } = useContext(ContextAlerta);

    //VARIAVEIS
    const onSubmitFormik = useCallback(
        async (vendaProduto: tNovaVendaProduto, helpers: FormikHelpers<tNovaVendaProduto>) => {
            try {
                await onSubmit(vendaProduto);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaVendaProduto}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                const cotacaoDesabilitada = values.tipoMoeda === "BRL";
                const valorCusto = values.quantidade * values.valorCustoUnit;
                const valorMarkup = (values.markup * valorCusto) / 100;

                const valorVenda = valorCusto + (values.markup * valorCusto) / 100;

                const valorMarkupLiquido = valorMarkup - (values.dedutiveisDistribuidor ?? 0);
                const valorNota =
                    values.tipoFaturamento === "DIRETO"
                        ? (values.impostoNota * valorMarkupLiquido) / 100
                        : (values.impostoNota * valorVenda) / 100;

                const valorImpostos = 0 + valorNota;
                const valorMargem = valorMarkupLiquido - valorImpostos;

                const atualizaMarkupPorValorVenda = (valor: number) => {
                    if (valorCusto === 0) return setAviso("O custo não pode ser 0.");
                    const novoValorMarkup = valor - valorCusto;
                    const novoMarkup = (novoValorMarkup * 100) / valorCusto;
                    setValues({ ...values, markup: novoMarkup });
                };
                const findPorcentMarkup = (valor: number) => {
                    if (valorCusto === 0) setAviso("O custo não pode ser 0.");
                    return valorCusto !== 0 ? (valor * 100) / valorCusto : 0;
                };
                const findPorcentImpNota = (valor: number) => {
                    switch (values.tipoFaturamento) {
                        case "DIRETO":
                            if (valorMarkupLiquido === 0) setAviso("O markup líquido não pode ser 0.");
                            return valorMarkupLiquido !== 0 ? (valor * 100) / valorMarkupLiquido : 0;
                        default:
                            if (valorVenda === 0 && values.tipoFaturamento === "REVENDA")
                                setAviso("O valor da venda não pode ser 0.");
                            return valorVenda !== 0 ? (valor * 100) / valorVenda : 0;
                    }
                };

                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row>
                            <Col sm="12" className="p-0">
                                <Row className="p-2 pb-4 pt-4 border-bottom">
                                    <Col sm="12">
                                        <h6>Produto e Faturamento</h6>
                                    </Col>
                                    <Col sm="12" className="text-end mb-1">
                                        <Form.Label className="mb-1">Produto</Form.Label>
                                        <SelectProduto
                                            {...rest}
                                            idSelecionado={values.produtoId}
                                            setIdSelecionado={(id) => setValues({ ...values, produtoId: id ?? "" })}
                                        />
                                        <Form.Control isInvalid={!!errors.produtoId} className="d-none" />
                                        <Form.Control.Feedback type="invalid">{errors.produtoId}</Form.Control.Feedback>
                                    </Col>

                                    <Form.Group as={Col} sm="12" className="text-end mb-2">
                                        <Form.Label className="mb-0 w-100">
                                            <Stack className="w-100 d-flex align-items-end text-end">
                                                <span className="mb-1">Fornecedores</span>
                                                <SelectFornecedor
                                                    setIdsSelecionados={(fornecedoresId) =>
                                                        setValues({ ...values, fornecedoresId })
                                                    }
                                                    idsSelecionados={values.fornecedoresId}
                                                />
                                            </Stack>
                                        </Form.Label>
                                        <Form.Control className="d-none" isInvalid={!!errors.fornecedoresId} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.fornecedoresId}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} sm="12" controlId={v4()} className="mb-2">
                                        <Form.Label className="mb-1">Tipo de faturamento</Form.Label>
                                        <Form.Select
                                            isInvalid={!!errors.tipoFaturamento}
                                            required
                                            onChange={(e) =>
                                                setValues({
                                                    ...values,
                                                    tipoFaturamento: e.target.value as tTipoFaturamento,
                                                })
                                            }
                                            value={values.tipoFaturamento}
                                        >
                                            {utils.retornaArrayTipoFaturamento().map((tipoFaturamento) => (
                                                <option key={tipoFaturamento} value={tipoFaturamento}>
                                                    {utils.retornaTextoTipoFaturamento(tipoFaturamento)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.tipoFaturamento}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Col>
                            <Col sm="12" className="p-0">
                                <Row className="bg-light p-2 pt-4 pb-4 border-bottom">
                                    <Col sm="12">
                                        <h6>
                                            Valores da Venda (
                                            {utils.retornaTextoTipoFaturamento(values.tipoFaturamento)})
                                        </h6>
                                    </Col>
                                    <Form.Group as={Col} className="col-7 mb-2" controlId={v4()}>
                                        <Form.Label className="mb-1">Moeda</Form.Label>
                                        <Form.Select
                                            isInvalid={!!errors.tipoMoeda}
                                            required
                                            onChange={(e) =>
                                                setValues({ ...values, tipoMoeda: e.target.value as tTipoMoeda })
                                            }
                                            value={values.tipoMoeda}
                                        >
                                            {utils.retornaArrayTipoMoeda().map((tipoMoeda) => (
                                                <option key={tipoMoeda} value={tipoMoeda}>
                                                    {utils.retornaTextoTipoMoeda(tipoMoeda)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.tipoMoeda}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-5 mb-2" controlId={v4()}>
                                        <Form.Label className="mb-1">Cotação</Form.Label>
                                        <InputValores
                                            disabled={cotacaoDesabilitada}
                                            isInvalid={!!errors.cotacao}
                                            valor={values.cotacao ?? 0}
                                            moeda="BRL"
                                            onAccept={
                                                !cotacaoDesabilitada
                                                    ? (cotacao) => setValues({ ...values, cotacao })
                                                    : () => {}
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.cotacao}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-7 mt-1 mb-2" controlId={v4()}>
                                        <Form.Label className="mb-1 text-nowrap">Custo (unitário)</Form.Label>
                                        <InputValores
                                            required
                                            isInvalid={!!errors.valorCustoUnit}
                                            moeda={values.tipoMoeda}
                                            valor={values.valorCustoUnit}
                                            onAccept={(valorCustoUnit) => setValues({ ...values, valorCustoUnit })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.valorCustoUnit}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-5 mt-1 mb-2" controlId={v4()}>
                                        <Form.Label className="mb-1 text-nowrap">Quantidade</Form.Label>
                                        <Form.Control
                                            required
                                            min={1}
                                            isInvalid={!!errors.quantidade}
                                            type="number"
                                            value={values.quantidade}
                                            onChange={(e) =>
                                                setValues({ ...values, quantidade: Number(e.target.value) })
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.quantidade}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Col sm="12" className="text-secondary-dark">
                                        <small>
                                            Custo: {utils.retornaValorMonetario(valorCusto, values.tipoMoeda)}
                                        </small>
                                    </Col>

                                    <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                        <Form.Label className="mb-1 text-nowrap" role="button">
                                            Markup Bruto %
                                        </Form.Label>
                                        <InputPorcentagem
                                            isInvalid={!!errors.markup}
                                            required
                                            valor={values.markup}
                                            onAccept={(markup) => setValues({ ...values, markup })}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.markup}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                        <Form.Label className="mb-1 text-nowrap" role="button">
                                            Markup Bruto
                                        </Form.Label>
                                        <InputValores
                                            required
                                            moeda={values.tipoMoeda}
                                            valor={valorMarkup}
                                            onAccept={(valor) =>
                                                setValues({ ...values, markup: findPorcentMarkup(valor) })
                                            }
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-12 mb-2" controlId={v4()}>
                                        <Form.Label className="mb-1">Venda (unitário)</Form.Label>
                                        <InputValores
                                            valor={valorVenda}
                                            moeda={values.tipoMoeda}
                                            onAccept={(valorVenda) => {
                                                atualizaMarkupPorValorVenda(valorVenda);
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>

                            {values.tipoFaturamento === "DIRETO" && (
                                <Col sm="12" className="p-0 border-bottom">
                                    <Row className="p-2 pt-4 pb-4">
                                        <Col sm="12">
                                            <h6>Dedutíveis e Impostos de Fornecimento</h6>
                                        </Col>

                                        <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                            <Form.Label className="mb-1 text-nowrap" role="button">
                                                Dedutíveis distribuidor
                                            </Form.Label>
                                            <InputValores
                                                isInvalid={!!errors.dedutiveisDistribuidor}
                                                moeda={values.tipoMoeda}
                                                required
                                                valor={values.dedutiveisDistribuidor ?? 0}
                                                onAccept={(dedutiveisDistribuidor) =>
                                                    setValues({ ...values, dedutiveisDistribuidor })
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dedutiveisDistribuidor}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                            <Form.Label className="mb-1 text-nowrap" role="button">
                                                Markup Líquido
                                                <OverlayTrigger overlay={<Tooltip>{INFO_MARKUP_LIQUIDO}</Tooltip>}>
                                                    <i className="bi bi-question-circle-fill text-primary ms-1" />
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <InputValores
                                                required
                                                moeda={values.tipoMoeda}
                                                valor={valorMarkupLiquido}
                                                onAccept={(markupLiquido) =>
                                                    setValues({
                                                        ...values,
                                                        dedutiveisDistribuidor: valorMarkup - markupLiquido,
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                    </Row>
                                </Col>
                            )}

                            <Col sm="12" className="p-0">
                                <Row className="p-2 pt-4 pb-4">
                                    <Col sm="12">
                                        <h6>Tributos Referentes à Nota Fiscal da Revenda</h6>
                                    </Col>

                                    <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                        <Form.Label className="mb-1 text-nowrap" role="button">
                                            Imposto Nota %
                                        </Form.Label>
                                        <InputPorcentagem
                                            isInvalid={!!errors.impostoNota}
                                            required
                                            valor={values.impostoNota}
                                            onAccept={(impostoNota) => setValues({ ...values, impostoNota })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.impostoNota}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mt-1" sm="6" controlId={v4()}>
                                        <Form.Label className="mb-1 text-nowrap" role="button">
                                            Imposto Nota
                                        </Form.Label>
                                        <InputValores
                                            required
                                            moeda={values.tipoMoeda}
                                            valor={valorNota}
                                            onAccept={(valor) =>
                                                setValues({
                                                    ...values,
                                                    impostoNota: findPorcentImpNota(valor),
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    <Col sm="12" className="text-secondary-dark">
                                        <small>
                                            Impostos: {utils.retornaValorMonetario(valorImpostos, values.tipoMoeda)}
                                        </small>
                                    </Col>

                                    <Col sm="12" className="text-end">
                                        <span>
                                            Margem: {utils.retornaValorMonetario(valorMargem, values.tipoMoeda)}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaVendaProduto: yup.ObjectSchema<tNovaVendaProduto> = yup.object({
    produtoId: yup.string().required("Selecione um produto."),
    quantidade: yup.number().required("Este campo é necessário.").min(1, "A quantidade mínima deve ser 1."),
    dedutiveisDistribuidor: yup.number().required("Este campo é obrigatório."),
    impostoNota: yup.number().required("Este campo é obrigatório."),
    valorCustoUnit: yup
        .number()
        .required("Este campo é necessário.")
        .min(0.01, "O valor do custo unitário deve ser no mínimo 0.01"),
    markup: yup.number().required("Este campo é necessário."),
    tipoMoeda: yup
        .mixed<tTipoMoeda>()
        .oneOf(utils.retornaArrayTipoMoeda(), "Escolha um tipo válido.")
        .required("Selecione o tipo da moeda."),
    cotacao: yup
        .number()
        .required("Este campo é necessário.")
        .min(0.000001, "O valor da cotação deve ser no mínimo 0.000001"),
    tipoFaturamento: yup
        .mixed<tTipoFaturamento>()
        .oneOf(utils.retornaArrayTipoFaturamento())
        .required("Este campo é necessário."),
    fornecedoresId: yup
        .array(yup.string().required("Selecione um fornecedor."))
        .required("Selecione um fornecedor.")
        .min(1, "Selecione um fornecedor"),
});
