import { useCallback, useContext, useRef } from "react";
import Dropdown from "./dropdown";
import { Col, Form, Row } from "react-bootstrap";
import { v4 } from "uuid";
import BotaoCustom from "../botoes/BotaoCustom";
import { ContextTabela } from "../tables/Tabela/TabelaRoot";

export type tHeaderTable = { name: string; value: string; show: boolean };

export default function DropdownCustomizacaoTabela() {
    //CONTEXT
    const { headers, customizationsName, setHeaders } = useContext(ContextTabela);

    //REFS
    const itemRef = useRef<HTMLDivElement>(null);

    //EVENTS
    const handleOnSetShow = useCallback(
        (value: string, newState: boolean) => {
            const newConstumization = headers.map((header) =>
                header.value === value ? { ...header, show: newState } : header
            );

            setHeaders(newConstumization);
            localStorage.setItem(customizationsName, JSON.stringify(newConstumization));
        },
        [headers, customizationsName, setHeaders]
    );

    const handleOnSetUpOrDown = useCallback(
        (currentIndex: number, setUp: boolean) => {
            if (currentIndex === 0 && setUp) return;
            if (currentIndex === headers.length - 1 && !setUp) return;

            const targetIndex = setUp ? currentIndex - 1 : currentIndex + 1;
            const newConstumization = headers.map((header, idx) =>
                idx === targetIndex ? headers[currentIndex] : idx === currentIndex ? headers[targetIndex] : header
            );
            setHeaders(newConstumization);
            localStorage.setItem(customizationsName, JSON.stringify(newConstumization));
        },
        [headers, customizationsName, setHeaders]
    );

    return (
        <Dropdown.Root>
            <Dropdown.Item ref={itemRef} bootstrapNameIcon="table" className="gap-1">
                Customização
            </Dropdown.Item>
            <Dropdown.Menu rootClose placement="bottom-end" target={itemRef.current}>
                <Row role="div">
                    {headers.map((header, index) => (
                        <Header
                            key={header.value}
                            name={header.name}
                            show={header.show}
                            setShow={(newState) => handleOnSetShow(header.value, newState)}
                            setUp={() => handleOnSetUpOrDown(index, true)}
                            setDown={() => handleOnSetUpOrDown(index, false)}
                        />
                    ))}
                </Row>
            </Dropdown.Menu>
        </Dropdown.Root>
    );
}

//FUNCOES AUXILIARES
function Header(props: {
    name: string;
    show: boolean;
    setShow: (show: boolean) => void;
    setUp: () => void;
    setDown: () => void;
}) {
    return (
        <Col sm="12" className="position-relative">
            <Form.Check
                type={"checkbox"}
                id={v4()}
                label={props.name}
                checked={props.show}
                onChange={(e) => props.setShow(e.target.checked)}
            />
            <div className="d-flex position-absolute end-0 top-0 h-100 align-items-center bg-white me-2">
                <BotaoCustom onClick={props.setUp}>
                    <i className="bi bi-chevron-up text-dark" />
                </BotaoCustom>
                <BotaoCustom onClick={props.setDown}>
                    <i className="bi bi-chevron-down text-dark" />
                </BotaoCustom>
            </div>
        </Col>
    );
}
