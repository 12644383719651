import React, { useCallback, useRef, useContext, useState } from "react";
import { Col, Container, Overlay, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { tVenda } from "../../interfaces";
import "./styles.css";
import MyLink from "../links/MyLink";
import { routes } from "../../router";
import { v4 } from "uuid";
import utils from "../../utils";
import { ContextUser } from "../../contexts/ContextUser";

interface ISpanVendaVendedoresProps {
    venda: tVenda;
}
export default function SpanVendaVendedores(props: ISpanVendaVendedoresProps) {
    //ESTADOS
    const [mostrarInfo, setMostrarInfo] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const { podeVisualizarVendedor } = useContext(ContextUser);
    //VARIAVEIS
    const { venda } = props;
    const returnTextoVendedores = useCallback(() => {
        if ((venda.vendedores.length ?? 0) < 1) return "--- Nenhum ---";
        const nomes = venda.vendedores?.map(({ user }) => user.name);
        const texto = nomes?.join(", ");
        if ((texto?.length ?? 0) < 26) return texto;
        return texto?.slice(0, 26) + "...";
    }, [venda]);

    //EVENTOS
    const handleOnMostrarInfo = useCallback(() => setMostrarInfo(true), []);
    const handleOnEsconderInfo = useCallback(() => setMostrarInfo(false), []);

    return (
        <>
            <div
                className="d-flex w-100 overflow-hidden text-info"
                role="button"
                onClick={handleOnMostrarInfo}
                ref={divRef}
            >
                <OverlayTrigger placement="top" flip overlay={<Tooltip>Clique para saber mais</Tooltip>}>
                    <span className="text-nowrap">
                        {returnTextoVendedores()}
                        <i className="bi bi-caret-down-fill ms-1" />
                    </span>
                </OverlayTrigger>
            </div>

            <Overlay
                rootClose
                onHide={handleOnEsconderInfo}
                target={divRef.current}
                show={mostrarInfo}
                flip
                placement="bottom"
            >
                <Popover className="shadow">
                    <Popover.Body className="my-container-controle-borda p-2">
                        {venda.vendedores.map(({ user, id }) => {
                            const comissoesVendedor = venda.comissoes.filter(({ vendedorId }) => vendedorId === id);
                            return (
                                <React.Fragment key={id}>
                                    <Container fluid className="p-1">
                                        <Row className="my-child-controle-borda border-bottom border-bottom pb-2">
                                            <Col sm="12">
                                                {podeVisualizarVendedor({ id }) ? (
                                                    <MyLink to={routes.vendedor.replace(":id", id)}>{user.name}</MyLink>
                                                ) : (
                                                    <span className="fw-bold">{user.name}</span>
                                                )}
                                            </Col>
                                            <Col sm="12">Comissões</Col>
                                            {comissoesVendedor.map(
                                                ({ comissao, valorMargemBrutaBase, dataComissao }) => {
                                                    const valor = (comissao * valorMargemBrutaBase) / 100;
                                                    return (
                                                        <Col sm="12" key={v4()}>
                                                            <span>
                                                                {dataComissao.toLocaleDateString("pt-Br", {
                                                                    day: "2-digit",
                                                                    month: "2-digit",
                                                                    year: "numeric",
                                                                })}{" "}
                                                                - {utils.retornaValorMonetario(valor, "BRL")}
                                                            </span>
                                                        </Col>
                                                    );
                                                }
                                            )}
                                        </Row>
                                    </Container>
                                </React.Fragment>
                            );
                        })}
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}
