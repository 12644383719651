import { ReactNode } from "react";
import { Offcanvas } from "react-bootstrap";

interface IOffcanvasNovoDadoHeader {
    children?: ReactNode;
}

export default function OffcanvasNovoDadoHeader(props: IOffcanvasNovoDadoHeader) {
    return <Offcanvas.Header className="border-bottom bg-light bg-opacity-25">{props.children}</Offcanvas.Header>;
}
