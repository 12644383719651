import { Col, Offcanvas, Row, Stack } from "react-bootstrap";
import BotaoFechar from "../botoes/BotaoFechar";
import utils from "../../utils";
import BotaoLabel from "../botoes/BotaoLabel";
import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import { routes } from "../../router";
import SpanCopy from "../span/SpanCopy";
import Botao from "../botoes/Botao";
import { OffcanvasNovoDado } from "./OffcanvasNovoDado";
import FormNovaSenha, { tNovaSenha } from "../forms/FormNovaSenha";
import { ContextAlerta } from "../../contexts/ContextAlert";
import api from "../../services/api";
import { tPartialInfoUser } from "../../contexts/ContextUser";
import "./styles.css";

const FORM_ID_NOVA_SENHA = "form-id-nova-senha";
let abortController: AbortController | undefined;

interface Props {
    show: boolean;
    onHide: () => void;
    usuario: tPartialInfoUser | null;
}
export default function OffcanvasPerfil(props: Props) {
    //CONTEXTOS
    const { setSucesso, setPerigo } = useContext(ContextAlerta);
    const navigate = useNavigate();

    //ESTADOS
    const [mostrarOffcanvasNovaSenha, setMostrarOffcanvasNovaSenha] = useState(false);
    const [salvandoNovaSenha, setSalvandoNovaSenha] = useState(false);

    //EVENTOS
    const handleOnLogout = useCallback(async () => {
        try {
            await api.logout();
            navigate(routes.login);
        } catch (err) {
            setPerigo(utils.retornaMensagemErro(err));
        }
    }, [navigate, setPerigo]);

    const handleOnMudarSenha = useCallback(() => setMostrarOffcanvasNovaSenha(true), []);
    const handleOnCancelMudarSenha = useCallback(() => {
        setMostrarOffcanvasNovaSenha(false);
        abortController?.abort();
    }, []);

    const handleOnSubmitNovaSenha = useCallback(
        async (dadosSenha: tNovaSenha) => {
            abortController = new AbortController();
            const signal = abortController.signal;

            try {
                setSalvandoNovaSenha(true);
                await api.mudarSenhaAtual(dadosSenha.password, signal);
                setMostrarOffcanvasNovaSenha(false);
                setSucesso("Nova senha cadastrada!");
            } catch (err) {
                throw err;
            } finally {
                setSalvandoNovaSenha(false);
            }
        },
        [setSucesso]
    );

    return (
        <>
            <Offcanvas show={props.show} onHide={props.onHide} placement="end">
                <Offcanvas.Header className="my-menu-user-header border-bottom">
                    <Row className="align-items-center pt-3">
                        <Col sm="12" className="position-absolute d-flex w-100 justify-content-end top-0 mt-2">
                            <BotaoFechar onClick={props.onHide} />
                        </Col>
                        <Col sm="auto">
                            <div className="my-div-circle-name d-flex justify-content-center align-items-center bg-primary rounded-circle p-4">
                                <span className="fs-2 fw-bold text-light">
                                    {utils.retornaLetrasDoNome(props.usuario?.name ?? "")}
                                </span>
                            </div>
                        </Col>
                        <Col>
                            <Stack>
                                <span className="lh-1 mb-2 fw-bold fs-5">{props.usuario?.name}</span>
                                <SpanCopy textoCopiar={props.usuario?.id ?? ""} tooltip={"Clique para copiar seu ID"}>
                                    ID de usuário: {props.usuario?.id}
                                </SpanCopy>
                                <BotaoLabel variant="danger" texto="Encerrar sessão" onClick={handleOnLogout} />
                            </Stack>
                        </Col>
                    </Row>
                </Offcanvas.Header>
                <Offcanvas.Body className="overflow-auto">
                    <Stack className="d-flex w-100">
                        <Botao onClick={handleOnMudarSenha}>
                            <h6 className="mb-0">
                                <i className="bi bi-person-fill-lock me-2" />
                                Mudar minha senha
                            </h6>
                        </Botao>
                    </Stack>
                </Offcanvas.Body>
            </Offcanvas>
            <OffcanvasNovoDado.Root show={mostrarOffcanvasNovaSenha}>
                <OffcanvasNovoDado.Header>
                    <h4>Mudar Senha</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    acceptButtonProps={{
                        children: "Salvar",
                        carregando: salvandoNovaSenha,
                        form: FORM_ID_NOVA_SENHA,
                        type: "submit",
                    }}
                    cancelButtonProps={{ onClick: handleOnCancelMudarSenha }}
                >
                    <FormNovaSenha
                        valoresIniciais={{ password: "", confirmPass: "" }}
                        formId={FORM_ID_NOVA_SENHA}
                        onSubmit={handleOnSubmitNovaSenha}
                    />
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>
        </>
    );
}
