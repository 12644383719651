import LayoutDashboardBody from "./LayoutDashboardBody";
import LayoutDashboardBodyRoot from "./LayoutDashboardBodyRoot";
import LayoutDashboardHeader from "./LayoutDashboardHeader";
import LayoutDashboardRoot from "./LayoutDashboardRoot";

const LayoutDashboard = {
    Root: LayoutDashboardRoot,
    Header: LayoutDashboardHeader,
    Body: LayoutDashboardBody,
    BodyRoot: LayoutDashboardBodyRoot,
};

export default LayoutDashboard;
