import { ReactNode } from "react";
import "./styles.css";

interface ILayoutTabelaHeaderRight {
    children?: ReactNode;
}

export default function LayoutTabelaHeaderRight(props: ILayoutTabelaHeaderRight) {
    return (
        <div className="d-flex my-div-session-filter-add-options justify-content-end align-items-center">
            {props.children}
        </div>
    );
}
