import { ReactNode } from "react";
import { Stack } from "react-bootstrap";
import "./styles.css";
import { createContext } from "react";
import { tHeaderTable } from "../../dropdowns/DropdownCustomizacaoTabela";

export const ContextTabela = createContext({
    customizationsName: "",
    headers: [{ value: "", name: "", show: false }],
    setHeaders: (headers: tHeaderTable[]) => {},
});

interface ITabelaProps {
    children?: ReactNode;
    customizationsName: string;
    headers: tHeaderTable[];
    setHeaders: React.Dispatch<React.SetStateAction<tHeaderTable[]>>;
}

export default function TabelaRoot({ customizationsName, headers, setHeaders, ...props }: ITabelaProps) {
    return (
        <ContextTabela.Provider
            value={{
                customizationsName,
                headers,
                setHeaders,
            }}
        >
            <Stack className="d-flex w-100">{props.children}</Stack>
        </ContextTabela.Provider>
    );
}

//AUXILIAR FUNCTION
export function returnCustomHeaders(customizationsName: string, defaultHeaders: tHeaderTable[]): tHeaderTable[] {
    const _saveCustomizationHeaders = localStorage.getItem(customizationsName);
    if (!_saveCustomizationHeaders) return defaultHeaders;

    const saveCustomizationHeaders = JSON.parse(_saveCustomizationHeaders);
    if (!Array.isArray(saveCustomizationHeaders)) return defaultHeaders;

    const headersContent = saveCustomizationHeaders.filter((header: tHeaderTable) => {
        if (typeof header !== "object") return false;
        return defaultHeaders.some(({ value, name }) => value === header.value && name === header.name);
    });
    if (headersContent.length !== defaultHeaders.length) return defaultHeaders;

    return headersContent;
}
