import { useCallback, useRef, useState } from "react";
import { Col, Container, Overlay, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { tVenda } from "../../interfaces";
import utils from "../../utils";
import "./styles.css";

interface ISpanVendaValorProps {
    venda: tVenda;
}
export default function SpanVendaValor(props: ISpanVendaValorProps) {
    //ESTADOS
    const [mostrarInfo, setMostrarInfo] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);

    //VARIAVEIS
    const { venda } = props;

    //EVENTOS
    const handleOnMostrarInfo = useCallback(() => setMostrarInfo(true), []);
    const handleOnEsconderInfo = useCallback(() => setMostrarInfo(false), []);

    return (
        <>
            <div
                className="d-flex w-100 overflow-hidden text-info justify-content-end"
                role="button"
                onClick={handleOnMostrarInfo}
                ref={divRef}
            >
                <OverlayTrigger placement="top" flip overlay={<Tooltip>Clique para saber mais</Tooltip>}>
                    <span className="text-nowrap">
                        {utils.retornaValorMonetario(venda.valorVenda ?? 0, "BRL")}
                        <i className="bi bi-caret-down-fill ms-1" />
                    </span>
                </OverlayTrigger>
            </div>

            <Overlay
                rootClose
                onHide={handleOnEsconderInfo}
                target={divRef.current}
                show={mostrarInfo}
                flip
                placement="bottom"
            >
                <Popover className="shadow">
                    <Popover.Body className="my-container-controle-borda p-2">
                        <Container fluid className="p-1">
                            <Row className="my-child-controle-borda border-bottom border-bottom">
                                <Col sm="12" className="d-flex justify-content-between lh-1 align-items-end">
                                    <span>Custo:</span>
                                    <span>{utils.retornaValorMonetario(venda.valorCusto ?? 0, "BRL")}</span>
                                </Col>
                                <Col sm="12" className="d-flex justify-content-between lh-1 align-items-end mt-1">
                                    <span>Markup:</span>
                                    <span>{utils.retornaValorMonetario(venda.valorMarkup ?? 0, "BRL")}</span>
                                </Col>
                                <Col sm="12" className="d-flex justify-content-between lh-1 align-items-end mt-1">
                                    <span>Impostos:</span>
                                    <span>{utils.retornaValorMonetario(venda.valorImpostos, "BRL")}</span>
                                </Col>
                                <Col sm="12" className="d-flex justify-content-between lh-1 align-items-end mt-1">
                                    <span>Margem Bruta:</span>
                                    <span>{utils.retornaValorMonetario(venda.valorMargemBruta, "BRL")}</span>
                                </Col>
                                <Col
                                    sm="12"
                                    className="d-flex justify-content-between mt-3 fw-bold lh-1 align-items-end"
                                >
                                    <span>Valor venda:</span>
                                    <span className="text-nowrap">
                                        {utils.retornaValorMonetario(venda.valorVenda ?? 0, "BRL")}
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}
