import { useCallback } from "react";
import { PartialEntity, tVendedor } from "../../interfaces";
import { OffcanvasNovoDado } from "./OffcanvasNovoDado";
import { OffcanvasEditarDado } from "./OffcanvasEditarDado.tsx";
import { OffcanvasDeletarDado } from "./OffcanvasDeletarDado.tsx";
import FormVendedor, { tNovoVendedor } from "../forms/FormVendedor";
import useVendedor from "../../hooks/useVendedor";

export type IOffcanvasVendedorProps = {
    mostrarForm: boolean;
    onTerminou?: () => void;
    onCancel?: () => void;
} & (
    | { tipo: "criar"; dados?: null }
    | { tipo: "editar"; dados: null | (tNovoVendedor & PartialEntity<tVendedor, "id">) }
    | { tipo: "deletar"; dados: null | PartialEntity<tVendedor, "id" | "user"> }
);

const FORM = "form-vendedor";

export default function OffcanvasVendedor(props: IOffcanvasVendedorProps) {
    //HOOKS
    const { criar, editar, deletar, estaCriando, estaEditando, estaDeletando, cancelar } = useVendedor();

    //VARAIVEIS
    const { onTerminou, onCancel, dados, tipo } = props;

    //EVENTS
    const handleOnCancel = useCallback(() => {
        cancelar();
        if (onCancel) onCancel();
    }, [onCancel, cancelar]);

    const handleOnCriar = useCallback(
        async (vendedor: tNovoVendedor) => {
            try {
                await criar([vendedor]);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [criar, onTerminou]
    );

    const handleOnEditar = useCallback(
        async (vendedor: tNovoVendedor & PartialEntity<tVendedor, "id">) => {
            try {
                await editar(vendedor);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [editar, onTerminou]
    );

    const handleOnDeletar = useCallback(
        async (id: string) => {
            try {
                await deletar(id);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [deletar, onTerminou]
    );

    return props.tipo === "criar" ? (
        <OffcanvasNovoDado.Root show={props.mostrarForm}>
            <OffcanvasNovoDado.Header>
                <h4>Novo Vendedor</h4>
            </OffcanvasNovoDado.Header>
            <OffcanvasNovoDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ carregando: estaCriando, type: "submit", form: FORM }}
            >
                <FormVendedor formId={FORM} valoresIniciais={{ name: "", email: "" }} onSubmit={handleOnCriar} />
            </OffcanvasNovoDado.Body>
        </OffcanvasNovoDado.Root>
    ) : tipo === "editar" ? (
        <OffcanvasEditarDado.Root show={props.mostrarForm}>
            <OffcanvasEditarDado.Header>
                <h4>Editar Vendedor</h4>
            </OffcanvasEditarDado.Header>
            <OffcanvasEditarDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ form: FORM, carregando: estaEditando, type: "submit" }}
            >
                {dados && (
                    <FormVendedor
                        formId={FORM}
                        valoresIniciais={{ name: dados.name, email: dados.email }}
                        onSubmit={(servico) => handleOnEditar({ id: dados.id, ...servico })}
                    />
                )}
            </OffcanvasEditarDado.Body>
        </OffcanvasEditarDado.Root>
    ) : tipo === "deletar" ? (
        <OffcanvasDeletarDado.Root show={props.mostrarForm}>
            <OffcanvasDeletarDado.Header>
                <h4>Deletar Vendedor</h4>
            </OffcanvasDeletarDado.Header>
            {dados && (
                <OffcanvasDeletarDado.Body
                    acceptButtonProps={{ onClick: () => handleOnDeletar(dados.id), carregando: estaDeletando }}
                    cancelButtonProps={{ onClick: handleOnCancel }}
                >
                    <p>
                        Você está prestes a deletar permanentemente do sistema o vendedor de nome{" "}
                        <strong>{dados.user.name}</strong>. Clique no botão{" "}
                        <strong className="text-danger">Deletar</strong> logo abaixo para prosseguir.
                    </p>
                </OffcanvasDeletarDado.Body>
            )}
        </OffcanvasDeletarDado.Root>
    ) : undefined;
}
